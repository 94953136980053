<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- All -->
      <ul class="unstyled nav-parent-item">
        <li>
          <a href="/logout" @click.prevent="logout">Logout</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.dispatch("setVideosOrder", "3");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      this.$root.addSnackBarMsg("Logout successful!", "info");
    },
  },
  created() {},
};
</script>
