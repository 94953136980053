<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              SETTINGS
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-settings"></i> </span>Thumbnails
                Generator
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray">
        <!-- First thumbnail start time -->
        <div class="row p-0 m-0 my-3">
          <div class="align-items-center d-flex flex-row col-lg-3">
            <div class="form-label">FIRST THUMBNAIL START TIME</div>
          </div>
          <div class="align-items-center col-lg-9 d-flex flex-row">
            <input
              type="text"
              class="form-control"
              placeholder="First thumbnail start time"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              @keypress="numbersOnly($event)"
              @click.right.prevent
              @copy.prevent
              @paste.prevent
              v-model="model_first_thumb_start_time"
            />
          </div>
        </div>
        <!-- Interval between thumbnails -->
        <div class="row p-0 m-0 my-3">
          <div class="align-items-center d-flex flex-row col-lg-3">
            <div class="form-label">INTERVAL BETWEEEN THUMBNAILS</div>
          </div>
          <div class="align-items-center col-lg-9 d-flex flex-row">
            <input
              type="text"
              class="form-control"
              placeholder="First thumbnail start time"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              @keypress="numbersOnly($event)"
              @click.right.prevent
              @copy.prevent
              @paste.prevent
              v-model="model_interval_between_thumbnails"
            />
          </div>
        </div>
        <!-- Number of thumbnails -->
        <div class="row p-0 m-0 my-3">
          <div class="align-items-center d-flex flex-row col-lg-3">
            <div class="form-label">NUMBER OF THUMBNAILS</div>
          </div>
          <div class="align-items-center col-lg-9 d-flex flex-row">
            <input
              type="text"
              class="form-control"
              placeholder="Number of thumbnails"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              @keypress="numbersOnly($event)"
              @click.right.prevent
              @copy.prevent
              @paste.prevent
              v-model="model_number_of_thumbnails"
            />
          </div>
        </div>
        <hr class="sepGray mt-4" />
        <!-- Save Settings -->
        <div class="container-fluid p-0 pt-3">
          <button
            @click="saveSettings()"
            type="button"
            class="btn button-outline-1 mb-3 float-end"
          >
            <span> <i class="zmdi zmdi-floppy"></i>Save Settings </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";

export default {
  components: {},
  data() {
    return {
      model_first_thumb_start_time: 3,
      model_interval_between_thumbnails: 15,
      model_number_of_thumbnails: 10,
      spinnerLoader: null,
    };
  },
  methods: {
    numbersOnly(event) {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    validationFields() {
      if (
        !this.model_first_thumb_start_time > 0 ||
        !this.model_interval_between_thumbnails > 0 ||
        !this.model_number_of_thumbnails > 0
      ) {
        this.$root.addSnackBarMsg("Fileds must be at least >0", "warning");
        return false;
      }
      return true;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
    saveSettings() {
      if (!this.validationFields()) return false;
      const bodyObj = {
        firstThumbStartTime: this.model_first_thumb_start_time,
        intervalBetweenThumbs: this.model_interval_between_thumbnails,
        numberOfThumbs: this.model_number_of_thumbnails,
      };
      this.putSettingsThumbnails(bodyObj);
    },
    async getSettingsThumbnails() {
      this.showLoader(true);
      const awaitSettings = await EventService.getSettingsThumbnails(
        this.$store.getters.getToken.token
      );

      if (awaitSettings.status != 200) {
        console.error("++ Settings, thumbnail: error during loading data");
        this.$root.addSnackBarMsg(
          "GET THUMBNAILS SETTING: An error has occurred, please try again",
          "error"
        );
        this.showLoader(false);
        return;
      }
      this.showLoader(false);
      console.log("++ thumbnails settings:", awaitSettings.data);

      this.model_first_thumb_start_time = awaitSettings.data.firstThumbStartTime;
      this.model_interval_between_thumbnails = awaitSettings.data.intervalBetweenThumbs;
      this.model_number_of_thumbnails = awaitSettings.data.numberOfThumbs;
    },
    async putSettingsThumbnails(bodyObj) {
      this.showLoader(true);
      const awaitSettings = await EventService.putSettingsThumbnails(
        bodyObj,
        this.$store.getters.getToken.token
      );

      if (awaitSettings.status != 200) {
        console.error("++ Settings, thumbnail: error during saving data");
        this.$root.addSnackBarMsg(
          "SAVE THUMBNAILS SETTING: An error has occurred, please try again",
          "error"
        );
        this.showLoader(false);
        return;
      }
      this.showLoader(false);
      this.$root.addSnackBarMsg("The data was updated successfully", "info");
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    this.getSettingsThumbnails();
  },
};
</script>
