<template>
  <main>
    <div v-if="isUserLogged" class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <sidebar />
        <!-- Main content -->
        <!-- <router-view :key="$route.url"></router-view> -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import EventService from "@/services/EventService.js";
import Sidebar from "@/views/pictures/PicturesSidebar.vue";

export default {
  components: { Sidebar },
  data() {
    return {
      isUserLogged: false,
    };
  },
  async beforeMount() {
    //if user is not logged
    this.isUserLogged = await EventService.checkToken();
    if (!this.isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
    }
  },
};
</script>
