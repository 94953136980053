<template>
  <tr v-if="myUserId != user.providerUserId">
    <!-- First Name -->
    <td>{{ capitalizeWords(user.firstName) }}</td>
    <!-- Last Name -->
    <td>{{ capitalizeWords(user.lastName) }}</td>
    <!-- User Name -->
    <td>{{ user.username }}</td>
    <!-- Email -->
    <td>{{ user.email }}</td>
    <!-- Email verified -->
    <td v-if="isEmailVerified" class="td-center">
      <ul class="list-tags">
        <li>
          <span
            class="badge-colored"
            :class="user.emailVerified ? 'green' : 'red'"
            :title="user.emailVerified ? 'yes' : 'no'"
            :alt="user.emailVerified ? 'yes' : 'no'"
            >{{ user.emailVerified ? "yes" : "no" }}</span
          >
        </li>
      </ul>
    </td>
    <!-- Privileges -->
    <td class="td-center">
      <ul class="list-tags">
        <li>
          <span
            v-if="user.isAdmin"
            class="badge-colored blue"
            :title="`${getUserTypeLabel(user.isAdmin)}`"
            :alt="`${getUserTypeLabel(user.isAdmin)}`"
            >{{ getUserTypeLabel(user.isAdmin) }}</span
          >
          <span
            v-if="!user.isAdmin"
            class="badge-colored fucsia"
            :title="`${getUserTypeLabel(user.isAdmin)}`"
            :alt="`${getUserTypeLabel(user.isAdmin)}`"
            >{{ getUserTypeLabel(user.isAdmin) }}</span
          >
        </li>
      </ul>
    </td>
    <!-- Actions -->
    <td class="td-actions td-center">
      <button alt="EDIT" title="EDIT" @click="$emit('editUser')">
        <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
      </button>
      <button alt="DELETE" title="DELETE" @click="$emit('deleteUser')">
        <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  emits: ["editUser", "deleteUser"],
  props: {
    user: { type: Object, required: true },
    myUserId: String,
  },
  components: {},
  data() {
    return {
      userTypes: [
        { type: "users", label: "Users", tag: "USER", isAdmin: false, isAdminStr: "N" },
        { type: "admins", label: "Admins", tag: "ADMIN", isAdmin: true, isAdminStr: "Y" },
      ],
      isEmailVerified: false,
    };
  },
  computed: {},
  methods: {
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    capitalizeWords(sentence) {
      // return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
      const sentences = sentence.split(" ");
      for (var i = 0; i < sentences.length; i++) {
        sentences[i] =
          sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1).toLowerCase();
      }
      const convertedSentence = sentences.join(" ");
      return convertedSentence;
    },
    getUserTypeLabel(isAdmin) {
      const tempElement = this.foundElementInArrayById(
        "isAdmin",
        isAdmin,
        this.userTypes
      );
      if (tempElement != undefined) {
        return tempElement.tag;
      }
      return "-";
    },
  },
};
</script>
