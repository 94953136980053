<template>
  <div class="col main-col">
    <!-- Modal Image -->
    <div id="modalImg" class="modal-image-container">
      <span id="modalImgClose" class="modal-image-close">&times;</span>
      <img class="modal-image-content border-mainblue pt-checker" id="imageModal" />
      <div id="modalImgCaption" class="modal-image-caption"></div>
    </div>
    <!-- Main Content -->
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <!-- Title section -->
            <h1>
              IMAGE
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-edit"></i> </span>Edit
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Linear Loader -->
      <div v-if="isLoading" class="linear-loader">
        <span></span>
      </div>
      <!-- Content -->
      <div v-if="isVisible" class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Title -->
        <div class="row m-0 padding-x-4px">
          <div class="col-md-12 p-0 mt-4">
            <h4 class="color-mainblue m-0 p-0">
              {{ imageMetadataDetails_en.metaDataDetails.title }}
            </h4>
          </div>
          <hr class="sepGray" />
        </div>
        <!-- Top Tabs -->
        <div class="row m-0 padding-x-4px">
          <ul class="nav nav-tabs video-tabs">
            <li class="nav-item">
              <a
                href="#overview"
                class="nav-link active d-inline-flex"
                data-bs-toggle="tab"
              >
                OVERVIEW
                <span v-if="!isChangesSaved[1]" class="ms-2 change-data"></span>
              </a>
            </li>
            <li
              v-if="imageDeliverables.length > 0 && !isImageZeroDimension"
              class="nav-item"
            >
              <a
                href="#encoding"
                @click.prevent="pauseVideo"
                class="nav-link d-inline-flex"
                data-bs-toggle="tab"
              >
                VERSIONS
                <span v-if="!isChangesSaved[3]" class="ms-2 change-data"></span>
              </a>
            </li>
            <li v-if="!isImageZeroDimension" class="nav-item">
              <a
                href="#attachments"
                @click.prevent="pauseVideo"
                class="nav-link d-inline-flex"
                data-bs-toggle="tab"
              >
                ATTACHMENTS
                <span v-if="!isChangesSaved[5]" class="ms-2 change-data"></span>
              </a>
            </li>
            <!-- Checkbox Digital Signage -->
            <div
              v-if="isDigitalSignage"
              class="align-items-center col d-flex flex-row justify-content-end w-100"
            >
              <div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check-digital-signage"
                    v-model="model_check_digital_signage"
                  />
                  <label class="content-form" for="check-digital-signage"
                    >Digital Signage</label
                  >
                </div>
              </div>
            </div>
            <!-- Copy GUID -->
            <div class="align-items-center col d-flex flex-row justify-content-end w-100">
              <div class="align-items-center d-flex flex-row justify-content-end row">
                <div>
                  <div class="form-label d-inline-flex me-2">GUID</div>
                  <p class="content-form word-break-all box-code d-inline-flex me-2">
                    {{ image.guid }}
                  </p>
                  <button
                    @click="copyTextToClipboard(image.guid, 'GUID')"
                    class="icon-button float-end"
                    alt="COPY GUID"
                    title="COPY GUID"
                  >
                    <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </ul>
          <div class="tab-content p-0">
            <!-- Overview -->
            <div class="tab-pane fade show active" id="overview">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Overview -->
                <div class="row my-3">
                  <div class="accordion accordion-console" id="accordion-overview">
                    <!-- Accordion Preview -->
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-1"
                          aria-expanded="true"
                          aria-controls="panel-collapse-1"
                        >
                          GENERAL INFO
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-1"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panel-heading-1"
                      >
                        <div class="accordion-body">
                          <!-- Preview & Chart Views -->
                          <div class="row my-3">
                            <!-- Preview -->
                            <div class="col-md-4 mb-3">
                              <div class="form-label mb-1">
                                IMAGE - Original resolution: {{ srcImageWidth }} x
                                {{ srcImageHeight }}
                              </div>
                              <!-- Error, image dimension is zero -->
                              <div
                                v-if="isImageZeroDimension"
                                class="d-inline-block color-error"
                              >
                                Error: image dimension is 0 x 0
                              </div>
                              <!-- Image preview -->
                              <div v-else class="border-mainblue bg-black d-inline-block">
                                <!-- Image Preview -->
                                <div class="image-preview-container">
                                  <img
                                    id="imgOriginalVersion"
                                    @click.prevent="previewModalImage($event)"
                                    class="img-fluid preview-image pt-checker"
                                    :src="imgOriginalSrc"
                                    :alt="imgOriginalSrc"
                                    title="PREVIEW"
                                  />
                                  <div class="image-preview-overlay">
                                    <div class="image-preview-icon">
                                      <i class="zmdi zmdi-eye"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Info -->
                            <div class="col-md-8 mb-3">
                              <div class="row my-3">
                                <!-- Created -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">CREATED</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form">
                                      {{
                                        mainImageDataConverted.creationDateUnixTimeStamp
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Modified -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">MODIFIED</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form">
                                      {{
                                        mainImageDataConverted.modificationDateUnixTimeStamp
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Author -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">AUTHOR</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form word-break-all">
                                      {{ imageMetadataDetails_en.metaDataDetails.author }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Contributin User -->
                                <div class="align-items-center d-flex flex-row my-2 row">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">CONTRIBUTING USER</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    {{ image.userName }}
                                  </div>
                                </div>
                                <hr
                                  v-if="
                                    imageDeliverables.length > 0 && !isImageZeroDimension
                                  "
                                  class="sepGray"
                                />
                                <!-- Image Versions -->
                                <div
                                  v-if="
                                    imageDeliverables.length > 0 && !isImageZeroDimension
                                  "
                                  class="align-items-center d-flex justify-content-center my-2 pe-0 row"
                                >
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label my-2">IMAGE VERSIONS</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-column justify-content-center px-0"
                                  >
                                    <!-- Select Image Version -->
                                    <div class="container p-0 mb-2">
                                      <div class="inner-addon right-addon">
                                        <i
                                          class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                                        ></i>
                                        <select
                                          class="form-select form-control"
                                          required
                                          v-model="imageSelectDirectUrl"
                                        >
                                          <option disabled value>
                                            Image Versions...
                                          </option>
                                          <option
                                            v-for="(directUrl, index) in getDirectUrl()"
                                            :key="index"
                                            :value="directUrl.value"
                                          >
                                            {{ directUrl.description }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <!-- Image Direct Url -->
                                    <div class="container p-0 m-0">
                                      <div
                                        class="container d-flex flex-column justify-content-center px-0"
                                      >
                                        <div
                                          class="align-items-center d-flex flex-row justify-content-between row"
                                        >
                                          <div class="col-sm-11">
                                            <p
                                              class="content-form word-break-all small box-code"
                                              style="min-height: 34px"
                                            >
                                              {{ imageSelectDirectUrl }}
                                            </p>
                                          </div>
                                          <div class="col-sm-1">
                                            <button
                                              @click="
                                                copyTextToClipboard(
                                                  imageSelectDirectUrl,
                                                  'URL'
                                                )
                                              "
                                              class="icon-button float-end"
                                              alt="COPY"
                                              title="COPY"
                                              :disabled="imageSelectDirectUrl == ''"
                                            >
                                              <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Accordion Metadata -->
                    <div v-if="!isImageZeroDimension" class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-4">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-4"
                          aria-expanded="true"
                          aria-controls="panel-collapse-4"
                        >
                          METADATA
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-4"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panel-heading-4"
                      >
                        <div class="accordion-body">
                          <!-- Metadata Content -->
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="row mb-2">
                                <!-- Language EN RU Tabs -->
                                <div class="row m-0 padding-x-4px p-2">
                                  <ul class="nav nav-tabs language-tabs">
                                    <li class="nav-item">
                                      <a
                                        href="#lang_en"
                                        class="nav-link active"
                                        data-bs-toggle="tab"
                                        >EN</a
                                      >
                                    </li>
                                    <li v-if="is_RU_LangAvailable" class="nav-item">
                                      <a
                                        href="#lang_ru"
                                        class="nav-link"
                                        data-bs-toggle="tab"
                                        >RU</a
                                      >
                                    </li>
                                  </ul>
                                  <div class="tab-content p-0">
                                    <!-- Language EN -->
                                    <div class="tab-pane fade show active" id="lang_en">
                                      <div class="container-fluid m-0 p-0">
                                        <!-- Content Language EN -->
                                        <div class="container p-0">
                                          <!-- Title -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">TITLE</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  imageMetadataDetails_en.metaDataDetails
                                                    .title
                                                "
                                                placeholder="Title"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                                @input="onTitleVideoENChange($event)"
                                              />
                                            </div>
                                          </div>
                                          <!-- Author -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">AUTHOR</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  imageMetadataDetails_en.metaDataDetails
                                                    .author
                                                "
                                                placeholder="Author"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Abstract -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">ABSTRACT</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    imageMetadataDetails_en
                                                      .metaDataDetails.s_abstract
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Eyelet -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">EYELET</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  imageMetadataDetails_en.metaDataDetails
                                                    .eyelet
                                                "
                                                placeholder="Eyelet"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Description -->
                                          <div class="align-items-top d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">
                                                DESCRIPTION
                                              </div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    imageMetadataDetails_en
                                                      .metaDataDetails.description
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Language RU -->
                                    <div class="tab-pane fade" id="lang_ru">
                                      <div class="container-fluid m-0 p-0">
                                        <!-- Content Language RU -->
                                        <div class="container p-0">
                                          <!-- Title -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">TITLE</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  imageMetadataDetails_ru.metaDataDetails
                                                    .title
                                                "
                                                placeholder="Title"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Author -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">AUTHOR</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  imageMetadataDetails_ru.metaDataDetails
                                                    .author
                                                "
                                                placeholder="Author"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Abstract -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">ABSTRACT</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    imageMetadataDetails_ru
                                                      .metaDataDetails.s_abstract
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Eyelet -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">EYELET</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  imageMetadataDetails_ru.metaDataDetails
                                                    .eyelet
                                                "
                                                placeholder="Eyelet"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Description -->
                                          <div class="align-items-top d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">
                                                DESCRIPTION
                                              </div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    imageMetadataDetails_ru
                                                      .metaDataDetails.description
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="row mb-2">
                                <!-- Language IT Tabs -->
                                <div class="row m-0 padding-x-4px p-2">
                                  <ul class="nav nav-tabs language-tabs">
                                    <li class="nav-item">
                                      <a
                                        href="#lang_it"
                                        class="nav-link active"
                                        data-bs-toggle="tab"
                                        >IT</a
                                      >
                                    </li>
                                  </ul>
                                  <div class="tab-content p-0">
                                    <!-- Language IT -->
                                    <div class="tab-pane fade show active" id="lang_it">
                                      <div class="container-fluid m-0 p-0">
                                        <!-- Content Language IT -->
                                        <div class="container p-0">
                                          <!-- Title -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">TITLE</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                placeholder="Title"
                                                v-model="
                                                  imageMetadataDetails_it.metaDataDetails
                                                    .title
                                                "
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Author -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">AUTHOR</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                placeholder="Author"
                                                v-model="
                                                  imageMetadataDetails_it.metaDataDetails
                                                    .author
                                                "
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Abstract -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">ABSTRACT</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    imageMetadataDetails_it
                                                      .metaDataDetails.s_abstract
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Eyelet -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">EYELET</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  imageMetadataDetails_it.metaDataDetails
                                                    .eyelet
                                                "
                                                placeholder="Eyelet"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Description -->
                                          <div class="align-items-top d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">
                                                DESCRIPTION
                                              </div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- <rich-text-editor
                                                :contentTxt="imageMetadataDetails_it.metaDataDetails.description"
                                                @reciveUpdateText="updateDescription_it"
                                                @keydown="isChangesSaved[1] = false"
                                                customToolbar="ct10"
                                              ></rich-text-editor>-->

                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    imageMetadataDetails_it
                                                      .metaDataDetails.description
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Digital Edition -->
                          <!-- <div class="row p-2">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="check-digital-edition"
                                @change="isChangesSaved[1] = false"
                                v-model="image.digitalEdition"
                              />
                              <label class="content-form" for="check-digital-edition"
                                >Digital Edition</label
                              >
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- Accordion Labels -->
                    <div v-if="!isImageZeroDimension" class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-5">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-5"
                          aria-expanded="true"
                          aria-controls="panel-collapse-5"
                        >
                          LABELS
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-5"
                        class="accordion-collapse collapse collapse show"
                        aria-labelledby="panel-heading-5"
                      >
                        <!-- Labels Content -->
                        <div class="accordion-body">
                          <!-- Areas Content -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="col-lg-2 pe-0">
                              <div class="form-label my-2">AREAS</div>
                            </div>
                            <div class="align-items-center col-lg-10 d-flex flex-row">
                              <Multiselect
                                id="areas"
                                placeholder="Chose an area..."
                                noResultsText="No result"
                                v-model="tagsAreasValue"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="tagsAreas"
                                @change="isChangesSaved[1] = false"
                              />
                            </div>
                          </div>
                          <!-- Categories Content -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="col-lg-2 pe-0">
                              <div class="form-label my-2">CATEGORIES</div>
                            </div>
                            <div class="align-items-center col-lg-10 d-flex flex-row">
                              <Multiselect
                                id="categories"
                                placeholder="Chose a category..."
                                noResultsText="No result"
                                v-model="tagsCategoriesValue"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="tagsCategories"
                                @change="isChangesSaved[1] = false"
                              />
                            </div>
                          </div>
                          <!-- Tags Content -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="col-lg-2 pe-0">
                              <div class="form-label my-2">TAGS</div>
                            </div>
                            <div class="align-items-center col-lg-10 d-flex flex-row">
                              <Multiselect
                                id="tags"
                                placeholder="Chose a tag..."
                                noResultsText="No result"
                                v-model="tagsTagsValue"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="tagsTags"
                                @change="isChangesSaved[1] = false"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Encoding -->
            <div class="tab-pane fade" id="encoding">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Encoding -->
                <div class="row my-3 m-0 padding-x-4px">
                  <!-- Add New Version -->
                  <div class="container-fluid p-0 mb-2">
                    <button
                      @click="chooseFile()"
                      type="button"
                      class="btn button-outline-1 small float-end"
                    >
                      <span> <i class="fa-solid fa-folder-open"></i>Load New</span>
                    </button>
                    <input
                      id="newImageUpload"
                      type="file"
                      :accept="imageFileTypes"
                      v-on:change="handleFileUpload($event)"
                      hidden
                    />
                    <button
                      @click="ci_refresh"
                      ref="btn_add_new_version"
                      type="button"
                      class="btn button-outline-1 small float-end me-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_addnewresolution"
                      aria-expanded="false"
                      aria-controls="collapse_addnewresolution"
                    >
                      <span> <i class="zmdi zmdi-plus"></i>Add New Version</span>
                    </button>
                  </div>
                  <hr class="sepGray" />

                  <!-- Cropper Image -->
                  <div class="collapse p-0" id="collapse_addnewresolution">
                    <div class="row p-0">
                      <!-- Cropper Image Column -->
                      <div v-if="isCropperRedraw" class="col-lg-9 mb-3">
                        <div class="title-column">
                          IMAGE - Original resolution: {{ srcImageWidth }} x
                          {{ srcImageHeight }} - Cropped image: {{ imageCroppedWidth }} x
                          {{ imageCroppedHeight }}
                        </div>

                        <!-- Image Cropper -->
                        <vue-cropper
                          ref="cropper"
                          id="cropper"
                          :dragMode="'move'"
                          :aspect-ratio="NaN"
                          :viewMode="Number(cropperViewMode)"
                          :src="imgSrc"
                          :checkCrossOrigin="false"
                          :checkOrientation="false"
                          crossorigin="anonymous"
                          preview=".previewImage"
                          @ready="ci_cropperReady"
                          @cropmove="ci_setValueDimensions"
                          @cropstart="ci_setValueDimensions"
                          @cropend="ci_setValueDimensions"
                          @zoom="ci_setValueDimensions"
                        ></vue-cropper>
                        <!-- Cropper Image Actions -->
                        <div class="cropper-image-actions">
                          <!-- Reset & Crop image-->
                          <div class="actions-group">
                            <button
                              ref="btn_reset_crop_area"
                              @click.prevent="ci_reset"
                              class="edit-icon-button redActive"
                              alt="RESET CROP AREA"
                              title="RESET CROP AREA"
                            >
                              <i class="fa-solid fa-refresh"></i>
                              <!-- <i class="fa-solid fa-power-off"></i> -->
                            </button>
                            <button
                              @click.prevent="ci_cropImage"
                              class="edit-icon-button greenActive"
                              alt="CROP IMAGE"
                              title="CROP IMAGE"
                            >
                              <i class="fa-solid fa-crop"></i>
                            </button>
                          </div>
                          <!-- Zoom group -->
                          <div class="actions-group">
                            <!-- Zoom in -->
                            <button
                              @click.prevent="ci_zoom(0.05)"
                              class="edit-icon-button"
                              alt="ZOOM IN"
                              title="ZOOM IN"
                            >
                              <i class="fa-solid fa-magnifying-glass-plus"></i>
                            </button>
                            <!-- Zoom out -->
                            <button
                              @click.prevent="ci_zoom(-0.05)"
                              class="edit-icon-button"
                              alt="ZOOM OUT"
                              title="ZOOM OUT"
                            >
                              <i class="fa-solid fa-magnifying-glass-minus"></i>
                            </button>
                          </div>
                          <!-- Move group -->
                          <div class="actions-group">
                            <!-- Move left -->
                            <button
                              @click.prevent="ci_move(-10, 0)"
                              class="edit-icon-button"
                              alt="MOVE LEFT"
                              title="MOVE LEFT"
                            >
                              <i class="fa-solid fa-caret-left"></i>
                            </button>
                            <!-- Move right -->
                            <button
                              @click.prevent="ci_move(10, 0)"
                              class="edit-icon-button"
                              alt="MOVE RIGHT"
                              title="MOVE RIGHT"
                            >
                              <i class="fa-solid fa-caret-right"></i>
                            </button>
                            <!-- Move up -->
                            <button
                              @click.prevent="ci_move(0, -10)"
                              class="edit-icon-button"
                              alt="MOVE UP"
                              title="MOVE UP"
                            >
                              <i class="fa-solid fa-caret-up"></i>
                            </button>
                            <!-- Move down -->
                            <button
                              @click.prevent="ci_move(0, 10)"
                              class="edit-icon-button"
                              alt="MOVE DOWN"
                              title="MOVE DOWN"
                            >
                              <i class="fa-solid fa-caret-down"></i>
                            </button>
                          </div>
                          <!-- Rotate group -->
                          <div class="actions-group">
                            <!-- Rotate 45 right -->
                            <button
                              @click.prevent="ci_rotate(45)"
                              class="edit-icon-button"
                              alt="ROTATE 45° RIGHT"
                              title="ROTATE 45° RIGHT"
                            >
                              <i class="fa-solid fa-rotate-right"></i>
                            </button>
                            <!-- Rotate 45 left -->
                            <button
                              @click.prevent="ci_rotate(-45)"
                              class="edit-icon-button"
                              alt="ROTATE 45° LEFT"
                              title="ROTATE 45° LEFT"
                            >
                              <i class="fa-solid fa-rotate-left"></i>
                            </button>
                          </div>
                          <!-- Flip group -->
                          <div class="actions-group">
                            <!-- Flip X -->
                            <button
                              ref="ci_flipX"
                              @click.prevent="ci_flipX"
                              class="edit-icon-button"
                              alt="FLIP HORIZONTAL"
                              title="FLIP HORIZONTAL"
                            >
                              <i class="fa-solid fa-repeat"></i>
                            </button>
                            <!-- Flip Y -->
                            <button
                              ref="ci_flipY"
                              @click.prevent="ci_flipY"
                              class="edit-icon-button"
                              alt="FLIP VERTICAL"
                              title="FLIP VERTICAL"
                            >
                              <i
                                class="fa-solid fa-repeat"
                                style="transform: rotate(90deg)"
                              ></i>
                            </button>
                          </div>
                          <!-- Cropped Image -->
                          <div
                            v-if="croppedImage != '' || renderedImage != ''"
                            class="title-column mt-4"
                          >
                            CROPPED IMAGE: {{ staticCroppedWidth }} x
                            {{ staticCroppedHeight }} - ASPECT RATIO:
                            {{ staticCroppedAspectRatio }}
                          </div>
                          <div v-if="croppedImage != ''" class="cropped-image">
                            <img
                              class="red-border"
                              id="theCroppedImage"
                              :src="croppedImage"
                            />
                          </div>
                          <div v-if="renderedImage != ''" class="cropped-image">
                            <img
                              class="red-border"
                              id="finalImageRendered"
                              :src="renderedImage"
                            />
                          </div>
                          <!-- Save New Version -->
                          <button
                            v-if="renderedImage != ''"
                            @click="ci_SaveNewVersion"
                            type="button"
                            class="btn button-outline-1 small float-start mt-4"
                          >
                            <span> <i class="zmdi zmdi-save"></i>Save New Version </span>
                          </button>
                        </div>
                      </div>

                      <!-- Preview and Filters -->
                      <div class="col-lg-3 mb-3">
                        <div class="title-column">PREVIEW</div>
                        <div v-if="isCropperRedraw">
                          <div class="previewImage red-border" />
                        </div>

                        <hr class="sepGray" />
                        <!-- X, Y, Width, Height input values -->
                        <!-- X value -->
                        <div class="input-group mb-1">
                          <span class="input-group-text w-25">X</span>
                          <input
                            ref="croppedX"
                            type="text"
                            class="form-control"
                            style="text-align: center"
                            disabled
                            value="0"
                          />
                          <span class="input-group-text w-25">px</span>
                        </div>
                        <!-- Y value -->
                        <div class="input-group mb-1">
                          <span class="input-group-text w-25">Y</span>
                          <input
                            ref="croppedY"
                            type="text"
                            class="form-control"
                            style="text-align: center"
                            disabled
                            value="0"
                          />
                          <span class="input-group-text w-25">px</span>
                        </div>
                        <!-- Width value -->
                        <div class="input-group mb-1">
                          <span class="input-group-text w-25">WIDTH</span>
                          <input
                            ref="croppedWidth"
                            type="text"
                            class="form-control"
                            style="text-align: center"
                            disabled
                            value="0"
                          />
                          <span class="input-group-text w-25">px</span>
                        </div>
                        <!-- Height value -->
                        <div class="input-group mb-3">
                          <span class="input-group-text w-25">HEIGHT</span>
                          <input
                            ref="croppedHeigth"
                            type="text"
                            class="form-control"
                            style="text-align: center"
                            disabled
                            value="0"
                          />
                          <span class="input-group-text w-25">px</span>
                        </div>

                        <!-- Group Ratio Buttons -->
                        <div class="form-label mt-3 mb-1">ASPECT RATIO</div>
                        <div
                          class="btn-group w-100"
                          role="group"
                          aria-label="Aspect Ratio"
                        >
                          <!-- Ratio 16:9 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_ratio"
                            id="btnradio_ratio_1"
                            @input="ci_setAspectRatio($event)"
                            v-model="aspectRatioModel"
                            value="0"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_ratio_1"
                            alt="RATIO 16:9"
                            title="RATIO 16:9"
                            >16:9</label
                          >
                          <!-- Ratio 9:16 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_ratio"
                            id="btnradio_ratio_2"
                            @input="ci_setAspectRatio($event)"
                            v-model="aspectRatioModel"
                            value="1"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_ratio_2"
                            alt="RATIO 9:16"
                            title="RATIO 9:16"
                            >9:16</label
                          >
                          <!-- Ratio 4:3 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_ratio"
                            id="btnradio_ratio_3"
                            @input="ci_setAspectRatio($event)"
                            v-model="aspectRatioModel"
                            value="2"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_ratio_3"
                            alt="RATIO 4:3"
                            title="RATIO 4:3"
                            >4:3</label
                          >
                          <!-- Ratio 3:4 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_ratio"
                            id="btnradio_ratio_4"
                            @input="ci_setAspectRatio($event)"
                            v-model="aspectRatioModel"
                            value="3"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_ratio_4"
                            alt="RATIO 3:4"
                            title="RATIO 3:4"
                            >3:4</label
                          >
                          <!-- Ratio 1:1 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_ratio"
                            id="btnradio_ratio_5"
                            @input="ci_setAspectRatio($event)"
                            v-model="aspectRatioModel"
                            value="4"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_ratio_5"
                            alt="RATIO 1:1"
                            title="RATIO 1:1"
                            >1:1</label
                          >
                          <!-- Ratio Free -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_ratio"
                            id="btnradio_ratio_6"
                            @input="ci_setAspectRatio($event)"
                            v-model="aspectRatioModel"
                            value="5"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_ratio_6"
                            alt="RATIO FREE"
                            title="RATIO FREE"
                            >FREE</label
                          >
                        </div>

                        <!-- Group View Mode Buttons -->
                        <div class="form-label mt-3 mb-1">VIEW MODE</div>
                        <div class="btn-group w-100" role="group" aria-label="View Mode">
                          <!-- View Mode 0 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_viewmode"
                            id="btnradio_viewmode_1"
                            @input="ci_setViewMode($event)"
                            v-model="cropperViewMode"
                            value="0"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_viewmode_1"
                            alt="VIEW MODE 0 - No restrictions, the crop box can extend outside the canvas"
                            title="VIEW MODE 0 - No restrictions, the crop box can extend outside the canvas"
                            >VM 0</label
                          >
                          <!-- View Mode 1 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_viewmode"
                            id="btnradio_viewmode_2"
                            @input="ci_setViewMode($event)"
                            v-model="cropperViewMode"
                            value="1"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_viewmode_2"
                            alt="VIEW MODE 1 - Restrict the crop box not to exceed the size of the canvas"
                            title="VIEW MODE 1 - Restrict the crop box not to exceed the size of the canvas"
                            >VM 1</label
                          >
                          <!-- View Mode 2 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_viewmode"
                            id="btnradio_viewmode_3"
                            @input="ci_setViewMode($event)"
                            v-model="cropperViewMode"
                            value="2"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_viewmode_3"
                            alt="VIEW MODE 2 - Restrict the minimum canvas size to fit within the container. If the proportions of the canvas and the container differ, the minimum canvas will be surrounded by extra space in one of the dimensions"
                            title="VIEW MODE 2 - Restrict the minimum canvas size to fit within the container. If the proportions of the canvas and the container differ, the minimum canvas will be surrounded by extra space in one of the dimensions"
                            >VM 2</label
                          >
                          <!-- View Mode 3 -->
                          <input
                            type="radio"
                            class="btn-check"
                            name="btnradio_viewmode"
                            id="btnradio_viewmode_4"
                            @input="ci_setViewMode($event)"
                            v-model="cropperViewMode"
                            value="3"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradio_viewmode_4"
                            alt="VIEW MODE 3 - Restrict the minimum canvas size to fill fit the container. If the proportions of the canvas and the container are different, the container will not be able to fit the whole canvas in one of the dimensions"
                            title="VIEW MODE 3 - Restrict the minimum canvas size to fill fit the container. If the proportions of the canvas and the container are different, the container will not be able to fit the whole canvas in one of the dimensions"
                            >VM 3</label
                          >
                        </div>

                        <hr class="sepGray" />

                        <!-- Set Dimensions -->
                        <div class="container p-0">
                          <div class="title-column mt-4">SET DIMENSIONS</div>
                          <!-- Set Width value -->
                          <div class="input-group mb-1">
                            <span class="input-group-text w-25">WIDTH</span>
                            <input
                              type="number"
                              class="form-control"
                              style="text-align: center"
                              min="1"
                              step="1"
                              v-model="modelCroppedWidth"
                              @input="ci_onChangeSetWidth"
                            />
                            <span class="input-group-text w-25">px</span>
                          </div>
                          <!-- Set Height value -->
                          <div class="input-group mb-3">
                            <span class="input-group-text w-25">HEIGHT</span>
                            <input
                              type="number"
                              class="form-control"
                              style="text-align: center"
                              step="1"
                              v-model="modelCroppedHeight"
                              @input="ci_onChangeSetHeight"
                            />
                            <span class="input-group-text w-25">px</span>
                          </div>
                        </div>

                        <!-- Image Filters -->
                        <div class="container p-0">
                          <div class="title-column mt-4">FILTERS</div>
                          <!-- Blur -->
                          <!-- <div class="row m-0">
                          <div class="form-label mt-3 mb-1">BLUR</div>
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value="0"
                            step="1"
                            data-filter="blur"
                            data-scale="px"
                            class="p-0 w-100 filter-range"
                            @input="ci_applyFilter()"
                          />
                          </div>-->
                          <!-- Brightness -->
                          <div class="row m-0">
                            <div class="form-label mt-2 mb-1">
                              BRIGHTNESS
                              <span class="float-end">{{ filter_Brightness / 100 }}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="200"
                              step="1"
                              data-filter="brightness"
                              data-scale="%"
                              class="p-0 w-100 filter-range"
                              v-model="filter_Brightness"
                              @input="ci_applyFilter()"
                            />
                          </div>
                          <!-- Contrast -->
                          <div class="row m-0">
                            <div class="form-label mt-3 mb-1">
                              CONTRAST
                              <span class="float-end">{{ filter_Contrast / 100 }}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="200"
                              step="1"
                              data-filter="contrast"
                              data-scale="%"
                              class="p-0 w-100 filter-range"
                              v-model="filter_Contrast"
                              @input="ci_applyFilter()"
                            />
                          </div>
                          <!-- Grayscale -->
                          <div class="row m-0">
                            <div class="form-label mt-3 mb-1">
                              GRAYSCALE
                              <span class="float-end">{{ filter_Grayscale / 100 }}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="100"
                              step="1"
                              data-filter="grayscale"
                              data-scale="%"
                              class="p-0 w-100 filter-range"
                              v-model="filter_Grayscale"
                              @input="ci_applyFilter()"
                            />
                          </div>
                          <!-- Saturate -->
                          <div class="row m-0">
                            <div class="form-label mt-3 mb-1">
                              SATURATE
                              <span class="float-end">{{ filter_Saturate }}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="3"
                              step="0.1"
                              data-filter="saturate"
                              data-scale
                              class="p-0 w-100 filter-range"
                              v-model="filter_Saturate"
                              @input="ci_applyFilter()"
                            />
                          </div>
                          <!-- Hue Rotate -->
                          <div class="row m-0">
                            <div class="form-label mt-3 mb-1">
                              HUE ROTATE
                              <span class="float-end">{{ filter_HueRotate }}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="360"
                              step="1"
                              data-filter="hue-rotate"
                              data-scale="deg"
                              class="p-0 w-100 filter-range"
                              v-model="filter_HueRotate"
                              @input="ci_applyFilter()"
                            />
                          </div>
                          <!-- Invert -->
                          <div class="row m-0">
                            <div class="form-label mt-3 mb-1">
                              INVERT
                              <span class="float-end">{{ filter_Invert / 100 }}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="100"
                              step="1"
                              data-filter="invert"
                              data-scale="%"
                              class="p-0 w-100 filter-range"
                              v-model="filter_Invert"
                              @input="ci_applyFilter()"
                            />
                          </div>
                          <!-- Sepia -->
                          <div class="row m-0">
                            <div class="form-label mt-3 mb-1">
                              SEPIA
                              <span class="float-end">{{ filter_Sepia / 100 }}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="100"
                              step="1"
                              data-filter="sepia"
                              data-scale="%"
                              class="p-0 w-100 filter-range"
                              v-model="filter_Sepia"
                              @input="ci_applyFilter()"
                            />
                          </div>
                          <!-- Tint -->
                          <svg class="svg-tint-filter">
                            <filter
                              id="filterColorize"
                              color-interpolation-filters="sRGB"
                            >
                              <feColorMatrix
                                type="matrix"
                                :values="ci_colorMatrixValue"
                                x="0"
                                y="0"
                                height="100%"
                                width="100%"
                              />
                            </filter>
                          </svg>
                          <!-- Tint Red -->
                          <div class="row p-0">
                            <div class="col-6">
                              <div class="form-label mt-3 mb-1">
                                TINT RED
                                <span class="float-end">{{ filter_tintRed }}</span>
                              </div>
                              <input
                                type="range"
                                min="-1"
                                max="3"
                                step="0.1"
                                class="p-0 w-100"
                                v-model="filter_tintRed"
                                @input="ci_onChangeSetTint"
                              />
                            </div>
                            <div class="col-6">
                              <div class="form-label mt-3 mb-1">
                                TINT ADD RED
                                <span class="float-end">{{ filter_tintAddRed }}</span>
                              </div>
                              <input
                                type="range"
                                min="-1"
                                max="3"
                                step="0.1"
                                class="p-0 w-100"
                                v-model="filter_tintAddRed"
                                @input="ci_onChangeSetTint"
                              />
                            </div>
                          </div>
                          <!-- Tint Green -->
                          <div class="row p-0">
                            <div class="col-6">
                              <div class="form-label mt-3 mb-1">
                                TINT GREEN
                                <span class="float-end">{{ filter_tintGreen }}</span>
                              </div>
                              <input
                                type="range"
                                min="-1"
                                max="3"
                                step="0.1"
                                class="p-0 w-100"
                                v-model="filter_tintGreen"
                                @input="ci_onChangeSetTint"
                              />
                            </div>
                            <div class="col-6">
                              <div class="form-label mt-3 mb-1">
                                TINT ADD GREEN
                                <span class="float-end">{{ filter_tintAddGreen }}</span>
                              </div>
                              <input
                                type="range"
                                min="-1"
                                max="3"
                                step="0.1"
                                class="p-0 w-100"
                                v-model="filter_tintAddGreen"
                                @input="ci_onChangeSetTint"
                              />
                            </div>
                          </div>
                          <!-- Tint Blue -->
                          <div class="row p-0">
                            <div class="col-6">
                              <div class="form-label mt-3 mb-1">
                                TINT BLUE
                                <span class="float-end">{{ filter_tintBlue }}</span>
                              </div>
                              <input
                                type="range"
                                min="-1"
                                max="3"
                                step="0.1"
                                class="p-0 w-100"
                                v-model="filter_tintBlue"
                                @input="ci_onChangeSetTint"
                              />
                            </div>
                            <div class="col-6">
                              <div class="form-label mt-3 mb-1">
                                TINT ADD BLUE
                                <span class="float-end">{{ filter_tintAddBlue }}</span>
                              </div>
                              <input
                                type="range"
                                min="-1"
                                max="3"
                                step="0.1"
                                class="p-0 w-100"
                                v-model="filter_tintAddBlue"
                                @input="ci_onChangeSetTint"
                              />
                            </div>
                          </div>
                          <!-- Reset Filters -->
                          <button
                            ref="btn_reset_filters"
                            @click="ci_resetFilters"
                            type="button"
                            class="btn button-outline-1 small float-end mt-4"
                          >
                            <span> <i class="zmdi zmdi-refresh"></i>Reset Filters </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr class="sepGray" />
                  </div>

                  <!-- List images versions -->
                  <table class="table-bordered table-responsive-md table-setup">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th width="115">THUMBNAIL</th>
                        <th>FILENAME</th>
                        <th>FILE SIZE</th>
                        <th>RESOLUTION</th>
                        <th>DEFAULT</th>
                        <th width="180">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody class="table-body-font td-vertical-center">
                      <!-- TR Original Image Version -->
                      <tr>
                        <!-- Thumbnail original image-->
                        <td class="td-center w100-px">
                          <img
                            @click.prevent="openPopupImage(image.originalImageUri)"
                            :src="image.thumbImageUri"
                            class="img-fluid thumb pt-checker"
                            style="cursor: pointer"
                            title="OPEN"
                          />
                        </td>
                        <!-- Name -->
                        <td>{{ getFilenameFromUrl(image.originalImageUri) }}</td>
                        <!-- Size -->
                        <td class="td-center">
                          {{ formatBytes(image.originalImageSize) }}
                        </td>
                        <!-- Resolution -->
                        <td class="td-center">
                          <ul class="list-tags">
                            <li>
                              <span class="badge-colored blue"
                                >{{ srcImageWidth }} x
                                {{ srcImageHeight }} [original]</span
                              >
                            </li>
                          </ul>
                        </td>
                        <!-- Default -->
                        <td class="td-center">
                          <div
                            class="form-check form-switch correct m-0 d-flex justify-content-center"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="isOriginalDefault"
                              @change="handleOriginalImageDefault()"
                            />
                          </div>
                        </td>
                        <!-- Actions -->
                        <td class="td-actions td-center">
                          <a :href="image.originalImageUri" target="_blank" download>
                            <button alt="DOWNLOAD" title="DOWNLOAD">
                              <i class="zmdi zmdi-download zmdi-hc-lg"></i>
                            </button>
                          </a>
                          <button
                            @click="copyTextToClipboard(image.originalImageUri, 'URL')"
                            alt="COPY"
                            title="COPY"
                          >
                            <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                          </button>
                        </td>
                      </tr>
                      <!-- TR Other Versions -->
                      <tr
                        v-for="(item, index) in imageDeliverables"
                        :key="index"
                        :id="'item_' + index"
                      >
                        <!-- Thumbnail -->
                        <td class="td-center w100-px">
                          <img
                            @click.prevent="openPopupImage(item.sas_url)"
                            :src="getThumbnail(item)"
                            class="img-fluid thumb pt-checker"
                            style="cursor: pointer"
                            title="OPEN"
                          />
                        </td>

                        <!-- Name -->
                        <td>{{ item.fileName }}</td>
                        <!-- Size -->
                        <td class="td-center">{{ formatBytes(item.size) }}</td>
                        <!-- Resolution -->
                        <td class="td-center">
                          <ul class="list-tags">
                            <li>
                              <span
                                v-if="
                                  getCustomAspectRatioFromFileName(item.sas_url).type == 1
                                "
                                class="badge-colored green"
                                >{{ getResolution(item.width, item.height)
                                }}{{
                                  getCustomAspectRatioFromFileName(item.sas_url)
                                    .reduceRatio
                                }}</span
                              >
                              <span
                                v-if="
                                  getCustomAspectRatioFromFileName(item.sas_url).type == 0
                                "
                                class="badge-colored fucsia"
                                >{{ getResolution(item.width, item.height)
                                }}{{
                                  getCustomAspectRatioFromFileName(item.sas_url)
                                    .reduceRatio
                                }}</span
                              >
                            </li>
                          </ul>
                        </td>
                        <!-- Default -->
                        <td class="td-center">
                          <div
                            class="form-check form-switch correct m-0 d-flex justify-content-center"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="item.isDefault"
                              @change="handleImageDeliverableDefault($event, item, index)"
                            />
                          </div>
                        </td>
                        <!-- Actions -->
                        <td class="td-actions td-center">
                          <!-- Delete -->
                          <button
                            v-if="
                              getCustomAspectRatioFromFileName(item.sas_url).type == 1
                            "
                            @click="deleteImageVersion(item, index)"
                            alt="DELETE CUSTOM VERSION"
                            title="DELETE CUSTOM VERSION"
                          >
                            <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                          </button>
                          <!-- Download -->
                          <a :href="item.sas_url" target="_blank" download>
                            <button alt="DOWNLOAD" title="DOWNLOAD">
                              <i class="zmdi zmdi-download zmdi-hc-lg"></i>
                            </button>
                          </a>
                          <!-- Copy URL -->
                          <button
                            @click="copyTextToClipboard(item.sas_url, 'URL')"
                            alt="COPY"
                            title="COPY"
                          >
                            <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Attachments -->
            <div class="tab-pane fade" id="attachments">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Attachments -->
                <div class="row my-3 m-0 padding-x-4px">
                  <!-- Add file attachment -->
                  <div class="container-fluid p-0 mb-2">
                    <button
                      ref="btn_add_attach"
                      type="button"
                      class="btn button-outline-1 small float-end"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_addattachment"
                      aria-expanded="false"
                      aria-controls="collapse_addattachment"
                    >
                      <span> <i class="zmdi zmdi-plus"></i>Add File </span>
                    </button>
                  </div>

                  <!-- Browse Attachment file button -->
                  <div class="collapse p-0" id="collapse_addattachment">
                    <div class="form-group p-0">
                      <div class="my-3 input-group">
                        <span class="input-group-text px-3">
                          <i class="zmdi zmdi-file"></i>
                        </span>
                        <input
                          id="attachmentFile"
                          ref="attachmentFile"
                          type="file"
                          accept="application/*, video/*, image/*, audio/*"
                          v-on:change="handleAttachmentUpload($event)"
                          hidden
                        />
                        <input
                          type="text"
                          class="form-control form-browse"
                          placeholder="Chose file..."
                          v-model="attachmentModelFile"
                          disabled
                        />
                        <button
                          class="btn button-outline-1 small px-4"
                          type="button"
                          :disabled="isUploadAttachReady"
                          @click.prevent="$refs.attachmentFile.click()"
                        >
                          <i class="fa-solid fa-folder-open"></i>Browse Files
                        </button>
                      </div>
                    </div>
                    <!-- Upload Attachment List -->
                    <div
                      v-if="isUploadAttachReady"
                      id="attach_list"
                      class="row m-0 p-0 mt-2"
                    >
                      <table class="table-bordered table-responsive-md table-setup">
                        <thead class="table-header-font">
                          <tr class="td-center">
                            <th>NAME</th>
                            <th width="80">ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody class="table-body-font td-vertical-center">
                          <tr>
                            <!-- Name -->
                            <td>{{ attachmentFileObj.name }}</td>
                            <!-- Actions -->
                            <td class="td-actions td-center">
                              <button
                                @click="removeUploadAttachFromList"
                                alt="DELETE"
                                title="DELETE"
                              >
                                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                              </button>
                              <button
                                @click="uploadAttachmentFile"
                                alt="UPLOAD"
                                title="UPLOAD"
                              >
                                <i class="fa-solid fa-file-arrow-up"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr class="sepGray" />
                  <!-- No files attachments -->
                  <div v-if="!imageAttachments.length > 0">
                    This list is empty, please add file
                  </div>
                  <!-- List File Attachments -->
                  <table
                    v-if="imageAttachments.length > 0"
                    class="table-bordered table-responsive-md table-setup mt-2"
                  >
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>NAME</th>
                        <th width="90">PUBLISHED</th>
                        <th width="160">
                          CREATED
                          <br />MODIFIED
                        </th>
                        <th width="80">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody class="table-body-font td-vertical-center">
                      <tr v-for="(attachment, index) in imageAttachments" :key="index">
                        <!-- Name -->
                        <td>{{ attachment.name }}</td>
                        <!-- Published -->
                        <td class="td-center">
                          <button
                            :title="
                              attachment.isPublished
                                ? 'UNPUBLISH ATTACHMENT'
                                : 'PUBLISH ATTACHMENT'
                            "
                            :alt="
                              attachment.isPublished
                                ? 'UNPUBLISH ATTACHMENT'
                                : 'PUBLISH ATTACHMENT'
                            "
                            type="button"
                            class="toggle-btn"
                            :class="attachment.isPublished ? 'active' : ''"
                            @click="
                              attachment.isPublished
                                ? ((attachment.isPublished = false),
                                  (isChangesSaved[5] = false))
                                : ((attachment.isPublished = true),
                                  (isChangesSaved[5] = false))
                            "
                          >
                            <i class="zmdi zmdi-check"></i>
                          </button>
                        </td>
                        <!-- Created on -->
                        <td class="td-center">
                          <ul class="unstyled">
                            <li>
                              {{ convertUnixDate(attachment.creationDateUnixTimeStamp) }}
                            </li>
                            <li>
                              {{
                                convertUnixDate(attachment.modificationDateUnixTimeStamp)
                              }}
                            </li>
                          </ul>
                        </td>
                        <!-- Actions -->
                        <td class="td-actions td-center">
                          <a :href="attachment.uri" target="_blank">
                            <button alt="VIEW" title="VIEW">
                              <i class="zmdi zmdi-eye zmdi-hc-lg"></i>
                            </button>
                          </a>
                          <button
                            @click="deleteAttachment(index)"
                            alt="DELETE"
                            title="DELETE"
                          >
                            <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr class="sepGray" />
          <!-- Buttons Back, Cancel, Save image -->
          <div class="container-fluid p-0 my-3">
            <!-- back to list -->
            <button
              v-if="!notChangesSaved"
              @click="backToList()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-arrow-left"></i>Back to List</span>
            </button>
            <!-- Cancel -->
            <button
              v-if="notChangesSaved"
              @click="cancelImage()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>Cancel</span>
            </button>
            <!-- Save Image Obj -->
            <button
              v-if="!isImageZeroDimension"
              @click="updateImage()"
              type="button"
              class="btn button-outline-1 float-end"
              :class="notChangesSaved ? 'notSaved' : ''"
            >
              <span> <i class="zmdi zmdi-floppy"></i>Save Image</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import CommonMethods from "@/services/CommonMethods";
import EventService from "@/services/EventService.js";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// Vue Cropper (cropper.js)
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    Multiselect,
    VueCropper,
  },
  data() {
    return {
      isImageZeroDimension: false,
      placeholder: require("@/assets/images/image-placeholder.png"),
      is_RU_LangAvailable: false,
      imgOriginalSrc: "",
      // Cropper image
      isCropperRedraw: false,
      imgSrc: "",
      // imgSrc: require("@/assets/images/4k.jpg"),
      // imgSrc:
      // "https://eclexiaring01.azureedge.net/images/i-53651756-f2a5-499c-ac00-4526bca09c3f/750/0/Mezzanine_Original.jpg",
      // imgSrc: "https://assets.stoumann.dk/img/filter01.jpg",
      // imgSrc:"https://eclexiamipv2.blob.core.windows.net/thumbnails/70747/003_40186_Snapshot1c34cc35275744488efa002db6270869.png",
      // imgSrc: "https://www.fasasoft.eu/pictures/people-01.jpeg",
      // imgSrc:"https://www.betterteam.com/images/what-is-people-operations-2400x2400-20201118.jpg",
      defaultAspectRatioModel: 5,
      aspectRatioModel: 5,
      defaultViewMode: 2,
      cropperViewMode: 2,
      srcImageWidth: 0,
      srcImageHeight: 0,
      croppedImage: "",
      renderedImage: "",
      canvasPic: null,
      ci_data: null,
      cropperEditor: null,
      imageCroppedWidth: 0,
      imageCroppedHeight: 0,
      staticCroppedWidth: 0,
      staticCroppedHeight: 0,
      staticCroppedAspectRatio: 0,
      modelCroppedWidth: 0,
      modelCroppedHeight: 0,
      filter_Brightness: 100,
      filter_Contrast: 100,
      filter_Grayscale: 0,
      filter_Saturate: 1,
      filter_HueRotate: 0,
      filter_Invert: 0,
      filter_Sepia: 0,
      filter_tintRed: 1,
      filter_tintGreen: 1,
      filter_tintBlue: 1,
      filter_tintAddRed: 0,
      filter_tintAddGreen: 0,
      filter_tintAddBlue: 0,
      //Digital Signage
      model_check_digital_signage: false,
      isDigitalSignage: false,
      // editor: ClassicEditor,
      editor: DecoupledEditor,
      editorConfig: {
        language: "it",
        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "underline",
            "link",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "indent",
            "outdent",
          ],
          shouldNotGroupWhenFull: false,
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,
          // ],
        },
      },
      testHTML:
        "<p data-pg-id='96838'><font color='#0000ff'>Lorem</font> <b data-pg-id='96839'>ipsum</b></p>",
      titolo: "",
      statusIndex: 0,
      upload_status: [
        { label: "Ready" },
        { label: "Upload in progress" },
        { label: "Encoding in progress" },
        { label: "Aborted" },
        { label: "Error" },
      ],
      requestUploadAudioFile: null,
      BASEURL: this.$appGlobalVariables.BASEURL_UPLOAD,
      attachmentModelFile: "",
      file: null,
      tempNewImg: null,
      spinnerLoader: null,
      attributes_categories: [],
      tagsCategories: [],
      tagsCategoriesValue: [],
      attributes_tags: [],
      tagsTags: [],
      tagsTagsValue: [],
      attributes_areas: [],
      tagsAreas: [],
      tagsAreasValue: [],
      isChangesSaved: [],
      isLoading: true,
      isVisible: false,
      isPreviewThumbnails: true,
      isUploadThumbReady: false,
      isUploadTakeThumbReady: false,
      isUploadAttachReady: false,
      isUploadSubtitReady: false,
      isUploadAudioReady: false,
      isProcessRunning: false,
      isUploadCompleted: false,
      uploadPercentage: 0,
      imageId: "",
      awaitImage: [],
      image: [],
      videoProperties: {
        videoSrc: "",
        poster: "",
        width: 0,
        height: 0,
      },
      imageDeliverables: [],
      videoDeliverables_audio: [],
      videoDeliverables_video: [],
      videoThumbnails: [],
      videoDirectUrl: [],
      imageAttachments: [],
      videoSubtitles: [],
      imageMetadata: [],
      videoMarkers: [],
      videoMarkersMetadataDetails: [],
      imageMetadataDetails_it: {
        lang: "it",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },
      imageMetadataDetails_en: {
        lang: "en",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },
      imageMetadataDetails_ru: {
        lang: "ru",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },

      videoMarkerMetadataDetails_it: [],
      videoMarkerMetadataDetails_en: [],
      videoMarkerMetadataDetails_ru: [],
      imageSelectDirectUrl: "",
      videoFullResolutionChart: [
        {
          description: "4320p (8K) - 7680 x 4320",
          pixelSize: [{ width: 7680, height: 4320 }],
        },
        {
          description: "2160p (4K) - 3840 x 2160",
          pixelSize: [{ width: 3840, height: 2160 }],
        },
        {
          description: "1440p (2K) - 2560 x 1440",
          pixelSize: [{ width: 2560, height: 1440 }],
        },
        {
          description: "1080p (FHD) - 1920 x 1080",
          pixelSize: [{ width: 1920, height: 1080 }],
        },
        {
          description: "720p (HD) - 1280 x 720",
          pixelSize: [{ width: 1280, height: 720 }],
        },
        {
          description: "480p (SD) - 852 x 480",
          pixelSize: [{ width: 852, height: 480 }],
        },
        {
          description: "240p - 640 x 360",
          pixelSize: [{ width: 640, height: 360 }],
        },
        {
          description: "360p - 428 x 240",
          pixelSize: [{ width: 428, height: 240 }],
        },
      ],
      videoResolutionChart: [
        {
          description: "720p (HD) - 1280 x 720",
          pixelSize: { width: 1280, height: 720 },
        },
        {
          description: "480p (SD) - 852 x 480",
          pixelSize: { width: 852, height: 480 },
        },
        {
          description: "360p - 640 x 360",
          pixelSize: { width: 640, height: 360 },
        },
        {
          description: "240p - 428 x 240",
          pixelSize: { width: 428, height: 240 },
        },
      ],
      mainImageDataConverted: {},
      na: "",
      attachmentFileTypes: [
        "application/pdf",
        "application/doc",
        "application/txt",
        "image/bmp",
        "image/jpeg",
        "image/png",
        "image/webp",
        "video/mp4",
        "video/quicktime",
        "video/avi",
        "video/webm",
        "audio/mp3",
        "audio/mpeg",
        "audio/wav",
        "audio/ogg",
      ],
      imageAccepted: "image/png, image/jpeg image/webp",
      imageFileTypes: ["image/jpeg", "image/png", "image/webp"],
      twoGB: 2147483648,
      isOriginalDefault: false,
    };
  },
  computed: {
    getPreviewVideoOptions() {
      return this.setPreviewVideoOptions();
    },
    notChangesSaved() {
      if (
        !this.isChangesSaved[1] ||
        !this.isChangesSaved[2] ||
        !this.isChangesSaved[3] ||
        !this.isChangesSaved[4] ||
        !this.isChangesSaved[5] ||
        !this.isChangesSaved[6]
      ) {
        return true;
      }
      return false;
    },
    ci_colorMatrixValue() {
      let value = `${this.filter_tintRed} 0 0 0 ${this.filter_tintAddRed}   0 ${this.filter_tintGreen} 0 0 ${this.filter_tintAddGreen}   0 0 ${this.filter_tintBlue} 0 ${this.filter_tintAddBlue}    0 0 0 1 0`;
      return value;
    },
  },
  methods: {
    backToList() {
      this.$router.push({ name: "view-pictures-all" });
      // this.$router.push({ name: "view-pictures-all", query: { recall: "1" } });
    },
    cancelImage() {
      this.$router.push({ name: "view-pictures-all" });
      // this.$router.push({ name: "view-pictures-all", query: { recall: "1" } });
    },
    handleOriginalImageDefault() {
      for (let i = 0; i < this.imageDeliverables.length; i++) {
        this.imageDeliverables[i].isDefault = false;
      }
      this.isOriginalDefault = true;

      this.isChangesSaved[2] = false;
    },
    handleImageDeliverableDefault(event, item, index) {
      console.log("event,item.isDefault", event.target.checked, item.isDefault);
      for (let i = 0; i < this.imageDeliverables.length; i++) {
        this.imageDeliverables[i].isDefault = false;
      }
      this.imageDeliverables[index].isDefault = true;
      this.isOriginalDefault = false;

      console.log(item.deliverableId, item.isDefault);
      this.isChangesSaved[2] = false;
      // this.getImageDataFromAPI();
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    // *****************************
    // START ** Rich Text Updates **
    // *****************************
    updateDescription_it(htmlValue) {
      this.isChangesSaved[1] = false;
      this.imageMetadataDetails_it.metaDataDetails.description = htmlValue;
    },
    updateDescription_en(htmlValue) {
      this.isChangesSaved[1] = false;
      this.imageMetadataDetails_en.metaDataDetails.description = htmlValue;
    },
    updateDescription_ru(htmlValue) {
      this.isChangesSaved[1] = false;
      this.imageMetadataDetails_ru.metaDataDetails.description = htmlValue;
    },
    // *****************************
    // END ** Rich Text Updates **
    // *****************************

    initChangesSaved() {
      this.isChangesSaved[1] = true;
      this.isChangesSaved[2] = true;
      this.isChangesSaved[3] = true;
      this.isChangesSaved[4] = true;
      this.isChangesSaved[5] = true;
      this.isChangesSaved[6] = true;
    },
    renderHTML(partId, index, richText) {
      let element = document.getElementById(partId + index);
      if (element != null) {
        element.innerHTML = richText;
      }
    },

    // *******************************
    // START ** Manage Image Object **
    // *******************************
    async getImageDataFromAPI() {
      //Get image from API
      this.imageId = this.$route.params.id;
      this.isLoading = true;
      this.awaitImage = await EventService.getImage(
        this.imageId,
        this.$store.getters.getToken.token
      );

      if (this.awaitImage.status != 200) {
        console.error("Image >> id:" + this.imageId + " error during loading data");
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET IMAGE: An error has occurred, please try again",
          "error"
        );
        return;
      }

      console.log("+++ Get Image >> data >>", this.awaitImage);

      //GET IMAGE DATA ARRAY
      this.image = this.awaitImage.data;
      console.log("+++ this.image:", this.image);

      // get image original
      this.getImgOriginalSrc();
      this.getImgSrc();
      this.imageDimension(this.imgOriginalSrc);

      //get attachments
      this.imageAttachments = this.image.attachments;
      console.log("+++ this.imageAttachments:", this.imageAttachments);

      //get deliverables, set isDefault
      this.imageDeliverables = this.image.deliverables;
      let isDefaultCount = 0;
      for (let i = 0; i < this.imageDeliverables.length; i++) {
        if (this.imageDeliverables[i].isDefault == undefined) {
          this.imageDeliverables[i].isDefault = false;
        }
        if (!this.imageDeliverables[i].isDefault) {
          isDefaultCount++;
        }
      }
      if (isDefaultCount == this.imageDeliverables.length) {
        this.isOriginalDefault = true;
      } else {
        this.isOriginalDefault = false;
      }
      console.log("++ isOriginalDefault", this.isOriginalDefault);
      console.log("++ imageDeliverables", this.imageDeliverables);

      //get metadata
      this.imageMetadata = this.image.metadata;
      console.log("+++ this.imageMetadata:", this.imageMetadata);
      this.getAllMetadataDetails();

      //get attributes from data table attributes (all areas, all categories, all tags)
      // get areas, get categories, get tags
      this.getAttributes();

      this.mainImageDataConverted = this.outputMainImageData();

      this.isLoading = false;
      this.isVisible = true;
    },

    updateImage() {
      if (!this.validationFields()) return false;
      this.$swal
        .fire({
          title: "Update",
          html:
            "Are you sure you want to update all data?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doUpdateImage();
          }
        });
    },
    async doUpdateImage() {
      //check required field
      if (!this.checkRequiredField()) return;

      let imageToUpload = { ...this.image };
      console.log("+++ this.image:", this.image);

      //important: remove imageId key before to upload API
      delete imageToUpload.imageId;

      //UPDATE IMAGE SEQUENCE

      //update areas
      imageToUpload.areas = this.getAttributesAdded(
        this.tagsAreasValue,
        this.tagsAreas,
        "areaId"
      );

      //update attachments
      imageToUpload.attachments = this.imageAttachments;

      //upload categories
      imageToUpload.categories = this.getAttributesAdded(
        this.tagsCategoriesValue,
        this.tagsCategories,
        "catId"
      );

      //upload deliverables
      imageToUpload.deliverables = this.imageDeliverables;
      console.log("** imageToUpload.deliverables", imageToUpload.deliverables);

      //upload digitalEdition (just do it, bind image.digitalEdition)

      //upload metadata
      imageToUpload.metadata = [
        this.imageMetadataDetails_it,
        this.imageMetadataDetails_en,
        this.imageMetadataDetails_ru,
      ];

      //upload tags
      imageToUpload.tags = this.getAttributesAdded(
        this.tagsTagsValue,
        this.tagsTags,
        "tagId"
      );

      // Upload image
      console.log("+++ imageToUpload:", imageToUpload);

      //Start upload image obj by API
      this.showLoader(true);
      const resultAPI = await EventService.updateImageObj(
        imageToUpload,
        this.image.imageId,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);

      if (resultAPI.status != 200) {
        console.error(
          "+++ updateImage >> id:" + this.image.imageId + " error during updating data"
        );
        this.$root.addSnackBarMsg(
          "SAVE IMAGE: An error has occurred, please try again",
          "error"
        );
        return;
      }

      this.$root.addSnackBarMsg("The image was updated successfully", "info");
      this.initChangesSaved();

      //refresh page
      //this.$router.go();
    },
    checkRequiredField() {
      //metadata en title, cannot be empty
      if (this.imageMetadataDetails_en.title == "") {
        console.error(
          "** this.imageMetadataDetails_en.title: is required. This field cannot be empty"
        );
        this.$root.addSnackBarMsg("METADATA EN TITLE: ", "warning");
        return false;
      }
      //metadata en author, cannot be empty
      if (this.imageMetadataDetails_en.author == "") {
        console.error(
          "** this.imageMetadataDetails_en.author: is required. This field cannot be empty"
        );
        this.$root.addSnackBarMsg(
          "METADATA EN AUTHOR: This field cannot be empty",
          "warning"
        );
        return false;
      }

      //else
      return true;
    },
    // *****************************
    // END ** Manage Image Object **
    // *****************************

    handlerContextmenu(e) {
      e.preventDefault();
    },
    pauseVideo() {
      if (this.$refs.videoPlayer_GeneralInfo) {
        this.$refs.videoPlayer_GeneralInfo.pause();
      }
      if (this.$refs.videoPlayer_Preview) {
        this.$refs.videoPlayer_Preview.pause();
      }
      if (this.$refs.videoPlayer_Marker) {
        this.$refs.videoPlayer_Marker.pause();
      }
      let audioPlayers = document.getElementsByClassName("audioPlayer");
      console.log(audioPlayers);
      for (let i = 0; i < audioPlayers.length; i++) {
        if (audioPlayers[i]) {
          audioPlayers[i].pause();
        }
      }
    },
    setPreviewVideoOptions() {
      const src = this.videoProperties.videoSrc;
      const videoOptions = {
        autoplay: false,
        controls: true,
        sources: {
          src: src,
          type: "video/mp4",
        },
      };
      return videoOptions;
    },

    outputMainImageData() {
      let returnOutputObjImage = {};

      // creationDateUnixTimeStamp
      let creationDateUnixTimeStamp = this.convertUnixDate(
        this.image.creationDateUnixTimeStamp
      );

      //modificationDateUnixTimeStamp
      let modificationDateUnixTimeStamp = this.convertUnixDate(
        this.image.modificationDateUnixTimeStamp
      );

      //returnOutputObjImage
      returnOutputObjImage = {
        //creationDateUnixTimeStamp & modificationDateUnixTimeStamp
        creationDateUnixTimeStamp: creationDateUnixTimeStamp,
        modificationDateUnixTimeStamp: modificationDateUnixTimeStamp,
      };
      return returnOutputObjImage;
    },

    // ***************************
    // START ** Manage Metadata **
    // ***************************
    getAllMetadataDetails() {
      //metadetails it
      this.imageMetadataDetails_it = this.getMetadatadetailsByLang(
        this.image.metadata,
        "lang",
        "it",
        "metaDataDetails"
      );

      //metadetails en
      this.imageMetadataDetails_en = this.getMetadatadetailsByLang(
        this.image.metadata,
        "lang",
        "en",
        "metaDataDetails"
      );

      //metadetails ru
      this.imageMetadataDetails_ru = this.getMetadatadetailsByLang(
        this.image.metadata,
        "lang",
        "ru",
        "metaDataDetails"
      );
    },
    getMetadatadetailsByLang(videoObjKey, lang, value, metaDetails) {
      let metaDataDetails = {
        title: this.na,
        author: this.na,
        s_abstract: this.na,
        eyelet: this.na,
        description: this.na,
      };
      const searchMetaDataDetails = this.searchJsonArrayValueFromKey(
        videoObjKey,
        lang,
        value,
        metaDetails
      );

      if (searchMetaDataDetails.length == 0)
        return { lang: value, metaDataDetails: metaDataDetails };

      metaDataDetails = {
        title:
          searchMetaDataDetails[0].value.title != ""
            ? searchMetaDataDetails[0].value.title
            : this.na,
        author:
          searchMetaDataDetails[0].value.author != ""
            ? searchMetaDataDetails[0].value.author
            : this.na,
        s_abstract:
          searchMetaDataDetails[0].value.s_abstract != ""
            ? searchMetaDataDetails[0].value.s_abstract
            : this.na,
        eyelet:
          searchMetaDataDetails[0].value.eyelet != ""
            ? searchMetaDataDetails[0].value.eyelet
            : this.na,
        description:
          searchMetaDataDetails[0].value.description != ""
            ? searchMetaDataDetails[0].value.description
            : this.na,
      };

      return { lang: value, metaDataDetails: metaDataDetails };
    },
    onTitleVideoENChange(event) {
      if (event.target.value == "") {
        this.$root.addSnackBarMsg(
          "OVERVIEW METADATA EN TITLE: This field cannot be empty",
          "warning"
        );
      }
    },
    validationFields() {
      if (this.imageMetadataDetails_en.metaDataDetails.title == "") {
        this.$root.addSnackBarMsg(
          "OVERVIEW METADATA EN TITLE: This field cannot be empty",
          "warning"
        );
        return false;
      }
      return true;
    },
    // *************************
    // END ** Manage Metadata **
    // *************************

    getJsonArrayValueFromKey(jsonKey, key) {
      let obj = [];
      for (let i = 0; i < jsonKey.length; i++) {
        for (const [_key, _value] of Object.entries(jsonKey[i])) {
          if (_key == key) {
            obj.push({ key: _key, value: _value });
            break;
          }
        }
      }
      return obj;
    },
    searchJsonArrayValueFromKey(jsonKey, key, valueToSearch, keyToGet) {
      let obj = [];
      for (let i = 0; i < jsonKey.length; i++) {
        for (const [_key, _value] of Object.entries(jsonKey[i])) {
          if (_key == key && _value == valueToSearch) {
            for (const [_keyN, _valueN] of Object.entries(jsonKey[i])) {
              if (_keyN == keyToGet) {
                obj.push({ key: _keyN, value: _valueN });
                break;
              }
            }
            break;
          }
        }
      }
      return obj;
    },
    formatBytes(bytes) {
      return CommonMethods.formatBytes(bytes);
    },
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
    async downloadFileVideo(url) {
      console.log("++ file image uri:", url);

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/video" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    getResolution(width, height) {
      let obj = this.videoResolutionChart.find((o) => o.pixelSize.width === width);
      let str = "-";
      if (width != 0 && height != 0) {
        if (obj) {
          str = obj.description;
        } else {
          str = `${width} x ${height}`;
        }
        return str;
      }
      return str;
    },
    getDirectUrl() {
      let returnDirectUrl = [];
      let sas_url = "";
      let description = "";
      //push original image
      returnDirectUrl.push({
        description: this.srcImageWidth + " x " + this.srcImageHeight + " [original]",
        value: this.image.originalImageUri,
      });
      //push versions
      for (let i = 0; i < this.imageDeliverables.length; i++) {
        description =
          this.imageDeliverables[i].width +
          " x " +
          this.imageDeliverables[i].height +
          " " +
          this.getCustomAspectRatioFromFileName(this.imageDeliverables[i].sas_url)
            .reduceRatio;

        sas_url = this.imageDeliverables[i].sas_url;
        returnDirectUrl.push({
          description: description,
          value: sas_url,
        });
      }
      return returnDirectUrl;
    },

    // *****************************
    // START ** Manage Attributes **
    // *****************************
    getAttributesAdded(attributesValue, arrayAttributes, key) {
      let elementReturn = [];
      let valueAtt, elementFound, attr;
      for (let i = 0; i < attributesValue.length; i++) {
        valueAtt = attributesValue[i];
        elementFound = arrayAttributes.find((el) => el.value == valueAtt);
        if (elementFound != undefined) {
          attr = {
            name: elementFound.label,
            [key]: valueAtt,
            lang: process.env.VUE_APP_DEFAULT_METADATA_LANG,
            namesearch: elementFound.label.toLowerCase(),
          };
          elementReturn.push(attr);
        }
      }
      return elementReturn;
    },
    // Manage Categories ===
    async getAttributesCategories() {
      await EventService.getCategories(this.$store.getters.getToken.token);
      this.attributes_categories = CommonMethods.getSemplifyAttributes(
        this.$store.getters.categories,
        "catId"
      );
    },
    createTagsCategories() {
      this.tagsCategories = [];
      for (let i = 0; i < this.attributes_categories.length; i++) {
        this.tagsCategories.push({
          value: this.attributes_categories[i].catId,
          label: this.attributes_categories[i].name,
        });
      }
    },
    getTagsCategories() {
      this.tagsCategoriesValue = [];
      for (let i = 0; i < this.image.categories.length; i++) {
        if (this.image.categories[i].lang == process.env.VUE_APP_DEFAULT_METADATA_LANG) {
          this.tagsCategoriesValue.push(this.image.categories[i].catId);
        }
      }
    },
    // Manage Tags ===
    async getAttributesTags() {
      await EventService.getTags(this.$store.getters.getToken.token);
      this.attributes_tags = CommonMethods.getSemplifyAttributes(
        this.$store.getters.tags,
        "tagId"
      );
    },
    createTagsTags() {
      this.tagsTags = [];
      for (let i = 0; i < this.attributes_tags.length; i++) {
        this.tagsTags.push({
          value: this.attributes_tags[i].tagId,
          label: this.attributes_tags[i].name,
        });
      }
    },
    getTagsTags() {
      this.tagsTagsValue = [];
      for (let i = 0; i < this.image.tags.length; i++) {
        if (this.image.tags[i].lang == process.env.VUE_APP_DEFAULT_METADATA_LANG) {
          this.tagsTagsValue.push(this.image.tags[i].tagId);
        }
      }
    },
    // Manage Areas ===
    async getAttributesAreas() {
      await EventService.getAreas(this.$store.getters.getToken.token);
      this.attributes_areas = CommonMethods.getSemplifyAttributes(
        this.$store.getters.areas,
        "areaId"
      );
    },
    createTagsAreas() {
      this.tagsAreas = [];
      for (let i = 0; i < this.attributes_areas.length; i++) {
        this.tagsAreas.push({
          value: this.attributes_areas[i].areaId,
          label: this.attributes_areas[i].name,
        });
      }
    },
    getTagsAreas() {
      this.tagsAreasValue = [];
      for (let i = 0; i < this.image.areas.length; i++) {
        if (this.image.areas[i].lang == process.env.VUE_APP_DEFAULT_METADATA_LANG) {
          this.tagsAreasValue.push(this.image.areas[i].areaId);
        }
      }
    },
    async getAttributes() {
      //Manage Categories
      await this.getAttributesCategories();
      this.createTagsCategories();
      this.getTagsCategories();
      //Manage Tags
      await this.getAttributesTags();
      this.createTagsTags();
      this.getTagsTags();
      //Manage Areas
      await this.getAttributesAreas();
      this.createTagsAreas();
      this.getTagsAreas();
    },
    // ***************************
    // END ** Manage Attributes **
    // ***************************

    // *****************************
    // START ** Manage Attachment **
    // *****************************
    async deleteAttachment(index) {
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this attachment?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            //call code to delete
            this.callDeleteAttachment(index);
          }
        });
    },
    async callDeleteAttachment(index) {
      //delete element from array
      this.imageAttachments.splice(index, 1);
      this.$root.addSnackBarMsg("Attachment deleted", "info");
      //update image obj
      await this.doUpdateImage();
    },

    handleAttachmentUpload(event) {
      this.attachmentFileObj = {};
      this.isUploadAttachReady = false;
      let tmpFile = event.target.files[0];
      console.log(`** attachment tmpFile: ${tmpFile}`);
      if (!tmpFile) return;
      let tmpFileSize = tmpFile.size;
      let tmpFileType = tmpFile.type;
      console.log(`** attachment: ${tmpFile.name} - ${tmpFileSize} - ${tmpFileType}`);
      if (!this.validAttachmentFileType(tmpFile)) {
        this.$root.addSnackBarMsg(
          `File name ${tmpFile.name}: Not a valid file type. Update your selection.`,
          "warning"
        );
        return;
      }
      if (tmpFileSize === 0) {
        this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
        return;
      }

      this.attachmentModelFile = `File name: ${
        tmpFile.name
      } - File size: ${this.formatBytes(tmpFile.size)}`;
      this.attachmentFileObj = tmpFile;
      this.attachmentFileSrc = URL.createObjectURL(this.attachmentFileObj);

      this.isUploadAttachReady = true;

      // this.statusIndex = 0;
      console.log("** attachment to upload:", this.attachmentFileObj);
    },
    validAttachmentFileType(file) {
      console.log("** attachments file type:", file.type);
      return this.attachmentFileTypes.includes(file.type);
    },
    resetUploadAttachFile() {
      this.attachmentFileObj = {};
      this.attachmentFileSrc = "";
      this.isUploadAttachReady = false;
      this.attachmentModelFile = "";
      //reset input file value
      document.getElementById("attachmentFile").value = "";
    },
    removeUploadAttachFromList() {
      this.resetUploadAttachFile();
      this.$root.addSnackBarMsg("File removed", "info");
      // this.$refs.btn_add_attach.click();
    },
    async uploadAttachmentFile() {
      const formData = new FormData();
      formData.append("file", this.attachmentFileObj, this.attachmentFileObj.name);

      this.isChangesSaved[5] = false;
      this.token = this.$store.getters.getToken.token;
      this.showLoader(true);
      const apiResponseObj = await EventService.uploadFileAttachmentImage(
        formData,
        this.image.imageId,
        this.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error(
          "Upload file attachment >> error during upload item",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg("UPLOAD FILE: Error during uploading item", "error");
        return;
      }

      this.$root.addSnackBarMsg("File uploaded", "info");
      console.log("*** upload file response:", apiResponseObj);
      // add code to refresh array
      this.imageAttachments.push(apiResponseObj.data);

      this.resetUploadAttachFile();
      this.showLoader(false);

      //update image obj
      await this.doUpdateImage();

      this.$refs.btn_add_attach.click();
    },
    // ***************************
    // END ** Manage Attachment **
    // ***************************

    // ***************************
    // START ** Tools & Utility **
    // ***************************
    copyTextToClipboard(textToCopy, what) {
      if (navigator.clipboard) {
        if (textToCopy == "" || textToCopy == null) {
          this.$root.addSnackBarMsg("There is nothing to copy", "warning");
          return;
        }
        navigator.clipboard.writeText(textToCopy).then(() => {
          this.$root.addSnackBarMsg(what + " copied to the clipboard", "info");
        });
      } else {
        this.$root.addSnackBarMsg(
          "This feature is available only in secure contexts (HTTPS)",
          "warning"
        );
        console.warn("This feature is available only in secure contexts (HTTPS)");
      }
    },
    async deleteImageVersion(item, index) {
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this image version?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            //call code to delete
            this.callDeleteImageVersion(item, index);
          }
        });
    },
    async callDeleteImageVersion(item, index) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE IMAGE: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }

      this.showLoader(true);
      const apiResponseObj = await EventService.deleteCustomImageVersion(
        item.deliverableId
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error("Delete image >> error during delete item", apiResponseObj.status);
        this.$root.addSnackBarMsg(
          "DELETE CUSTOM VERSION: Error during delete item",
          "error"
        );
        return false;
      }

      //delete element from array
      if (item.isDefault) {
        for (let i = 0; i < this.imageDeliverables.length; i++) {
          this.imageDeliverables[i].isDefault = false;
        }
        this.isOriginalDefault = true;
      }
      this.imageDeliverables.splice(index, 1);
      this.$root.addSnackBarMsg("Custom version deleted", "info");
      //update image obj
      await this.doUpdateImage();
    },
    formatDuration(duration) {
      let milliseconds = duration * 1000;
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      seconds = seconds % 60;
      minutes = minutes % 60;
      hours = hours % 24;
      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(
        minutes
      )}:${this.padTo2Digits(seconds)}`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },

    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
    convertUnixDate(date) {
      return CommonMethods.convertUnixTimeStampMoment(date);
    },

    returnFileSize(number) {
      if (number < 1024) {
        return `${number} bytes`;
      } else if (number >= 1024 && number < 1048576) {
        return `${(number / 1024).toFixed(1)} KB`;
      } else if (number >= 1048576) {
        return `${(number / 1048576).toFixed(1)} MB`;
      }
    },
    humanFileSize(size) {
      var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    calculateAspectRatio(width, height) {
      return this.reduceRatio(width, height);
    },
    reduceRatio(numerator, denominator) {
      let gcd, temp, divisor, left, right;

      //Greatest Common Divisor
      //Massimo Comun Denominatore (MCD)
      gcd = function (a, b) {
        if (b === 0) return a;
        console.log("++ gcd:", a % b);
        return gcd(b, a % b);
      };

      // take care of the simple case
      if (numerator === denominator) return "1 : 1";

      // make sure numerator is always the larger number
      if (+numerator < +denominator) {
        temp = numerator;
        numerator = denominator;
        denominator = temp;
      }

      divisor = gcd(+numerator, +denominator);
      console.log("++ divisor", divisor);

      if ("undefined" === typeof temp) {
        left = numerator / divisor;
        right = denominator / divisor;
      } else {
        left = denominator / divisor;
        right = numerator / divisor;
      }

      // handle special cases
      if (8 === left && 5 === right) {
        left = 16;
        right = 10;
      }

      return `${left} : ${right}`;
    },
    // **************************
    // END ** Tools & Utility **
    // **************************

    // *************************
    // START ** Cropper Image **
    // *************************
    imageDimension(url) {
      const img = new Image();
      img.onload = () => {
        this.srcImageWidth = img.naturalWidth;
        this.srcImageHeight = img.naturalHeight;
        if (this.srcImageWidth == 0 || this.srcImageHeight == 0) {
          this.isImageZeroDimension = true;
        } else {
          this.isImageZeroDimension = false;
        }
      };
      img.onerror = () => {
        this.isImageZeroDimension = true;
        console.error("++ image loading error, image dimension is 0 x 0");
      };
      img.src = url;
    },
    ci_cropperReady() {
      this.cropperEditor = this.$refs.cropper;
      // this.ci_setViewMode(2);
      this.ci_setValueDimensions();
      this.imageDimension(this.imgSrc);
    },
    ci_setViewMode(event) {
      console.log(Number(event.target.value));
      let mode = Number(event.target.value);
      this.cropperViewMode = mode;
      this.aspectRatioModel = this.defaultAspectRatioModel;
      this.ci_refresh();
    },
    ci_reset() {
      this.cropperEditor.reset();
      this.cropperEditor.setAspectRatio(NaN);
      this.cropperViewMode = this.defaultViewMode;
      this.aspectRatioModel = this.defaultAspectRatioModel;
      this.croppedImage = "";
      this.renderedImage = "";
      this.ci_setValueDimensions();
      this.ci_refresh();
    },
    async ci_cropImage() {
      console.log("++ start cropping image with filters");
      this.showLoader(true);
      await CommonMethods.sleep(300);
      this.croppedImage = "";
      this.renderedImage = "";
      this.canvasPic = null;
      let roundWidth = Math.round(this.ci_getData().width);
      let roundHeight = Math.round(this.ci_getData().height);
      console.log("++ cropped image width:", roundWidth);
      console.log("++ cropped image height:", roundHeight);
      this.staticCroppedWidth = roundWidth;
      this.staticCroppedHeight = roundHeight;
      this.staticCroppedAspectRatio = this.calculateAspectRatio(
        this.staticCroppedWidth,
        this.staticCroppedHeight
      );
      this.croppedImage = this.cropperEditor
        .getCroppedCanvas({
          imageSmoothingQuality: "high",
        })
        .toDataURL();
      this.showLoader(false);
      //copy styles from preview to cropped image
      setTimeout(() => {
        let previewImageFilters = window.getComputedStyle(
          document.querySelector(".previewImage img")
        );
        let imageCrop = document.getElementById("theCroppedImage");
        console.log("previewImageFilters.filter", previewImageFilters.filter);
        imageCrop.style.filter = previewImageFilters.filter;
        this.ci_createSnapshotFromCroppedImageWithFiltersApplied(
          previewImageFilters.filter
        );
        //"filter: brightness(100%) contrast(100%) grayscale(0%) saturate(1) hue-rotate(0deg) invert(100%) sepia(0%);"; //previewImageFilters.filter;
        console.log("++ end cropping image");
      }, 100);
    },
    ci_createSnapshotFromCroppedImageWithFiltersApplied(filters) {
      this.canvasPic = document.createElement("canvas");
      let elementCroppedImg = document.getElementById("theCroppedImage");

      //create canvas for snapshot
      this.canvasPic.id = "canvasPic";
      this.canvasPic.width = elementCroppedImg.naturalWidth;
      this.canvasPic.height = elementCroppedImg.naturalHeight;
      this.canvasPic.style.position = "absolute";
      this.canvasPic.style.top = 0;
      this.canvasPic.style.left = 0;
      this.canvasPic.style.zIndex = 100;
      this.canvasPic.hidden = true;
      let ctx = this.canvasPic.getContext("2d");
      ctx.filter = filters;
      ctx.drawImage(elementCroppedImg, 0, 0, this.canvasPic.width, this.canvasPic.height);
      //let body = document.getElementsByTagName("body")[0];
      //body.appendChild(canvasPic);
      this.croppedImage = "";
      this.renderedImage = this.canvasPic.toDataURL();

      // this.thumbnailTakeImageFilename =
      //   "snapshot_" + Math.floor(Date.now() / 1000) + ".jpg";

      // this.thumbnailTakeFileObj = new Blob([this.thumbnailTakeImageSrc], {
      //   type: "image/jpeg",
      // });

      // this.isUploadTakeThumbReady = true;
      // canvasPic.remove();
    },
    ci_setValueDimensions() {
      this.imageCroppedWidth = Math.round(this.ci_getData().width);
      this.imageCroppedHeight = Math.round(this.ci_getData().height);
      this.$refs.croppedX.value = Math.round(this.ci_getData().x);
      this.$refs.croppedY.value = Math.round(this.ci_getData().y);
      this.$refs.croppedWidth.value = this.imageCroppedWidth;
      this.$refs.croppedHeigth.value = this.imageCroppedHeight;
      this.modelCroppedWidth = this.imageCroppedWidth;
      this.modelCroppedHeight = this.imageCroppedHeight;
    },
    ci_setAspectRatio(event) {
      let value = Number(event.target.value);
      switch (value) {
        case 0: // 16/9
          this.cropperEditor.setAspectRatio(16 / 9);
          this.ci_setValueDimensions();
          console.log("++ ci_setAspectRatio: 16/9");
          break;
        case 1: // 9/16
          this.cropperEditor.setAspectRatio(9 / 16);
          this.ci_setValueDimensions();
          console.log("++ ci_setAspectRatio: 9/16");
          break;
        case 2: // 4/3
          this.cropperEditor.setAspectRatio(4 / 3);
          this.ci_setValueDimensions();
          console.log("++ ci_setAspectRatio: 4/3");
          break;
        case 3: // 3/4
          this.cropperEditor.setAspectRatio(3 / 4);
          this.ci_setValueDimensions();
          console.log("++ ci_setAspectRatio: 3/4");
          break;
        case 4: // 1:1
          this.cropperEditor.setAspectRatio(1 / 1);
          this.ci_setValueDimensions();
          console.log("++ ci_setAspectRatio: 1/1");
          break;
        case 5: // Free
          this.cropperEditor.setAspectRatio(NaN);
          this.ci_setValueDimensions();
          console.log("++ ci_setAspectRatio: Free NaN");
          break;
      }
    },
    ci_ratio_16_9(event) {
      this.aspectRatioModel = Number(event.target.value);
      this.cropperEditor.setAspectRatio(this.aspectRatioModel);
      this.ci_setValueDimensions();
    },
    ci_ratio_9_16(event) {
      document.getElementById(event.target.id).checked = true;
      this.cropperEditor.setAspectRatio(9 / 16);
      this.ci_setValueDimensions();
    },
    ci_ratio_4_3(event) {
      document.getElementById(event.target.id).checked = true;
      this.cropperEditor.setAspectRatio(4 / 3);
      this.ci_setValueDimensions();
    },
    ci_ratio_3_4(event) {
      document.getElementById(event.target.id).checked = true;
      this.cropperEditor.setAspectRatio(3 / 4);
      this.ci_setValueDimensions();
    },
    ci_ratio_1_1(event) {
      document.getElementById(event.target.id).checked = true;
      this.cropperEditor.setAspectRatio(1 / 1);
      this.ci_setValueDimensions();
    },
    ci_ratio_Free(event) {
      document.getElementById(event.target.id).checked = true;
      this.cropperEditor.setAspectRatio(NaN);
      this.ci_setValueDimensions();
    },
    ci_flipX() {
      const dom = this.$refs.ci_flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.cropperEditor.scaleX(scale);
      dom.setAttribute("data-scale", scale);
      this.ci_setValueDimensions();
    },
    ci_flipY() {
      const dom = this.$refs.ci_flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
      this.ci_setValueDimensions();
    },
    ci_getCropBoxData() {
      this.ci_data = JSON.stringify(this.cropperEditor.getCropBoxData(), null, 4);
      return this.ci_data;
    },
    ci_getDimensionData() {
      let data = 0;
      if (this.cropperEditor) {
        data = this.cropperEditor.getData(true);
      }
      return data;
    },
    ci_getData() {
      this.ci_data = this.cropperEditor.getData(false); //JSON.stringify(this.cropperEditor.getData(), null, 4);
      // console.log("++ ci_data", this.ci_data);
      return this.cropperEditor.getData(false);
    },

    ci_move(offsetX, offsetY) {
      this.cropperEditor.move(offsetX, offsetY);
      this.ci_setValueDimensions();
    },
    ci_rotate(deg) {
      this.cropperEditor.rotate(deg);
      this.ci_setValueDimensions();
    },
    ci_setCropBoxData() {
      this.cropperEditor.setCropBoxData(JSON.parse(this.ci_getData()));
    },
    ci_setDataWidth() {
      this.cropperEditor.setData({
        width: this.modelCroppedWidth,
      });
      this.ci_setValueDimensions();
    },
    ci_setDataHeight() {
      this.cropperEditor.setData({
        height: this.modelCroppedHeight,
      });
      this.ci_setValueDimensions();
    },
    ci_setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.cropperEditor.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    ci_zoom(percent) {
      this.cropperEditor.relativeZoom(percent);
      this.ci_setValueDimensions();
    },
    ci_refresh() {
      setTimeout(() => {
        console.log("++ redraw cropper image");
        this.isCropperRedraw = false;
      }, 0);
      setTimeout(() => {
        console.log("++ redraw cropper image");
        this.isCropperRedraw = true;
      }, 0);
    },
    // ci_refresh() {
    //   // let domDiv = document.getElementById("ciContainer");
    //   // let content = domDiv.innerHTML;
    //   // domDiv.innerHTML = content;
    //   var element = document.getElementById("ciContainer");
    //   var n = document.createTextNode(" ");
    //   var disp = element.style.display; // don't worry about previous display style

    //   element.appendChild(n);
    //   element.style.display = "none";

    //   setTimeout(function () {
    //     element.style.display = disp;
    //     n.parentNode.removeChild(n);
    //   }, 1000); // you can play with this timeout to make it as short as possible
    // },
    ci_applyFilter() {
      let imgCropperCanvas = document.querySelector(".cropper-canvas img");
      let imgPreviewImage = document.querySelector(".previewImage img");
      let imgCropperViewBox = document.querySelector(".cropper-view-box img");
      // let image4 = document.getElementById("theCroppedImage");

      let filterControls = document.querySelectorAll(".filter-range");
      var computedFilters = "";
      filterControls.forEach(function (item) {
        computedFilters +=
          item.getAttribute("data-filter") +
          "(" +
          item.value +
          item.getAttribute("data-scale") +
          ") ";
      });
      imgCropperCanvas.style.filter = computedFilters + " url('#filterColorize')";
      imgPreviewImage.style.filter = computedFilters + " url('#filterColorize')";
      imgCropperViewBox.style.filter = computedFilters + " url('#filterColorize')";
    },
    ci_resetFilters() {
      this.filter_Brightness = 100;
      this.filter_Contrast = 100;
      this.filter_Grayscale = 0;
      this.filter_Saturate = 1;
      this.filter_HueRotate = 0;
      this.filter_Invert = 0;
      this.filter_Sepia = 0;
      this.filter_tintRed = 1;
      this.filter_tintGreen = 1;
      this.filter_tintBlue = 1;
      this.filter_tintAddRed = 0;
      this.filter_tintAddGreen = 0;
      this.filter_tintAddBlue = 0;
      let imgCropperCanvas = document.querySelector(".cropper-canvas img");
      let imgPreviewImage = document.querySelector(".previewImage img");
      let imgCropperViewBox = document.querySelector(".cropper-view-box img");
      imgCropperCanvas.style.removeProperty("filter");
      imgPreviewImage.style.removeProperty("filter");
      imgCropperViewBox.style.removeProperty("filter");
    },

    ci_onChangeSetTint() {
      this.ci_applyFilter();
    },
    ci_onChangeSetWidth() {
      if (this.modelCroppedWidth < 1) {
        this.modelCroppedWidth = 1;
      }
      this.ci_setDataWidth();
    },
    ci_onChangeSetHeight() {
      if (this.modelCroppedHeight < 1) {
        this.modelCroppedHeight = 1;
      }
      this.ci_setDataHeight();
    },
    async ci_SaveNewVersion() {
      let imageToSave = document.getElementById("finalImageRendered");
      if (imageToSave) {
        let imageObj = {
          data: imageToSave.src,
          width: imageToSave.naturalWidth,
          height: imageToSave.naturalHeight,
          imageId: this.image.imageId,
        };

        this.showLoader(true);
        let saveImage = await EventService.saveNewResolution(
          imageObj,
          this.$store.getters.getToken.token
        );
        this.showLoader(false);

        if (saveImage.status != 200) {
          console.error("Save new version >> error during saving data");
          this.$root.addSnackBarMsg(
            "SAVE NEW VERSION: An error has occurred, please try again",
            "error"
          );
          return;
        }

        this.$root.addSnackBarMsg("The new image version has been saved", "info");
        //update versions list
        this.$refs.btn_add_new_version.click();
        this.getImageDataFromAPI();
        //reset crop area & filters
        this.$refs.btn_reset_crop_area.click();
        this.$refs.btn_reset_filters.click();
      } else {
        this.$root.addSnackBarMsg("There is no image to save", "warning");
      }
    },
    // ***********************
    // END ** Cropper Image **
    // ***********************

    // **************************
    // START ** Load New Image **
    // **************************
    validImageFileType(file) {
      return this.imageFileTypes.includes(file.type);
    },
    handleFileUpload(event) {
      let tmpFile = event.target.files[0];
      if (!tmpFile) return;
      let tmpFileSize = tmpFile.size;
      if (!this.validImageFileType(tmpFile)) {
        this.$root.addSnackBarMsg(
          "Attention, the file must be of type " + this.imageAccepted,
          "warning"
        );
        return;
      }
      if (tmpFileSize === 0) {
        this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
        return;
      }
      if (tmpFileSize > this.twoGB) {
        this.$root.addSnackBarMsg("Attention, the file exceeds 2 GB", "warning");
        return;
      }

      //conver base64 image
      this.showLoader(true);
      const targetFile = tmpFile;
      const fileReader = new FileReader();
      let rawImg;
      fileReader.onloadend = async () => {
        rawImg = fileReader.result;
        console.log("** rawImg:", rawImg);
        await this.saveNewImageVersion(rawImg);
      };
      fileReader.readAsDataURL(targetFile);
      console.log("** readAsDataURL:", targetFile);
    },
    chooseFile() {
      document.getElementById("newImageUpload").click();
    },
    async saveNewImageVersion(newImage) {
      if (newImage) {
        let imageToSave = document.createElement("img");
        imageToSave.addEventListener("load", async () => {
          console.log("*** QUI ***");
          let imageObj = {
            data: imageToSave.src,
            width: imageToSave.width,
            height: imageToSave.height,
            imageId: this.image.imageId,
          };
          let saveImage = await EventService.saveNewResolution(
            imageObj,
            this.$store.getters.getToken.token
          );
          this.showLoader(false);
          if (saveImage.status != 200) {
            console.error("Save new version >> error during saving data");
            this.$root.addSnackBarMsg(
              "SAVE NEW VERSION: An error has occurred, please try again",
              "error"
            );
            return;
          }
          this.$root.addSnackBarMsg("The new image version has been saved", "info");
          //update versions list
          // this.$refs.btn_add_new_version.click();
          this.getImageDataFromAPI();
          //reset crop area & filters
          // this.$refs.btn_reset_crop_area.click();
          // this.$refs.btn_reset_filters.click();
        });
        imageToSave.src = newImage;
        console.log(imageToSave.src);
      } else {
        this.$root.addSnackBarMsg("There is no image to save", "warning");
      }
    },
    // ************************
    // END ** Load New Image **
    // ************************
    getDeliverableVideoOriginal(array, key) {
      let str = "";
      let index, existsInString;
      let element = array[0];
      for (let i = 0; i < array.length; i++) {
        str = array[i][key];
        index = str.indexOf("_original");
        existsInString = !!~index;
        if (existsInString) {
          element = array[i];
          break;
        }
      }
      return element;
    },
    getImgOriginalSrc() {
      if (this.image.originalImageUri) {
        this.imgOriginalSrc =
          this.image.originalImageUri + "?" + Math.floor(Date.now() / 1000);
      } else {
        this.imgOriginalSrc = require("@/assets/images/image-placeholder.png");
      }
    },
    async getImgSrc() {
      if (this.image.originalImageUri) {
        let imageObj = {
          imageUri: this.image.originalImageUri,
          base64: "",
        };
        //get image base64 as src image
        this.showLoader(true);
        let imageBase64 = await EventService.getImageBase64(
          imageObj,
          this.$store.getters.getToken.token
        );
        this.showLoader(false);

        if (imageBase64.status != 200) {
          console.error("Get image base64 >> error during fetching data");
          this.$root.addSnackBarMsg(
            "GET IMAGE SRC: An error has occurred, please try again",
            "error"
          );
          this.imgSrc = require("@/assets/images/image-placeholder.png");
          return;
        }

        if (imageBase64.data.base64) {
          this.imgSrc = imageBase64.data.base64;
        } else {
          this.imgSrc = require("@/assets/images/image-placeholder.png");
        }
      } else {
        this.imgSrc = require("@/assets/images/image-placeholder.png");
      }
    },
    previewModalImage(event) {
      let modal = document.getElementById("modalImg");
      let img = event.target;
      let modalImg = document.getElementById("imageModal");
      let captionText = document.getElementById("modalImgCaption");

      modal.style.display = "block";
      modalImg.src = img.src;
      captionText.innerHTML = img.alt;

      let span = document.getElementById("modalImgClose");
      span.onclick = () => {
        modal.style.display = "none";
      };
      modalImg.onclick = () => {
        modal.style.display = "none";
      };
      modal.onclick = () => {
        modal.style.display = "none";
      };
      window.onkeydown = (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
          modal.style.display = "none";
        }
      };
    },
    openPopupImage(imageUrl) {
      let modal = document.getElementById("modalImg");
      let modalImg = document.getElementById("imageModal");
      let captionText = document.getElementById("modalImgCaption");

      modal.style.display = "block";
      modalImg.src = imageUrl;
      captionText.innerHTML = imageUrl;

      let span = document.getElementById("modalImgClose");
      span.onclick = () => {
        modal.style.display = "none";
      };
      modalImg.onclick = () => {
        modal.style.display = "none";
      };
      modal.onclick = () => {
        modal.style.display = "none";
      };
      window.onkeydown = (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
          modal.style.display = "none";
        }
      };
    },
    getThumbnail(item) {
      let thumbnail = this.placeholder;
      if (item.sas_url) {
        thumbnail = item.sas_url;
      }
      return thumbnail;
    },
    getFilenameFromUrl(strUrl) {
      let lastElement = "-";
      let splitArr = strUrl.split("/");
      lastElement = splitArr[splitArr.length - 1];
      return lastElement;
    },
    getImageSizeFromTagImg(imgId) {
      let imgsize = "-";
      let img = document.getElementById(imgId);
      console.log(img);
      return imgsize;
    },
    getCustomAspectRatioFromFileName(url) {
      let reduceRatio = " [custom]";
      let type = 1; //0=default resolutions, 1=custom resolution
      let penultimateElement = "";
      let returnObj = { type: 0, reduceRatio: "" };
      let splitDot = url.split(".");
      penultimateElement = splitDot[splitDot.length - 2];
      let splitUnderscore = penultimateElement.split("_");
      if (splitUnderscore[1] && splitUnderscore[2]) {
        type = 0;
        reduceRatio = ` [${splitUnderscore[1]}:${splitUnderscore[2]}]`;
      }
      returnObj = { type: type, reduceRatio: reduceRatio };
      return returnObj;
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.isLoading = false;
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    //init changes saved
    this.initChangesSaved();
    //get image data
    this.getImageDataFromAPI();
  },
  mounted() {},

  beforeRouteLeave(to, from, next) {
    if (this.isChangesSaved.indexOf(false) > 0) {
      this.$swal
        .fire({
          title: "Data unsaved",
          html: "Do you really want to leave? You have unsaved changes!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },
};
</script>

<style src="@/assets/css/multiselect.css"></style>
