<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <!-- Title section -->
            <h1>
              VIDEO
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-edit"></i> </span>Edit
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Linear Loader -->
      <div v-if="isLoading" class="linear-loader">
        <span></span>
      </div>
      <!-- Content -->
      <div v-if="isVisible" class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Title -->
        <div class="row m-0 padding-x-4px">
          <div class="col-md-12 p-0 mt-4">
            <h4 class="color-mainblue m-0 p-0">
              {{ videoMetadataDetails_en.metaDataDetails.title }}
            </h4>
          </div>
          <hr class="sepGray" />
        </div>
        <!-- Top Tabs -->
        <div class="row m-0 padding-x-4px">
          <ul class="nav nav-tabs video-tabs">
            <li class="nav-item">
              <a
                href="#overview"
                class="nav-link active d-inline-flex"
                data-bs-toggle="tab"
              >
                OVERVIEW
                <span v-if="!isChangesSaved[1]" class="ms-2 change-data"></span>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#thumbnails"
                @click.prevent="pauseVideo"
                class="nav-link d-inline-flex"
                data-bs-toggle="tab"
              >
                THUMBNAILS
                <span v-if="!isChangesSaved[2]" class="ms-2 change-data"></span>
              </a>
            </li>
            <li v-if="videoDeliverables.length > 0" class="nav-item">
              <a
                href="#encoding"
                @click.prevent="pauseVideo"
                class="nav-link d-inline-flex"
                data-bs-toggle="tab"
              >
                ENCODING
                <span v-if="!isChangesSaved[3]" class="ms-2 change-data"></span>
              </a>
            </li>
            <li v-if="isTabVisible" class="nav-item">
              <a
                href="#audio"
                @click.prevent="pauseVideo"
                class="nav-link d-inline-flex"
                data-bs-toggle="tab"
              >
                AUDIO &amp; SUBTITLES
                <span v-if="!isChangesSaved[4]" class="ms-2 change-data"></span>
              </a>
            </li>
            <li v-if="isTabVisible" class="nav-item">
              <a
                href="#attachments"
                @click.prevent="pauseVideo"
                class="nav-link d-inline-flex"
                data-bs-toggle="tab"
              >
                ATTACHMENTS
                <span v-if="!isChangesSaved[5]" class="ms-2 change-data"></span>
              </a>
            </li>
            <li v-if="isTabVisible" class="nav-item">
              <a
                href="#marker"
                @click.prevent="pauseVideo"
                class="nav-link d-inline-flex"
                data-bs-toggle="tab"
              >
                MARKER
                <span v-if="!isChangesSaved[6]" class="ms-2 change-data"></span>
              </a>
            </li>
            <!-- Copy GUID -->
            <div class="align-items-center col d-flex flex-row justify-content-end w-100">
              <div class="align-items-center d-flex flex-row justify-content-end row">
                <div>
                  <div class="form-label d-inline-flex me-2">GUID</div>
                  <p class="content-form word-break-all box-code d-inline-flex me-2">
                    {{ video.guid }}
                  </p>
                  <button
                    @click="copyTextToClipboard(video.guid, 'GUID')"
                    class="icon-button float-end"
                    alt="COPY GUID"
                    title="COPY GUID"
                  >
                    <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </ul>
          <div class="tab-content p-0">
            <!-- Overview -->
            <div class="tab-pane fade show active" id="overview">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Overview -->
                <div class="row my-3">
                  <div class="accordion accordion-console" id="accordion-overview">
                    <!-- Accordion Preview -->
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-1"
                          aria-expanded="true"
                          aria-controls="panel-collapse-1"
                        >
                          GENERAL INFO
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-1"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panel-heading-1"
                      >
                        <div class="accordion-body">
                          <!-- Preview & Chart Views -->
                          <div class="row my-3">
                            <!-- Preview -->
                            <div class="col-md-4 mb-3">
                              <div class="form-label mb-1">VIDEO</div>
                              <div class="border-mainblue bg-black">
                                <!-- Video Player Plyr GenaralInfo -->
                                <plyr-player
                                  ref="videoPlayer_GeneralInfo"
                                  :videoObj="videoProperties"
                                ></plyr-player>
                              </div>
                              <!-- Button Generate Metadata -->
                              <div class="container-fluid p-0 mt-3">
                                <button
                                  @click="pauseVideo(), generateEmbedCode()"
                                  type="button"
                                  class="btn button-outline-1 small"
                                >
                                  <span>
                                    <i class="zmdi zmdi-code-setting"></i>Generate Embed
                                    Code
                                  </span>
                                </button>
                              </div>
                            </div>

                            <!-- Info -->
                            <div class="col-md-8 mb-3">
                              <div class="row my-3">
                                <!-- Created -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">CREATED</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form">
                                      {{
                                        mainVideoDataConverted.creationDateUnixTimeStamp
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Modified -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">MODIFIED</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form">
                                      {{
                                        mainVideoDataConverted.modificationDateUnixTimeStamp
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Duration -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">DURATION (hh:mm:ss)</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form">
                                      {{ formatDuration(video.duration) }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Views -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">VIEWS</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form">
                                      {{ mainVideoDataConverted.views }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Author -->
                                <div class="row my-2">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">AUTHOR</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    <p class="content-form word-break-all">
                                      {{ videoMetadataDetails_en.metaDataDetails.author }}
                                    </p>
                                  </div>
                                </div>
                                <!-- Contributin User -->
                                <div class="align-items-center d-flex flex-row my-2 row">
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label">CONTRIBUTING USER</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-row"
                                  >
                                    {{ video.userName }}
                                  </div>
                                </div>
                                <hr class="sepGray" />

                                <!-- Shareable URL -->
                                <div
                                  class="align-items-center d-flex justify-content-center my-2 pe-0 row"
                                >
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label my-2">SHAREABLE URL</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-column justify-content-center px-0"
                                  >
                                    <div class="container p-0 m-0">
                                      <div
                                        class="container d-flex flex-column justify-content-center px-0"
                                      >
                                        <div
                                          class="align-items-center d-flex flex-row justify-content-between row"
                                        >
                                          <div class="col-sm-11">
                                            <p
                                              class="content-form word-break-all small box-code"
                                              style="min-height: 34px"
                                            >
                                              {{ shareableUrl }}
                                            </p>
                                          </div>
                                          <div class="col-sm-1">
                                            <button
                                              @click="
                                                copyTextToClipboard(
                                                  shareableUrl,
                                                  'SHAREABLE URL'
                                                )
                                              "
                                              class="icon-button ms-2 float-end"
                                              alt="COPY SHAREABLE URL"
                                              title="COPY SHAREABLE URL"
                                            >
                                              <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- Movie Versions -->
                                <div
                                  v-if="videoDeliverables.length > 0"
                                  class="align-items-center d-flex justify-content-center my-2 pe-0 row"
                                >
                                  <div class="col-lg-4 pe-0">
                                    <div class="form-label my-2">MOVIE VERSIONS</div>
                                  </div>
                                  <div
                                    class="align-items-center col-lg-8 d-flex flex-column justify-content-center px-0"
                                  >
                                    <!-- Select Video Version -->
                                    <div class="container p-0 mb-2">
                                      <div class="inner-addon right-addon">
                                        <i
                                          class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                                        ></i>
                                        <select
                                          class="form-select form-control"
                                          required
                                          v-model="videoSelectDirectUrl"
                                        >
                                          <option disabled value>
                                            Movie Versions...
                                          </option>
                                          <option
                                            v-for="(
                                              directUrl, index
                                            ) in getMovieVersions()"
                                            :key="index"
                                            :value="directUrl.value"
                                          >
                                            {{ directUrl.description }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <!-- Video Direct Url -->
                                    <div class="container p-0 m-0">
                                      <div
                                        class="container d-flex flex-column justify-content-center px-0"
                                      >
                                        <div
                                          class="align-items-center d-flex flex-row justify-content-between row"
                                        >
                                          <div class="col-sm-11">
                                            <p
                                              class="content-form word-break-all small box-code"
                                              style="min-height: 34px"
                                            >
                                              {{ videoSelectDirectUrl }}
                                            </p>
                                          </div>
                                          <div class="col-sm-1">
                                            <button
                                              @click="
                                                copyTextToClipboard(
                                                  videoSelectDirectUrl,
                                                  'URL'
                                                )
                                              "
                                              class="icon-button float-end"
                                              alt="COPY"
                                              title="COPY"
                                            >
                                              <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Accordion Metadata -->
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-4">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-4"
                          aria-expanded="true"
                          aria-controls="panel-collapse-4"
                        >
                          METADATA
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-4"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panel-heading-4"
                      >
                        <div class="accordion-body">
                          <!-- Metadata Content -->
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="row mb-2">
                                <!-- Language EN RU Tabs -->
                                <div class="row m-0 padding-x-4px p-2">
                                  <ul class="nav nav-tabs language-tabs">
                                    <li class="nav-item">
                                      <a
                                        href="#lang_en"
                                        class="nav-link active"
                                        data-bs-toggle="tab"
                                        >EN</a
                                      >
                                    </li>
                                    <li v-if="is_RU_LangAvailable" class="nav-item">
                                      <a
                                        href="#lang_ru"
                                        class="nav-link"
                                        data-bs-toggle="tab"
                                        >RU</a
                                      >
                                    </li>
                                  </ul>
                                  <div class="tab-content p-0">
                                    <!-- Language EN -->
                                    <div class="tab-pane fade show active" id="lang_en">
                                      <div class="container-fluid m-0 p-0">
                                        <!-- Content Language EN -->
                                        <div class="container p-0">
                                          <!-- Title -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">TITLE *</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  videoMetadataDetails_en.metaDataDetails
                                                    .title
                                                "
                                                placeholder="Title"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                                @input="onTitleVideoENChange($event)"
                                              />
                                            </div>
                                          </div>
                                          <!-- Author -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">AUTHOR</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  videoMetadataDetails_en.metaDataDetails
                                                    .author
                                                "
                                                placeholder="Author"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Abstract -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">ABSTRACT</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    videoMetadataDetails_en
                                                      .metaDataDetails.s_abstract
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Eyelet -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">EYELET</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  videoMetadataDetails_en.metaDataDetails
                                                    .eyelet
                                                "
                                                placeholder="Eyelet"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Description -->
                                          <div class="align-items-top d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">
                                                DESCRIPTION
                                              </div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    videoMetadataDetails_en
                                                      .metaDataDetails.description
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Language RU -->
                                    <div class="tab-pane fade" id="lang_ru">
                                      <div class="container-fluid m-0 p-0">
                                        <!-- Content Language RU -->
                                        <div class="container p-0">
                                          <!-- Title -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">TITLE</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  videoMetadataDetails_ru.metaDataDetails
                                                    .title
                                                "
                                                placeholder="Title"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Author -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">AUTHOR</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  videoMetadataDetails_ru.metaDataDetails
                                                    .author
                                                "
                                                placeholder="Author"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Abstract -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">ABSTRACT</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    videoMetadataDetails_ru
                                                      .metaDataDetails.s_abstract
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Eyelet -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">EYELET</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  videoMetadataDetails_ru.metaDataDetails
                                                    .eyelet
                                                "
                                                placeholder="Eyelet"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Description -->
                                          <div class="align-items-top d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">
                                                DESCRIPTION
                                              </div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    videoMetadataDetails_ru
                                                      .metaDataDetails.description
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="row mb-2">
                                <!-- Language IT Tabs -->
                                <div class="row m-0 padding-x-4px p-2">
                                  <ul class="nav nav-tabs language-tabs">
                                    <li class="nav-item">
                                      <a
                                        href="#lang_it"
                                        class="nav-link active"
                                        data-bs-toggle="tab"
                                        >IT</a
                                      >
                                    </li>
                                  </ul>
                                  <div class="tab-content p-0">
                                    <!-- Language IT -->
                                    <div class="tab-pane fade show active" id="lang_it">
                                      <div class="container-fluid m-0 p-0">
                                        <!-- Content Language IT -->
                                        <div class="container p-0">
                                          <!-- Title -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">TITLE</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                placeholder="Title"
                                                v-model="
                                                  videoMetadataDetails_it.metaDataDetails
                                                    .title
                                                "
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Author -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">AUTHOR</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                placeholder="Author"
                                                v-model="
                                                  videoMetadataDetails_it.metaDataDetails
                                                    .author
                                                "
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Abstract -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">ABSTRACT</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    videoMetadataDetails_it
                                                      .metaDataDetails.s_abstract
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Eyelet -->
                                          <div class="align-items-center d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">EYELET</div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <input
                                                type="text"
                                                class="form-control"
                                                spellcheck="false"
                                                v-model="
                                                  videoMetadataDetails_it.metaDataDetails
                                                    .eyelet
                                                "
                                                placeholder="Eyelet"
                                                @change="isChangesSaved[1] = false"
                                                @keydown="isChangesSaved[1] = false"
                                              />
                                            </div>
                                          </div>
                                          <!-- Description -->
                                          <div class="align-items-top d-flex my-2 row">
                                            <div class="col-lg-3 pe-0">
                                              <div class="form-label my-2">
                                                DESCRIPTION
                                              </div>
                                            </div>
                                            <div
                                              class="align-items-center col-lg-9 d-flex flex-row"
                                            >
                                              <!-- CKEditor Rich Text -->
                                              <div class="container m-0 p-0">
                                                <ckeditor
                                                  class="w-100"
                                                  :editor="editor"
                                                  v-model="
                                                    videoMetadataDetails_it
                                                      .metaDataDetails.description
                                                  "
                                                  :config="editorConfig"
                                                  @ready="onReady"
                                                  @keydown="isChangesSaved[1] = false"
                                                ></ckeditor>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Digital Edition -->
                          <!-- <div class="row p-2">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="check-digital-edition"
                                @change="isChangesSaved[1] = false"
                                v-model="video.digitalEdition"
                              />
                              <label class="content-form" for="check-digital-edition"
                                >Digital Edition</label
                              >
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- Accordion labels -->
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-5">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-5"
                          aria-expanded="true"
                          aria-controls="panel-collapse-5"
                        >
                          LABELS
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-5"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panel-heading-5"
                      >
                        <!-- Labels Content -->
                        <div class="accordion-body">
                          <!-- Areas Content -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="align-items-center col-lg-12 d-flex flex-row">
                              <Multiselect
                                id="areas"
                                placeholder="Chose an area..."
                                noResultsText="No result"
                                v-model="tagsAreasValue"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="tagsAreas"
                                @change="isChangesSaved[1] = false"
                              />
                            </div>
                          </div>
                          <!-- Categories Content -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="align-items-center col-lg-12 d-flex flex-row">
                              <Multiselect
                                id="categories"
                                placeholder="Chose a category..."
                                noResultsText="No result"
                                v-model="tagsCategoriesValue"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="tagsCategories"
                                @change="isChangesSaved[1] = false"
                              />
                            </div>
                          </div>
                          <!-- Tags Content -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="align-items-center col-lg-12 d-flex flex-row">
                              <Multiselect
                                id="tags"
                                placeholder="Chose a tag..."
                                noResultsText="No result"
                                v-model="tagsTagsValue"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="tagsTags"
                                @change="isChangesSaved[1] = false"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Thumbnails -->
            <div class="tab-pane fade" id="thumbnails">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Thumbnails -->
                <div class="row my-3 padding-x-4px">
                  <!-- Preview -->
                  <div v-if="!isPreviewThumbnails" class="col-xl-6 mb-3">
                    <div class="form-label mb-1">PREVIEW</div>
                    <div ref="videoPreviewContainer" class="border-mainblue bg-black">
                      <!-- Video Player Plyr Preview -->
                      <plyr-player
                        ref="videoPlayer_Preview"
                        :videoObj="videoProperties"
                      ></plyr-player>
                    </div>
                    <!-- Buttons Edit Thumbnails -->
                    <div class="container-fluid p-0 mt-3">
                      <button
                        @click="createSnapshotFromVideo"
                        type="button"
                        class="btn button-outline-1 small"
                      >
                        <span> <i class="zmdi zmdi-image"></i>Take Thumbnail </span>
                      </button>
                    </div>
                    <!-- Upload Take Thumbnails -->
                    <div
                      v-if="isUploadTakeThumbReady"
                      id="thumb_list"
                      class="row m-0 p-0 mt-4"
                    >
                      <table class="table-bordered table-responsive-md table-setup">
                        <thead class="table-header-font">
                          <tr class="td-center">
                            <th width="180">THUMBNAIL</th>
                            <th>FILE NAME</th>
                            <th width="80">ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody class="table-body-font td-vertical-center">
                          <tr>
                            <!-- Thumbnail -->
                            <td class="td-center">
                              <img
                                id="imgTakeThumb"
                                ref="imgTakeThumb"
                                :src="thumbnailTakeImageSrc"
                                width="160"
                              />
                            </td>
                            <!-- File Name -->
                            <td>{{ thumbnailTakeImageFilename }}</td>
                            <!-- Actions -->
                            <td class="td-actions td-center">
                              <button
                                @click="removeUploadTakeThumbFromList"
                                alt="DELETE"
                                title="DELETE"
                              >
                                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                              </button>
                              <button
                                @click="uploadTakeThumbnailFile"
                                alt="UPLOAD"
                                title="UPLOAD"
                              >
                                <i class="fa-solid fa-file-arrow-up"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- Thumbnails -->
                  <div class="col-xl-6 mb-3">
                    <div class="form-label mb-1">THUMBNAILS</div>
                    <div class="grid-container mb-4">
                      <!-- Thumbnail -->
                      <div
                        class="thumbnail border-mainblue"
                        v-for="(thumbnail, index) in videoThumbnails"
                        :key="index"
                        :id="'thumb_' + index"
                      >
                        <!-- Image -->
                        <div @click="setThumbnail(thumbnail, index)">
                          <img class="img-fluid" :src="thumbnail.uri" />
                        </div>
                        <!-- Middle nutton check -->
                        <div
                          v-if="thumbnail.isDefault != true"
                          @click="setThumbnail(thumbnail, index)"
                          class="middle"
                        >
                          <div class="icon set">
                            <i class="zmdi zmdi-check"></i>
                          </div>
                        </div>
                        <!-- Button Delete -->
                        <div
                          v-if="thumbnail.isDefault != true"
                          @click="deleteThumbnail(thumbnail, index)"
                          class="thumb-btn-top-right"
                        >
                          <button class="thumb-btn delete" type="button">
                            <i class="zmdi zmdi-delete"></i>
                          </button>
                        </div>
                        <!-- Button Set -->
                        <div
                          v-if="thumbnail.isDefault == true"
                          class="thumb-btn-top-right"
                        >
                          <button class="thumb-btn set" type="button">
                            <i class="zmdi zmdi-check"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Browse Thumbnails button -->
                    <div class="form-group">
                      <div class="input-group">
                        <span class="input-group-text px-3">
                          <i class="zmdi zmdi-image"></i>
                        </span>

                        <input
                          id="thumbnailFile"
                          ref="thumbnailFile"
                          type="file"
                          accept="image/*"
                          v-on:change="handleThumbnailUpload($event)"
                          hidden
                        />
                        <input
                          type="text"
                          class="form-control form-browse"
                          placeholder="Chose image..."
                          v-model="thumbnailModelFile"
                          disabled
                        />
                        <button
                          class="btn button-outline-1 small px-4"
                          type="button"
                          :disabled="isUploadThumbReady"
                          @click.prevent="$refs.thumbnailFile.click()"
                        >
                          <i class="fa-solid fa-folder-open"></i>Browse Images
                        </button>
                      </div>
                    </div>
                    <!-- Upload Thumbnails List -->
                    <div
                      v-if="isUploadThumbReady"
                      id="thumb_list"
                      class="row m-0 padding-x-4px mt-4"
                    >
                      <table class="table-bordered table-responsive-md table-setup">
                        <thead class="table-header-font">
                          <tr class="td-center">
                            <th width="180">THUMBNAIL</th>
                            <th>FILE NAME</th>
                            <th width="80">ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody class="table-body-font td-vertical-center">
                          <tr>
                            <!-- Thumbnail -->
                            <td class="td-center">
                              <img :src="thumbnailImageSrc" width="160" />
                            </td>
                            <!-- File Name -->
                            <td>{{ thumbnailFileObj.name }}</td>
                            <!-- Actions -->
                            <td class="td-actions td-center">
                              <button
                                @click="removeUploadThumbFromList"
                                alt="DELETE"
                                title="DELETE"
                              >
                                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                              </button>
                              <button
                                @click="uploadThumbnailFile"
                                alt="UPLOAD"
                                title="UPLOAD"
                              >
                                <i class="fa-solid fa-file-arrow-up"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Encoding -->
            <div class="tab-pane fade" id="encoding">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Encoding -->
                <div class="row my-3 m-0 padding-x-4px">
                  <table class="table-bordered table-responsive-md table-setup">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>FILE NAME</th>
                        <!-- <th>
                          DURATION
                          <br />hh:mm:ss
                        </th>-->
                        <th>FILE SIZE</th>
                        <th>RESOLUTION</th>
                        <th width="80">LANGUAGE</th>
                        <th width="80">AUDIO ONLY</th>
                        <th width="80">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody class="table-body-font td-vertical-center">
                      <tr
                        v-for="(item, index) in videoDeliverables"
                        :key="index"
                        :id="'item_' + index"
                      >
                        <!-- Name -->
                        <td>{{ item.fileName }}</td>
                        <!-- Duration -->
                        <!-- <td class="td-center">{{ formatDuration(item.duration) }}</td> -->
                        <!-- Size -->
                        <td class="td-center">{{ formatBytes(item.size) }}</td>
                        <!-- Resolution -->
                        <td class="td-center">
                          {{ getResolution(item.width, item.height) }}
                        </td>
                        <!-- Language -->
                        <td class="td-center">
                          <ul class="list-tags">
                            <li>
                              <span
                                v-if="item.audioLanguage != null"
                                class="badge-colored gray2"
                                title="en"
                                alt="en"
                                >{{ item.audioLanguage }}</span
                              >
                            </li>
                          </ul>
                        </td>
                        <!-- Audio Only -->
                        <td class="td-center">
                          <ul class="list-tags">
                            <li>
                              <span
                                class="badge-colored"
                                :class="item.isAudio ? 'green' : 'red'"
                                title="no"
                                alt="no"
                                >{{ item.isAudio ? "yes" : "no" }}</span
                              >
                            </li>
                          </ul>
                        </td>
                        <!-- Actions -->
                        <td class="td-actions td-center">
                          <a :href="item.sas_url" target="_blank" download>
                            <button alt="DOWNLOAD" title="DOWNLOAD">
                              <i class="zmdi zmdi-download zmdi-hc-lg"></i>
                            </button>
                          </a>
                          <button
                            @click="copyTextToClipboard(item.sas_url, 'URL')"
                            alt="COPY"
                            title="COPY"
                          >
                            <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Audio & Subtitles -->
            <div v-if="isTabVisible" class="tab-pane fade" id="audio">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Audio & Subtitles -->
                <div class="row my-3">
                  <div class="accordion accordion-console" id="accordion-audio">
                    <!-- Encoding STT in progress -->
                    <div v-if="isEncodingSTTInProgress" class="accordion-item">
                      <img
                        :src="require('@/assets/images/ico-speech-cyan.svg')"
                        class="d-block my-2 img-fluid"
                        width="200"
                      />
                      <h5 class="ms-3 me-2 d-inline-flex">
                        Encoding speech to text in progress
                      </h5>
                      <div
                        class="spinner-border text-primary"
                        role="status"
                        data-pg-collapsed
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <!-- Accordion Subtitles -->
                    <div v-if="!isEncodingSTTInProgress" class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-audio-1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-audio-1"
                          aria-expanded="true"
                          aria-controls="panel-collapse-audio-1"
                        >
                          SUBTITLES
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-audio-1"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panel-heading-audio-1"
                      >
                        <div class="accordion-body">
                          <!-- Subtitles Content -->
                          <div class="my-2 row padding-x-4px">
                            <!-- Buttons add subtitle and to translate file -->
                            <div class="container-fluid p-0 mb-2">
                              <button
                                ref="btn_add_subtit"
                                type="button"
                                class="btn button-outline-1 small float-end"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse_subtitle"
                                aria-expanded="false"
                                aria-controls="collapse_subtitle"
                                @click.prevent="checkNoMoreSubtitlesLanguage()"
                              >
                                <span> <i class="zmdi zmdi-plus"></i>Add Subtitle</span>
                              </button>
                            </div>
                            <!-- Browse Subtitle file to add & Browse Subtitle file to add to translate -->
                            <div v-if="isNoAudioLanguage" class="my-3 input-group">
                              <h6 class="text-warning">
                                No more VTT files can be added because all audio languages
                                have been used
                              </h6>
                            </div>
                            <div v-if="isNoSubtitLanguage" class="my-3 input-group">
                              <h6 class="text-warning">
                                No more VTT files can be added because all translate
                                languages have been used
                              </h6>
                            </div>
                            <div
                              v-if="!isNoAudioLanguage && !isNoSubtitLanguage"
                              class="collapse p-0"
                              id="collapse_subtitle"
                            >
                              <!-- Tabs Add Subtitles -->
                              <ul class="nav nav-tabs video-tabs mt-3">
                                <li class="nav-item">
                                  <a
                                    href="#addNewSubtitles"
                                    class="nav-link active d-inline-flex"
                                    data-bs-toggle="tab"
                                  >
                                    ADD NEW SUBTITLE
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a
                                    href="#translateSubtitles"
                                    class="nav-link d-inline-flex"
                                    data-bs-toggle="tab"
                                  >
                                    TRANSLATE AN EXISTING SUBTITLE
                                  </a>
                                </li>
                              </ul>

                              <!-- Subtitles Tab Container -->
                              <div class="tab-content p-0 my-4">
                                <!-- Container Add New Subtitle -->
                                <div
                                  class="tab-pane fade show active"
                                  id="addNewSubtitles"
                                >
                                  <div class="container-fluid m-0 p-0">
                                    <!-- Browse Subtitle file to add -->
                                    <div class="container form-label mb-2">
                                      ADD SUBTITLE
                                    </div>
                                    <div
                                      v-if="!isUploadTranslateSubtitReady"
                                      class="form-group p-0"
                                    >
                                      <div
                                        v-if="!isNoAudioLanguage && !isNoSubtitLanguage"
                                        class="my-3 input-group"
                                      >
                                        <span class="input-group-text px-3">
                                          <i class="zmdi zmdi-file"></i>
                                        </span>
                                        <input
                                          id="subtitleFile"
                                          ref="subtitleFile"
                                          type="file"
                                          accept=".vtt"
                                          v-on:change="handleSubtitleUpload($event)"
                                          hidden
                                        />
                                        <input
                                          type="text"
                                          class="form-control form-browse"
                                          placeholder="Chose subtitle file to add..."
                                          v-model="subtitleModelFile"
                                          disabled
                                        />
                                        <button
                                          class="btn button-outline-1 small px-4"
                                          type="button"
                                          :disabled="isUploadSubtitReady"
                                          @click.prevent="$refs.subtitleFile.click()"
                                        >
                                          <i class="fa-solid fa-folder-open"></i>Browse
                                          Files
                                        </button>
                                      </div>
                                    </div>
                                    <!-- Upload Subtitle List -->
                                    <div
                                      v-if="isUploadSubtitReady"
                                      id="subtit_list"
                                      class="row m-0 p-0 mt-2"
                                    >
                                      <table
                                        class="table-bordered table-responsive-md table-setup"
                                      >
                                        <thead class="table-header-font">
                                          <tr class="td-center">
                                            <th>FILE NAME</th>
                                            <th width="260">LANGUAGE</th>
                                            <th width="80">ACTIONS</th>
                                          </tr>
                                        </thead>
                                        <tbody class="table-body-font td-vertical-center">
                                          <tr>
                                            <!-- Name -->
                                            <td>{{ subtitleFileObj.name }}</td>
                                            <!-- Language -->
                                            <td class="td-center">
                                              <div class="inner-addon right-addon w-100">
                                                <i
                                                  class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                                                ></i>
                                                <select
                                                  class="form-select form-control"
                                                  required
                                                  v-model="modelSubtitleLanguage"
                                                >
                                                  <option disabled value>
                                                    Language...
                                                  </option>
                                                  <option
                                                    v-for="(
                                                      language, index
                                                    ) in getSubtitleLanguagesFromSubtitlesLanguage()"
                                                    :key="index"
                                                    :value="language.isoCode"
                                                  >
                                                    {{ language.isoCode }} -
                                                    {{ language.language }}
                                                  </option>
                                                </select>
                                              </div>
                                            </td>
                                            <!-- Actions -->
                                            <td class="td-actions td-center">
                                              <button
                                                @click="removeUploadSubtitFromList"
                                                alt="DELETE"
                                                title="DELETE"
                                              >
                                                <i
                                                  class="zmdi zmdi-delete zmdi-hc-lg"
                                                ></i>
                                              </button>
                                              <button
                                                @click="uploadNewSubtitleFile"
                                                alt="UPLOAD"
                                                title="UPLOAD"
                                                :disabled="modelSubtitleLanguage == ''"
                                              >
                                                <i class="fa-solid fa-file-arrow-up"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <!-- Container Translate Subtitle -->
                                <div class="tab-pane fade" id="translateSubtitles">
                                  <div class="container-fluid m-0 p-0">
                                    <div id="subtit_list" class="row m-0 p-0 mt-2">
                                      <table
                                        class="table-bordered table-responsive-md table-setup"
                                      >
                                        <thead class="table-header-font">
                                          <tr class="td-center">
                                            <th width="45%">SOURCE LANGUAGE</th>
                                            <th width="45%">DESTINATION LANGUAGE</th>
                                            <th width="80">ACTIONS</th>
                                          </tr>
                                        </thead>
                                        <tbody class="table-body-font td-vertical-center">
                                          <tr>
                                            <!-- Language -->
                                            <td class="td-center">
                                              <div class="inner-addon right-addon w-100">
                                                <i
                                                  class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                                                ></i>
                                                <select
                                                  class="form-select form-control"
                                                  required
                                                  v-model="
                                                    modelInputTranslateSubtitleLanguage
                                                  "
                                                >
                                                  <option disabled value>
                                                    Language...
                                                  </option>
                                                  <option
                                                    v-for="(
                                                      language, index
                                                    ) in getActiveLanguagesFromSubtitlesLanguage()"
                                                    :key="index"
                                                    :value="language.isoCode"
                                                  >
                                                    {{ language.isoCode }} -
                                                    {{ language.language }}
                                                  </option>
                                                </select>
                                              </div>
                                            </td>
                                            <!-- Translate -->
                                            <td class="td-center">
                                              <div class="inner-addon right-addon w-100">
                                                <i
                                                  class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                                                ></i>
                                                <select
                                                  class="form-select form-control"
                                                  required
                                                  v-model="
                                                    modelOutpuptTranslateSubtitleLanguage
                                                  "
                                                >
                                                  <option disabled value>
                                                    Language to translate...
                                                  </option>
                                                  <option
                                                    v-for="(
                                                      languageTrans, index
                                                    ) in getSubtitleLanguagesToTranslate"
                                                    :key="index"
                                                    :value="languageTrans.isoCode"
                                                  >
                                                    {{ languageTrans.isoCode }} -
                                                    {{ languageTrans.language }}
                                                  </option>
                                                </select>
                                              </div>
                                            </td>
                                            <!-- Actions -->
                                            <td class="td-actions td-center">
                                              <button
                                                @click="uploadNewSubtitleFileToTranslate"
                                                alt="TRANSLATE"
                                                title="TRANSLATE"
                                                :disabled="
                                                  modelInputTranslateSubtitleLanguage ==
                                                    '' ||
                                                  modelOutpuptTranslateSubtitleLanguage ==
                                                    '' ||
                                                  modelInputTranslateSubtitleLanguage ==
                                                    modelOutpuptTranslateSubtitleLanguage
                                                "
                                              >
                                                <i class="zmdi zmdi-translate"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <hr class="sepGray" />
                            <!-- No files subtitle -->
                            <div v-if="!videoSubtitles.length > 0">
                              This list is empty, please add subtitle
                            </div>
                            <!-- List File Subtitles -->
                            <div class="row my-3 m-0 padding-x-4px">
                              <table
                                v-if="videoSubtitles.length > 0"
                                class="table-bordered table-responsive-md table-setup"
                              >
                                <thead class="table-header-font">
                                  <tr class="td-center">
                                    <th>LABEL</th>
                                    <th width="160">LANGUAGE</th>
                                    <th width="200">ACTIONS</th>
                                  </tr>
                                </thead>
                                <tbody class="table-body-font td-vertical-center">
                                  <tr
                                    v-for="(subtitle, index) in videoSubtitles"
                                    :key="index"
                                  >
                                    <!-- Label -->
                                    <td>
                                      {{ getLanguageLabel(subtitle.lang) }}
                                    </td>
                                    <!-- Language -->
                                    <td class="td-center">
                                      <ul class="list-tags">
                                        <li>
                                          <span
                                            class="badge-colored gray2"
                                            :title="subtitle.lang"
                                            :alt="subtitle.lang"
                                            >{{ subtitle.lang }}</span
                                          >
                                        </li>
                                      </ul>
                                    </td>
                                    <!-- Actions -->
                                    <td class="td-actions td-center">
                                      <button
                                        v-if="isEditLanguageVisible"
                                        @click="
                                          pauseVideo(), editAudioTrack(subtitle, index)
                                        "
                                        alt="CHANGE LANGUAGE"
                                        title="CHANGE LANGUAGE"
                                      >
                                        <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                                      </button>
                                      <button
                                        @click="
                                          pauseVideo(), manageAudioTrack(subtitle, index)
                                        "
                                        alt="EDIT SUBTITLE"
                                        title="EDIT SUBTITLE"
                                      >
                                        <i class="zmdi zmdi-settings zmdi-hc-lg"></i>
                                      </button>
                                      <a :href="subtitle.uri" target="_blank" download>
                                        <button alt="DOWNLOAD" title="DOWNLOAD">
                                          <i class="zmdi zmdi-download zmdi-hc-lg"></i>
                                        </button>
                                      </a>

                                      <button
                                        @click="pauseVideo(), deleteSubtitle(index)"
                                        alt="DELETE"
                                        title="DELETE"
                                      >
                                        <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Accordion Audio -->
                    <div v-if="!isEncodingSTTInProgress" class="accordion-item">
                      <h2 class="accordion-header" id="panel-heading-audio-2">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panel-collapse-audio-2"
                          aria-expanded="true"
                          aria-controls="panel-collapse-audio-2"
                        >
                          AUDIO
                        </button>
                      </h2>
                      <div
                        id="panel-collapse-audio-2"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panel-heading-audio-2"
                      >
                        <div class="accordion-body">
                          <!-- Audio Content -->
                          <div class="my-2 row padding-x-4px">
                            <!-- Add file audio -->
                            <div class="container-fluid p-0 mb-2">
                              <button
                                ref="btn_add_audio"
                                type="button"
                                class="btn button-outline-1 small float-end"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse_audio"
                                aria-expanded="false"
                                aria-controls="collapse_audio"
                              >
                                <span> <i class="zmdi zmdi-plus"></i>Add Audio </span>
                              </button>
                            </div>
                            <!-- Browse Audio file button -->
                            <div class="collapse p-0" id="collapse_audio">
                              <div class="form-group p-0">
                                <div class="my-3 input-group">
                                  <span class="input-group-text px-3">
                                    <i class="zmdi zmdi-file"></i>
                                  </span>
                                  <input
                                    id="audioFile"
                                    ref="audioFile"
                                    type="file"
                                    accept="audio/*"
                                    v-on:change="handleAudioUpload($event)"
                                    hidden
                                  />
                                  <input
                                    type="text"
                                    class="form-control form-browse"
                                    placeholder="Chose file..."
                                    v-model="audioModelFile"
                                    disabled
                                  />
                                  <button
                                    class="btn button-outline-1 small px-4"
                                    type="button"
                                    :disabled="isUploadAudioReady"
                                    @click.prevent="$refs.audioFile.click()"
                                  >
                                    <i class="fa-solid fa-folder-open"></i>Browse Files
                                  </button>
                                </div>
                              </div>
                              <!-- Upload Audio List -->
                              <div
                                v-if="isUploadAudioReady"
                                id="audio_list"
                                class="row m-0 p-0 mt-2"
                              >
                                <table
                                  class="table-bordered table-responsive-md table-setup"
                                >
                                  <thead class="table-header-font">
                                    <tr class="td-center">
                                      <th>FILE NAME</th>
                                      <th v-if="!isProcessRunning" width="260">
                                        LANGUAGE
                                      </th>
                                      <th v-if="isProcessRunning" width="220">
                                        PROGRESS
                                      </th>
                                      <th width="110">STATUS</th>
                                      <th v-if="!isProcessRunning" width="80">ACTIONS</th>
                                    </tr>
                                  </thead>
                                  <tbody class="table-body-font td-vertical-center">
                                    <tr>
                                      <!-- Name -->
                                      <td>{{ audioFileObj.name }}</td>
                                      <!-- Language -->
                                      <td v-if="!isProcessRunning" class="td-center">
                                        <div class="inner-addon right-addon w-100">
                                          <i
                                            class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                                          ></i>
                                          <select
                                            class="form-select form-control"
                                            required
                                            v-model="modelAudioLanguage"
                                          >
                                            <option disabled value>Language...</option>
                                            <option
                                              v-for="(
                                                language, index
                                              ) in getAudioLanguagesNotUsed()"
                                              :key="index"
                                              :value="language.isoCode"
                                            >
                                              {{ language.isoCode }} -
                                              {{ language.language }}
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                      <!-- Progress -->
                                      <td v-if="isProcessRunning" class="td-center">
                                        <div
                                          class="progress my-1"
                                          style="background-color: #adadad"
                                        >
                                          <div
                                            id="progress_upload"
                                            class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                            role="progressbar1"
                                            aria-valuenow="0"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            :style="'width:' + uploadPercentage + '%'"
                                          >
                                            {{ uploadPercentage }}%
                                          </div>
                                        </div>
                                      </td>
                                      <!-- Status -->
                                      <td class="td-center">
                                        {{ upload_status[statusIndex].label }}
                                      </td>
                                      <!-- Actions -->
                                      <td
                                        v-if="!isProcessRunning"
                                        class="td-actions td-center"
                                      >
                                        <button
                                          v-if="!isProcessRunning"
                                          @click="removeUploadAudioFromList"
                                          alt="DELETE"
                                          title="DELETE"
                                        >
                                          <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                                        </button>
                                        <button
                                          v-if="!isProcessRunning"
                                          @click="submitAudioFile"
                                          alt="UPLOAD"
                                          title="UPLOAD"
                                          :disabled="modelAudioLanguage == ''"
                                        >
                                          <i class="fa-solid fa-file-arrow-up"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <hr class="sepGray" />
                            <!-- No files audio -->
                            <div v-if="!videoDeliverables_audio.length > 0">
                              This list is empty, please add audio
                            </div>
                            <!-- List File Audio -->
                            <div class="row my-3 m-0 padding-x-4px">
                              <table
                                v-if="videoDeliverables_audio.length > 0"
                                class="table-bordered table-responsive-md table-setup"
                              >
                                <thead class="table-header-font">
                                  <tr class="td-center">
                                    <th>LABEL</th>
                                    <th width="160">LANGUAGE</th>
                                    <th width="160">AUDIO BITRATE</th>
                                    <th width="360">PREVIEW</th>
                                    <th width="120">ACTIONS</th>
                                  </tr>
                                </thead>
                                <tbody class="table-body-font td-vertical-center">
                                  <tr
                                    v-for="(audio, index) in videoDeliverables_audio"
                                    :key="index"
                                  >
                                    <!-- Label -->
                                    <td>
                                      {{ getLanguageLabel(audio.audioLanguage) }}
                                    </td>
                                    <!-- Language -->
                                    <td class="td-center">
                                      <ul class="list-tags">
                                        <li>
                                          <span
                                            class="badge-colored gray2"
                                            :title="audio.audioLanguage"
                                            :alt="audio.audioLanguage"
                                            >{{ audio.audioLanguage }}</span
                                          >
                                        </li>
                                      </ul>
                                    </td>
                                    <!-- Label -->
                                    <td class="td-center">{{ audio.bitrate }} Kbps</td>
                                    <!-- Preview Audio-->
                                    <td class="td-center">
                                      <div class="border-mainblue">
                                        <vue-plyr
                                          class="audioPlayer"
                                          style="background-color: black !important"
                                        >
                                          <audio controls crossorigin playsinline>
                                            <source
                                              :src="audio.sas_url"
                                              type="audio/mp3"
                                            />
                                          </audio>
                                        </vue-plyr>
                                      </div>
                                    </td>
                                    <!-- Actions -->
                                    <td class="td-actions td-center">
                                      <button
                                        v-if="isEditLanguageVisible"
                                        @click="pauseVideo(), editAudio(audio, index)"
                                        alt="CHANGE LANGUAGE"
                                        title="CHANGE LANGUAGE"
                                      >
                                        <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                                      </button>
                                      <button
                                        @click="pauseVideo(), deleteAudio(index)"
                                        alt="DELETE"
                                        title="DELETE"
                                      >
                                        <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                                      </button>
                                      <button
                                        v-if="isSpeechtotextVisible(audio.audioLanguage)"
                                        @click="
                                          pauseVideo(),
                                            speechToText(index, audio.audioLanguage)
                                        "
                                        alt="SPEECH TO TEXT"
                                        title="SPEECH TO TEXT"
                                      >
                                        <!-- <i class="fa-solid fa-head-side-cough"></i> -->
                                        <i class="zmdi zmdi-comment-text zmdi-hc-lg"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Attachments -->
            <div v-if="isTabVisible" class="tab-pane fade" id="attachments">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Attachments -->
                <div class="row my-3 m-0 padding-x-4px">
                  <!-- Add file attachment -->
                  <div class="container-fluid p-0 mb-2">
                    <button
                      ref="btn_add_attach"
                      type="button"
                      class="btn button-outline-1 small float-end"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_addattachment"
                      aria-expanded="false"
                      aria-controls="collapse_addattachment"
                    >
                      <span> <i class="zmdi zmdi-plus"></i>Add File </span>
                    </button>
                  </div>

                  <!-- Browse Attachment file button -->
                  <div class="collapse p-0" id="collapse_addattachment">
                    <div class="form-group p-0">
                      <div class="my-3 input-group">
                        <span class="input-group-text px-3">
                          <i class="zmdi zmdi-file"></i>
                        </span>
                        <input
                          id="attachmentFile"
                          ref="attachmentFile"
                          type="file"
                          accept="application/*, video/*, image/*, audio/*"
                          v-on:change="handleAttachmentUpload($event)"
                          hidden
                        />
                        <input
                          type="text"
                          class="form-control form-browse"
                          placeholder="Chose file..."
                          v-model="attachmentModelFile"
                          disabled
                        />
                        <button
                          class="btn button-outline-1 small px-4"
                          type="button"
                          :disabled="isUploadAttachReady"
                          @click.prevent="$refs.attachmentFile.click()"
                        >
                          <i class="fa-solid fa-folder-open"></i>Browse Files
                        </button>
                      </div>
                    </div>
                    <!-- Upload Attachment List -->
                    <div
                      v-if="isUploadAttachReady"
                      id="attach_list"
                      class="row m-0 p-0 mt-2"
                    >
                      <table class="table-bordered table-responsive-md table-setup">
                        <thead class="table-header-font">
                          <tr class="td-center">
                            <th>FILE NAME</th>
                            <th width="80">ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody class="table-body-font td-vertical-center">
                          <tr>
                            <!-- Name -->
                            <td>{{ attachmentFileObj.name }}</td>
                            <!-- Actions -->
                            <td class="td-actions td-center">
                              <button
                                @click="removeUploadAttachFromList"
                                alt="DELETE"
                                title="DELETE"
                              >
                                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                              </button>
                              <button
                                @click="uploadAttachmentFile"
                                alt="UPLOAD"
                                title="UPLOAD"
                              >
                                <i class="fa-solid fa-file-arrow-up"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr class="sepGray" />
                  <!-- No files attachments -->
                  <div v-if="!videoAttachments.length > 0">
                    This list is empty, please add file
                  </div>
                  <!-- List File Attachments -->
                  <table
                    v-if="videoAttachments.length > 0"
                    class="table-bordered table-responsive-md table-setup mt-2"
                  >
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>FILE NAME</th>
                        <th width="90">PUBLISHED</th>
                        <th width="160">
                          CREATED
                          <br />MODIFIED
                        </th>
                        <th width="80">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody class="table-body-font td-vertical-center">
                      <tr v-for="(attachment, index) in videoAttachments" :key="index">
                        <!-- Name -->
                        <td>{{ attachment.name }}</td>
                        <!-- Published -->
                        <td class="td-center">
                          <button
                            :title="
                              attachment.isPublished
                                ? 'UNPUBLISH ATTACHMENT'
                                : 'PUBLISH ATTACHMENT'
                            "
                            :alt="
                              attachment.isPublished
                                ? 'UNPUBLISH ATTACHMENT'
                                : 'PUBLISH ATTACHMENT'
                            "
                            type="button"
                            class="toggle-btn"
                            :class="attachment.isPublished ? 'active' : ''"
                            @click="
                              attachment.isPublished
                                ? ((attachment.isPublished = false),
                                  (isChangesSaved[5] = false))
                                : ((attachment.isPublished = true),
                                  (isChangesSaved[5] = false))
                            "
                          >
                            <i class="zmdi zmdi-check"></i>
                          </button>
                        </td>
                        <!-- Created on -->
                        <td class="td-center">
                          <ul class="unstyled">
                            <li>
                              {{ convertUnixDate(attachment.creationDateUnixTimeStamp) }}
                            </li>
                            <li>
                              {{
                                convertUnixDate(attachment.modificationDateUnixTimeStamp)
                              }}
                            </li>
                          </ul>
                        </td>
                        <!-- Actions -->
                        <td class="td-actions td-center">
                          <a :href="attachment.uri" target="_blank">
                            <button alt="VIEW" title="VIEW">
                              <i class="zmdi zmdi-eye zmdi-hc-lg"></i>
                            </button>
                          </a>
                          <button
                            @click="deleteAttachment(index)"
                            alt="DELETE"
                            title="DELETE"
                          >
                            <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Marker -->
            <div v-if="isTabVisible" class="tab-pane fade" id="marker">
              <div class="container-fluid m-0 p-0">
                <!-- Accordion Marker -->
                <div class="row my-3 m-0 padding-x-4px">
                  <!-- Add marker -->
                  <div class="container-fluid m-0 p-0 mb-2">
                    <button
                      @click.prevent="pauseVideo"
                      @click="addMarker"
                      ref="btn_add_marker"
                      type="button"
                      class="btn button-outline-1 small float-end"
                    >
                      <span> <i class="zmdi zmdi-plus"></i>Add Marker </span>
                    </button>
                  </div>
                  <hr class="sepGray" />
                  <!-- Video -->
                  <div class="col-md-6 p-0 pe-2 mb-3">
                    <div class="form-label mb-1">VIDEO</div>
                    <div class="border-mainblue bg-black">
                      <!-- Video Player Plyr -->
                      <plyr-player
                        ref="videoPlayer_Marker"
                        :videoObj="videoProperties"
                      ></plyr-player>
                    </div>
                  </div>
                  <!-- Markers -->
                  <div class="col-md-6 p-0 ps-2 mb-3">
                    <div class="form-label mb-1">
                      MARKERS ({{ videoMarkers.length }} elements)
                    </div>
                    <div v-if="!videoMarkers.length > 0">
                      This list is empty, please add marker
                    </div>
                    <div id="marker-scroll-container" class="row markers-container">
                      <div class="marker-area">
                        <div
                          v-for="(marker, index) in videoMarkers"
                          :key="index"
                          @click.prevent
                        >
                          <div :id="'index_' + index" class="marker-item mb-3">
                            <div class="mt-1 mb-2 d-flex">
                              <p class="marker-index">{{ index + 1 }}</p>
                              <span class="marker-title">{{
                                videoMarkerMetadataDetails_en[marker.id].metaDataDetails
                                  .title
                              }}</span>
                            </div>
                            <div class="row">
                              <div
                                class="align-items-center col-sm-3 d-flex flex-column justify-content-center small"
                              >
                                <div class="form-label mb-1">Seek To</div>
                                <div class="text-white">
                                  {{ marker.seekTimeSpan }}
                                </div>
                              </div>
                              <div
                                class="align-items-center col-sm-3 d-flex flex-column justify-content-center small"
                              >
                                <div class="form-label mb-1">Start Time</div>
                                <div class="text-white">
                                  {{ marker.initTimeSpan }}
                                </div>
                              </div>
                              <div
                                class="align-items-center col-sm-3 d-flex flex-column justify-content-center small"
                              >
                                <div class="form-label mb-1">End Time</div>
                                <div class="text-white">
                                  {{ marker.endTimeSpan }}
                                </div>
                              </div>
                              <div
                                class="align-items-center col-sm-3 d-flex flex-column justify-content-center small"
                              >
                                <div class="form-label mb-1">Published</div>
                                <ul class="list-tags">
                                  <li>
                                    <span
                                      class="badge-colored"
                                      :class="marker.published ? 'green' : 'red'"
                                      :title="marker.published ? 'yes' : 'no'"
                                      :alt="marker.published ? 'yes' : 'no'"
                                      >{{ marker.published ? "yes" : "no" }}</span
                                    >
                                  </li>
                                </ul>
                              </div>
                              <div class="col-sm-12 mb-2 small">
                                <div class="form-label mt-2 mb-1">Description</div>
                                <div
                                  class="marker-description"
                                  :id="'desc_html_render_' + index"
                                >
                                  {{
                                    renderHTML(
                                      "desc_html_render_",
                                      index,
                                      videoMarkerMetadataDetails_en[marker.id]
                                        .metaDataDetails.description
                                    )
                                  }}
                                </div>
                              </div>
                              <hr class="sepGray" />
                              <div class="container-fluid">
                                <div class="float-end">
                                  <button
                                    @click="
                                      pauseVideo(),
                                        $refs.videoPlayer_Marker.gotoMarker(
                                          convertTimeStringInSec(marker.initTimeSpan)
                                        )
                                    "
                                    class="icon-button"
                                    alt="VIEW"
                                    title="VIEW"
                                  >
                                    <i class="zmdi zmdi-eye zmdi-hc-lg"></i>
                                  </button>
                                  <button
                                    @click="pauseVideo(), editMarker(marker, index)"
                                    class="icon-button"
                                    alt="EDIT"
                                    title="EDIT"
                                  >
                                    <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                                  </button>
                                  <button
                                    @click="pauseVideo(), deleteMarker(marker, index)"
                                    class="icon-button"
                                    alt="DELETE"
                                    title="DELETE"
                                  >
                                    <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="sepGray" />
          <!-- Buttons Back, Cancel, Save video -->
          <div class="container-fluid p-0 my-3">
            <!-- back to list -->
            <button
              v-if="!notChangesSaved"
              @click="backToList()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-arrow-left"></i>Back to List</span>
            </button>
            <!-- Cancel -->
            <button
              v-if="notChangesSaved"
              @click="cancelImgae()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>Cancel</span>
            </button>
            <!-- Save Video Obj -->
            <button
              @click="updateVideo()"
              type="button"
              class="btn button-outline-1 float-end"
              :class="notChangesSaved ? 'notSaved' : ''"
            >
              <span> <i class="zmdi zmdi-floppy"></i>Save Video </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Dialogs -->
  <manage-audio-tracks
    dialogTitle="Edit Subtitle"
    :dialogWidth="'1200px'"
    :dataElement="dataManageAudioTrack"
    @closeDialog="closeDialogManageAudioTrack"
    :openDialog="isOpenDialogManageAudioTrack"
    @confirmData="changeDataAudioTrack"
  ></manage-audio-tracks>
  <manage-markers
    dialogTitle="Edit Marker"
    :dialogWidth="'1200px'"
    :dataMarker="dataManageMarkers"
    @closeDialog="closeDialogManageMarker"
    :openDialog="isOpenDialogManageMarker"
    @confirmData="changeDataMarker"
  >
  </manage-markers>
  <add-marker
    dialogTitle="Add Marker"
    :dialogWidth="'1200px'"
    :dataMarker="dataManageMarkers"
    @closeDialog="closeDialogAddMarker"
    :openDialog="isOpenDialogAddMarker"
    @confirmData="addDataMarker"
  ></add-marker>
  <generate-embed-code
    dialogTitle="Generate Embed Code"
    :dialogWidth="'1200px'"
    @closeDialog="closeDialogGenerateCode"
    :openDialog="isOpenDialogGenerateCode"
    :subtitlesLang="getSubtitlesLang()"
    :videoGUI="getVideoGUI()"
    :movieVersions="getMovieVersions(true)"
  >
  </generate-embed-code>
</template>

<script>
import "video.js/dist/video-js.css";
import CommonMethods from "@/services/CommonMethods";
import EventService from "@/services/EventService.js";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import languageISOCode from "@/data/iso_language_code.json";
import ManageAudioTracks from "@/components/dialogs/ManageAudioTrack.vue";
import ManageMarkers from "@/components/dialogs/ManageMarkers.vue";
import AddMarker from "@/components/dialogs/AddMarker.vue";
// import EditLanguageAudioTrack from "@/components/dialogs/EditLanguageAudioTrack.vue";
import GenerateEmbedCode from "@/components/dialogs/GenerateEmbedCode.vue";
import PlyrPlayer from "@/components/core/PlyrPlayer.vue";
// import RichTextEditor from "@/components/ui/RichTextEditor.vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  components: {
    Multiselect,
    ManageAudioTracks,
    ManageMarkers,
    AddMarker,
    // EditLanguageAudioTrack,
    GenerateEmbedCode,
    PlyrPlayer,
    // RichTextEditor,
  },
  data() {
    return {
      isTabVisible: false,
      shareableUrl: null,
      isNoAudioLanguage: false,
      isNoSubtitLanguage: false,
      jobIdLocalStorage: "",
      intervalEncodingSTTInProgress: null,
      isEncodingSTTInProgress: false,
      isEditLanguageVisible: false,
      is_RU_LangAvailable: false,
      // editor: ClassicEditor,
      editor: DecoupledEditor,
      editorConfig: {
        language: "it",
        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "underline",
            "link",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "indent",
            "outdent",
          ],
          shouldNotGroupWhenFull: false,
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,
          // ],
        },
      },
      testHTML:
        "<p data-pg-id='96838'><font color='#0000ff'>Lorem</font> <b data-pg-id='96839'>ipsum</b></p>",
      titolo: "",
      statusIndex: 0,
      upload_status: [
        { label: "Ready" },
        { label: "Upload in progress" },
        { label: "Encoding in progress" },
        { label: "Aborted" },
        { label: "Error" },
      ],
      requestUploadAudioFile: null,
      BASEURL: this.$appGlobalVariables.BASEURL_UPLOAD,
      file: null,
      tempNewImg: null,
      plyrPlayer_thumb: null,
      isOpenDialogManageAudioTrack: false,
      isOpenDialogManageMarker: false,
      isOpenDialogAddMarker: false,
      dataManageAudioTrack: {},
      dataManageMarkers: {},
      dataManageAudio: {},
      isOpenDialogLanguageTrack: false,
      isOpenDialogLanguageAudio: false,
      isOpenDialogGenerateCode: false,
      languageISOCode,
      spinnerLoader: null,
      attributes_categories: [],
      tagsCategories: [],
      tagsCategoriesValue: [],
      attributes_tags: [],
      tagsTags: [],
      tagsTagsValue: [],
      attributes_areas: [],
      tagsAreas: [],
      tagsAreasValue: [],
      isChangesSaved: [],
      isLoading: true,
      isVisible: false,
      isPreviewThumbnails: false,
      isUploadThumbReady: false,
      isUploadTakeThumbReady: false,
      isUploadAttachReady: false,
      isUploadSubtitReady: false,
      isUploadTranslateSubtitReady: false,
      isSelectLanguageTranslateReady: false,
      isUploadAudioReady: false,
      isProcessRunning: false,
      isUploadCompleted: false,
      uploadPercentage: 0,
      thumbnailFileObj: {},
      thumbnailModelFile: "",
      thumbnailImageSrc: "",
      thumbnailTakeImageSrc: "",
      thumbnailTakeImageFilename: "",
      thumbnailTakeFileObj: {},
      attachmentFileObj: {},
      attachmentModelFile: "",
      attachmentFileSrc: "",
      subtitleFileObj: {},
      subtitleModelFile: "",
      subtitleFileSrc: "",
      modelSubtitleLanguage: "",
      modelInputTranslateSubtitleLanguage: "",
      modelOutpuptTranslateSubtitleLanguage: "",
      audioFileObj: {},
      audioModelFile: "",
      audioFileSrc: "",
      modelAudioLanguage: "",
      imageFileTypes: ["image/jpeg", "image/png", "image/webp"],
      attachmentFileTypes: [
        "application/pdf",
        "application/doc",
        "application/txt",
        "image/bmp",
        "image/jpeg",
        "image/png",
        "image/webp",
        "video/mp4",
        "video/quicktime",
        "video/avi",
        "video/webm",
        "audio/mp3",
        "audio/mpeg",
        "audio/wav",
        "audio/ogg",
      ],
      subtitleFileTypes: ["text/vtt", "vtt"],
      audioFileTypes: [
        "audio/mp3",
        "audio/mpeg",
        "audio/mp4",
        "video/mp4",
        "audio/wav",
        "audio/ogg",
      ],
      videoId: "",
      awaitVideo: [],
      video: [],
      videoProperties: {
        videoSrc: "",
        poster: "",
        width: 0,
        height: 0,
      },
      videoDeliverables: [],
      videoDeliverables_audio: [],
      videoDeliverables_video: [],
      videoThumbnails: [],
      videoDirectUrl: [],
      videoAttachments: [],
      videoSubtitles: [],
      videoMetadata: [],
      videoMarkers: [],
      videoMarkersMetadataDetails: [],
      videoMetadataDetails_it: {
        lang: "it",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },
      videoMetadataDetails_en: {
        lang: "en",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },
      videoMetadataDetails_ru: {
        lang: "ru",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },

      videoMarkerMetadataDetails_it: [],
      videoMarkerMetadataDetails_en: [],
      videoMarkerMetadataDetails_ru: [],
      videoSelectDirectUrl: "",
      videoFullResolutionChart: [
        {
          description: "4320p (8K) - 7680 x 4320",
          pixelSize: [{ width: 7680, height: 4320 }],
        },
        {
          description: "2160p (4K) - 3840 x 2160",
          pixelSize: [{ width: 3840, height: 2160 }],
        },
        {
          description: "1440p (2K) - 2560 x 1440",
          pixelSize: [{ width: 2560, height: 1440 }],
        },
        {
          description: "1080p (FHD) - 1920 x 1080",
          pixelSize: [{ width: 1920, height: 1080 }],
        },
        {
          description: "720p (HD) - 1280 x 720",
          pixelSize: [{ width: 1280, height: 720 }],
        },
        {
          description: "480p (SD) - 852 x 480",
          pixelSize: [{ width: 852, height: 480 }],
        },
        {
          description: "240p - 640 x 360",
          pixelSize: [{ width: 640, height: 360 }],
        },
        {
          description: "360p - 428 x 240",
          pixelSize: [{ width: 428, height: 240 }],
        },
      ],
      videoResolutionChart: [
        {
          description: "4320p (8K) - 7680 x 4320",
          pixelSize: { width: 7680, height: 4320 },
        },
        {
          description: "2160p (4K) - 3840 x 2160",
          pixelSize: { width: 3840, height: 2160 },
        },
        {
          description: "1440p (2K) - 2560 x 1440",
          pixelSize: { width: 2560, height: 1440 },
        },
        {
          description: "1080p (FHD) - 1920 x 1080",
          pixelSize: { width: 1920, height: 1080 },
        },
        {
          description: "720p (HD) - 1280 x 720",
          pixelSize: { width: 1280, height: 720 },
        },
        {
          description: "480p (SD) - 852 x 480",
          pixelSize: { width: 852, height: 480 },
        },
      ],
      mainVideoDataConverted: {},
      na: "",
    };
  },
  computed: {
    getPreviewVideoOptions() {
      return this.setPreviewVideoOptions();
    },
    notChangesSaved() {
      if (
        !this.isChangesSaved[1] ||
        !this.isChangesSaved[2] ||
        !this.isChangesSaved[3] ||
        !this.isChangesSaved[4] ||
        !this.isChangesSaved[5] ||
        !this.isChangesSaved[6]
      ) {
        return true;
      }
      return false;
    },
    getSubtitleLanguagesToTranslate() {
      let returnSubtitlesLangISO = JSON.parse(JSON.stringify(this.languageISOCode));
      let subtitleLanguages = JSON.parse(JSON.stringify(this.videoSubtitles));
      for (let k = 0; k < subtitleLanguages.length; k++) {
        for (let i = 0; i < returnSubtitlesLangISO.length; i++) {
          if (returnSubtitlesLangISO[i].isoCode == subtitleLanguages[k].lang) {
            //delete element from array
            returnSubtitlesLangISO.splice(i, 1);
            break;
          }
        }
      }
      // for (let i = 0; i < this.languageISOCode.length; i++) {
      //   if (this.languageISOCode[i].isoCode == this.modelInputTranslateSubtitleLanguage) {
      //     //delete element from array
      //     returnSubtitlesLangISO.splice(i, 1);
      //     break;
      //   }
      // }
      return returnSubtitlesLangISO;
    },
  },
  methods: {
    backToList() {
      this.$router.push({ name: "view-videos-all" });
    },
    cancelImgae() {
      this.$router.push({ name: "view-videos-all" });
    },
    getSharableUrl() {
      if (process.env.VUE_APP_EMBED_CODE_HOST1) {
        this.shareableUrl = process.env.VUE_APP_EMBED_CODE_HOST1 + "/" + this.video.guid;
      }
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    // *****************************
    // START ** Rich Text Updates **
    // *****************************
    updateDescription_it(htmlValue) {
      this.isChangesSaved[1] = false;
      this.videoMetadataDetails_it.metaDataDetails.description = htmlValue;
    },
    updateDescription_en(htmlValue) {
      this.isChangesSaved[1] = false;
      this.videoMetadataDetails_en.metaDataDetails.description = htmlValue;
    },
    updateDescription_ru(htmlValue) {
      this.isChangesSaved[1] = false;
      this.videoMetadataDetails_ru.metaDataDetails.description = htmlValue;
    },
    // *****************************
    // END ** Rich Text Updates **
    // *****************************

    initChangesSaved() {
      this.isChangesSaved[1] = true;
      this.isChangesSaved[2] = true;
      this.isChangesSaved[3] = true;
      this.isChangesSaved[4] = true;
      this.isChangesSaved[5] = true;
      this.isChangesSaved[6] = true;
    },
    renderHTML(partId, index, richText) {
      let element = document.getElementById(partId + index);
      if (element != null) {
        element.innerHTML = richText;
      }
    },

    // **************************
    // START ** Manage Dialogs **
    // **************************
    closeDialogManageAudioTrack() {
      this.isOpenDialogManageAudioTrack = false;
    },
    closeDialogLanguageTrack() {
      this.isOpenDialogLanguageTrack = false;
    },
    closeDialogLanguageAudio() {
      this.isOpenDialogLanguageAudio = false;
    },
    closeDialogManageMarker() {
      this.isOpenDialogManageMarker = false;
    },
    closeDialogAddMarker() {
      this.isOpenDialogAddMarker = false;
    },
    manageAudioTrack(subtitle, index) {
      let thumb = "";
      let thumbFind = "";
      thumbFind = this.videoThumbnails.find((el) => el.isDefault === true);
      if (thumbFind != undefined) thumb = thumbFind.uri;
      this.dataManageAudioTrack = {
        subtitle: subtitle,
        index: index,
        video_poster: thumb,
        // video_src: this.videoDeliverables_video[0].sas_url,
        video_src: this.getDeliverableVideoOriginal(
          this.videoDeliverables_video,
          "sas_url"
        ).sas_url,
      };
      this.isOpenDialogManageAudioTrack = true;
    },
    editAudioTrack(subtitle, index) {
      this.dataManageAudioTrack = {
        subtitle: subtitle,
        index: index,
        languageCode: this.getSubtitleLanguagesNotUsed(subtitle.lang),
      };
      this.isOpenDialogLanguageTrack = true;
    },
    async changeLanguageAudioTrack(data) {
      this.videoSubtitles.splice(data.index, 1);
      this.videoSubtitles.push(data.subtitle);
      this.videoSubtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1));
      //update element videoDeliverables_video array
      for (let i = 0; i < this.videoDeliverables_video.length; i++) {
        if (this.videoDeliverables_video[i].audioLanguage == data.oldLang) {
          this.videoDeliverables_video[i].audioLanguage = data.subtitle.lang;
        }
      }
      //update videoDeliverables array
      this.videoDeliverables = [];
      for (let i = 0; i < this.videoDeliverables_audio.length; i++) {
        this.videoDeliverables.push(this.videoDeliverables_audio[i]);
      }
      for (let i = 0; i < this.videoDeliverables_video.length; i++) {
        this.videoDeliverables.push(this.videoDeliverables_video[i]);
      }

      this.isChangesSaved[4] = false;
      this.$root.addSnackBarMsg("Language changed", "info");
      this.closeDialogLanguageTrack();
      //update video obj
      await this.doUpdateVideo();
    },
    editAudio(audio, index) {
      console.warn(audio);
      this.dataManageAudio = {
        subtitle: { lang: audio.audioLanguage, uri: audio.sas_url },
        index: index,
        languageCode: this.getAudioLanguagesNotUsed(audio.audioLanguage),
      };
      this.isOpenDialogLanguageAudio = true;
    },
    async changeLanguageAudio(data) {
      this.videoDeliverables_audio[data.index].audioLanguage = data.subtitle.lang;
      this.videoDeliverables_audio.sort((a, b) =>
        a.audioLanguage > b.audioLanguage ? 1 : -1
      );
      //update audioTracks array
      this.video.audioTracks = [];
      for (let i = 0; i < this.videoDeliverables_audio.length; i++) {
        this.video.audioTracks.push(this.videoDeliverables_audio[i].audioLanguage);
      }
      this.isChangesSaved[4] = false;
      this.$root.addSnackBarMsg("Language changed", "info");
      this.closeDialogLanguageAudio();
      //update video obj
      await this.doUpdateVideo();
    },
    changeDataAudioTrack(data) {
      this.isChangesSaved[4] = false;
      this.closeDialogManageAudioTrack();
      this.updateSubtitleFile(data);
    },

    editMarker(markerData, indexItem) {
      let thumb = "";
      let thumbFind = "";
      thumbFind = this.videoThumbnails.find((el) => el.isDefault === true);
      if (thumbFind != undefined) thumb = thumbFind.uri;
      this.dataManageMarkers = {
        index: indexItem,
        marker: markerData,
        video_poster: thumb,
        // video_src: this.videoDeliverables_video[0].sas_url,
        video_src: this.getDeliverableVideoOriginal(
          this.videoDeliverables_video,
          "sas_url"
        ).sas_url,
        video_duration: this.video.duration,
      };
      this.pauseVideo();

      this.isOpenDialogManageMarker = true;
    },
    changeDataMarker(data) {
      this.closeDialogManageMarker();
      console.log("** change marker", data);
      this.setMarker(data);
    },
    addDataMarker(data) {
      this.closeDialogAddMarker();
      console.log("** change marker", data);
      this.saveAddMarker(data);
    },
    addMarker() {
      let thumb = "";
      let thumbFind = "";
      thumbFind = this.videoThumbnails.find((el) => el.isDefault === true);
      if (thumbFind != undefined) thumb = thumbFind.uri;

      this.dataManageMarkers = {
        marker: {
          seekTimeSpan: "00:00:00",
          initTimeSpan: "00:00:00",
          endTimeSpan: "00:00:00",
        },
        video_poster: thumb,
        // video_src: this.videoDeliverables_video[0].sas_url,
        video_src: this.getDeliverableVideoOriginal(
          this.videoDeliverables_video,
          "sas_url"
        ).sas_url,
        video_duration: this.video.duration,
      };
      this.isOpenDialogAddMarker = true;
    },
    closeDialogGenerateCode() {
      this.isOpenDialogGenerateCode = false;
    },
    generateEmbedCode() {
      if (!this.notChangesSaved) {
        this.isOpenDialogGenerateCode = true;
      } else {
        this.$root.addSnackBarMsg(
          "There are unsaved changes, you need to save video first",
          "warning"
        );
      }
    },
    getAudioLang() {
      let arr = [];
      if (Object(this.video.audioTracks).length > 0) {
        arr = this.video.audioTracks;
      }
      return arr;
    },
    getSubtitlesLang() {
      let arr = [];
      if (Object(this.video.subtitles).length > 0) {
        for (let i = 0; i < this.video.subtitles.length; i++) {
          arr.push(this.video.subtitles[i].lang);
        }
      }
      return arr;
    },
    getVideoGUI() {
      return this.video.guid;
    },
    // ************************
    // END ** Manage Dialogs **
    // ************************

    // **************************
    // START ** Manage Markers **
    // **************************
    getAllMarkersMetadataDetails() {
      if (!this.videoMarkers.length > 0) return;
      this.videoMarkersMetadataDetails = [];
      //get markers metadata array
      let arrMarkerMetaElement = [];
      let markerElement = [];
      let markerMetaElement = [];
      for (let i = 0; i < this.videoMarkers.length; i++) {
        markerElement = this.videoMarkers[i];
        markerMetaElement = markerElement.metaDataDetails;
        arrMarkerMetaElement[i] = {
          id: markerElement.id,
          metaDataElements: markerMetaElement,
        };
      }

      this.videoMarkersMetadataDetails = arrMarkerMetaElement;
      console.log(
        "+++ this.videoMarkersMetadataDetails",
        this.videoMarkersMetadataDetails
      );
      this.changeMarkerMetadataIt();
    },
    changeMarkerMetadataIt() {
      let vMark = this.videoMarkers;
      let id, element;
      for (let i = 0; i < vMark.length; i++) {
        id = vMark[i].id;
        element = this.getMarkerMetadataDetails(id, "en");
        this.videoMarkerMetadataDetails_en[id] = element;
      }
    },
    getMarkerMetadataDetails(idToSearch, langToSearch) {
      let elementToFind, metadataFinded;
      let objToReturn = {
        lang: langToSearch,
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };
      const arrMetadata = this.videoMarkersMetadataDetails;
      elementToFind = arrMetadata.find((el) => el.id === idToSearch);
      if (elementToFind) {
        metadataFinded = elementToFind.metaDataElements.find(
          (el) => el.lang === langToSearch
        );
        if (metadataFinded) {
          objToReturn = metadataFinded;
        }
      }
      return objToReturn;
    },
    convertTimeStringInSec(timeString) {
      let column = timeString.split(":");
      let seconds = +column[0] * 60 * 60 + +column[1] * 60 + +column[2];
      return seconds;
    },
    async setMarker(data) {
      this.isChangesSaved[6] = false;
      this.$root.addSnackBarMsg("Marker updated", "info");
      this.videoMarkers.splice(data.index, 1);
      this.videoMarkers.push(data.marker);
      this.getAllMarkersMetadataDetails();

      //update video obj
      await this.doUpdateVideo();
    },
    // async setMarker(data) {
    //   this.isChangesSaved[6] = false;
    //   // Start upload marker
    //   this.showLoader(true);
    //   const apiResponseObj = await EventService.setMarker(
    //     data.marker,
    //     this.video.videoId,
    //     this.$store.getters.getToken.token
    //   );
    //   this.showLoader(false);

    //   if (apiResponseObj.status != 200) {
    //     console.error(
    //       "Set marker >> error during set marker",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "EDIT MARKER: Error during updating item",
    //       "error"
    //     );
    //     return;
    //   }

    //   this.$root.addSnackBarMsg("Marker updated", "info");
    //   this.videoMarkers.splice(data.index, 1);
    //   this.videoMarkers.push(data.marker);
    //   this.getAllMarkersMetadataDetails();

    //   //update video obj
    //   await this.doUpdateVideo();
    // },
    async saveAddMarker(data) {
      this.isChangesSaved[6] = false;

      // Start upload marker
      let id = CommonMethods.generateElementUID();
      data.marker.id = id;
      data.marker.objectID = this.video.videoId;
      this.$root.addSnackBarMsg("Marker added", "info");
      this.videoMarkers.push(data.marker);
      this.getAllMarkersMetadataDetails();

      //update video obj
      await this.doUpdateVideo();
    },
    // async saveAddMarker(data) {
    //   this.isChangesSaved[6] = false;
    //   // Start upload marker
    //   this.showLoader(true);
    //   const apiResponseObj = await EventService.setMarker(
    //     data.marker,
    //     this.video.videoId,
    //     this.$store.getters.getToken.token
    //   );
    //   this.showLoader(false);

    //   if (apiResponseObj.status != 200) {
    //     console.error(
    //       "Set marker >> error during set marker",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "EDIT MARKER: Error during updating item",
    //       "error"
    //     );
    //     return;
    //   }

    //   this.$root.addSnackBarMsg("Marker updated", "info");
    //   this.videoMarkers.push(data.marker);
    //   this.getAllMarkersMetadataDetails();

    //   //update video obj
    //   await this.doUpdateVideo();
    // },
    deleteMarker(marker, index) {
      console.log("++ delete marker:", marker, index);
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this marker?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            //call code to delete
            this.videoMarkers.splice(index, 1);
            this.$root.addSnackBarMsg("Marker deleted", "info");
            this.callDeleteMarker();
          }
        });
    },
    async callDeleteMarker() {
      //update video obj
      await this.doUpdateVideo();
    },
    // ************************
    // END ** Manage Markers **
    // ************************

    // *********************************
    // START ** Manage Speech To Text **
    // *********************************
    isSpeechtotextVisible(language) {
      let isSTT = true;
      for (let i = 0; i < this.videoSubtitles.length; i++) {
        if (language == this.videoSubtitles[i].lang) {
          isSTT = false;
        }
      }
      return isSTT;
    },
    async speechToText(index, language) {
      console.log("++ speech to text:", this.videoDeliverables_audio[index], language);
      // get stt language for google
      let sttLang = "";
      let element = this.languageISOCode.find((el) => el.isoCode === language);
      if (element) {
        sttLang = element.sttCode;
      } else {
        this.$root.addSnackBarMsg(
          "There are inconsistencies on the language, please try again",
          "error"
        );
        return;
      }
      // call speech to text API
      this.token = this.$store.getters.getToken.token;
      this.showLoader(true);
      let apiResponseObj = await EventService.speechToText(
        this.video.videoId,
        language,
        this.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error(
          "Speech to text >> An error has occurred, please try again",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "SPEECH TO TEXT: An error has occurred, please try again",
          "error"
        );
        return;
      }

      //encoding Speech to text
      let sttJobId = apiResponseObj.data.jobId;
      this.$root.addSnackBarMsg("Encoding speech to text in progress", "info");
      console.log("++ apiResponseObj:", apiResponseObj.data);
      this.showLoader(true);
      apiResponseObj = await EventService.encodingSTTJobID(
        sttJobId,
        language,
        sttLang,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error("Encode STT >> error during encode", apiResponseObj.status);
        this.$root.addSnackBarMsg(
          "ENCODE SPEECH TO TEXT: Error during encoding",
          "error"
        );
        return;
      }

      //write data on local storage
      const encodeSTTJob = {
        jobId: sttJobId,
        lang: language,
      };
      this.writeSTTLocalJobId(encodeSTTJob);

      //encoding stt in progress
      this.checkEncodingSTTInProgress(true);
    },
    async checkEncodingSTTInProgress(start) {
      //clear interval isEncodingSTTInProgress
      if (start == false) {
        clearInterval(this.intervalEncodingSTTInProgress);
        this.intervalEncodingSTTInProgress = null;
        this.isEncodingSTTInProgress = false;
        this.removeitemSTTLocalJobId();
        return;
      }
      //read stt jobId from local storage
      let encodeSTTJob = this.readSTTLocalJobId();
      if (!encodeSTTJob) {
        console.warn("++ encodeSTTJob: local storage is empty");
        clearInterval(this.intervalEncodingSTTInProgress);
        this.intervalEncodingSTTInProgress = null;
        this.isEncodingSTTInProgress = false;
        return;
      }

      this.jobIdLocalStorage = encodeSTTJob.jobId;

      //check api jobid status
      await this.checkSTTJobStatus();
      this.intervalEncodingSTTInProgress = setInterval(async () => {
        await this.checkSTTJobStatus();
      }, 5000);
      this.isEncodingSTTInProgress = true;
    },
    removeitemSTTLocalJobId() {
      localStorage.removeItem("encodeSTTJob-" + this.videoId);
    },
    writeSTTLocalJobId(encodeSTTJob) {
      localStorage.setItem("encodeSTTJob-" + this.videoId, JSON.stringify(encodeSTTJob));
    },
    readSTTLocalJobId() {
      let encodeSTTJob = localStorage.getItem("encodeSTTJob-" + this.videoId);
      let objSttJob = null;
      if (encodeSTTJob) {
        try {
          objSttJob = JSON.parse(encodeSTTJob);
        } catch (err) {
          console.error(
            "** readSTTLocalJobId, error during loading from localStorage >>",
            err
          );
          objSttJob = null;
        }
      } else {
        console.warn(
          "** readSTTLocalJobId, >> load from localStorage, localStorage is empty"
        );
        objSttJob = null;
      }
      return objSttJob;
    },
    async checkSTTJobStatus() {
      if (!this.jobIdLocalStorage) return;
      this.token = this.$store.getters.getToken.token;
      let apiResponseObj = await EventService.checkEncodingSTTJobID(
        this.jobIdLocalStorage,
        this.token
      );

      if (apiResponseObj.status != 200) {
        this.isEncodingSTTInProgress = false;
        console.error(
          "++ checkSTTJobStatus >> error during check STT",
          apiResponseObj.status
        );
      } else {
        console.warn("++ checkSTTJobStatus:", apiResponseObj.data);
        let jobStatus = apiResponseObj.data.status;
        if (jobStatus == "encoder job done" || jobStatus == "encoding ended with error") {
          //encoding ended
          if (jobStatus == "encoding ended with error") {
            this.$root.addSnackBarMsg(
              "SPEECH TO TEXT: Encoding ended with error",
              "error"
            );
          }
          this.checkEncodingSTTInProgress(false);
          await this.getVideoDataFromAPI();
        } else {
          //encoding in progress
          this.isEncodingSTTInProgress = true;
        }
      }
    },
    // *******************************
    // END ** Manage Speech To Text **
    // *******************************

    // ************************************
    // START ** Manage VTT (Audio Track) **
    // ************************************
    async updateSubtitleFile(data) {
      const subtitLang = data.subtitle.lang;
      const formData = new FormData();
      const blob = new Blob([data.vttText], { type: "text/vtt" });
      const fileName = subtitLang + "_" + this.video.videoId + ".vtt";
      blob.name = fileName;
      formData.append("file", blob, fileName);

      console.log("++ updateSubtitleFile blob, data", blob, data);
      //if (formData) return;

      // start upload file vtt
      this.isChangesSaved[4] = false;
      this.token = this.$store.getters.getToken.token;
      this.showLoader(true);
      let apiResponseObj = await EventService.uploadSubtitle(
        formData,
        this.video.videoId,
        subtitLang,
        this.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error(
          "Upload file subtitle >> error during upload item",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "UPLOAD SUBTITLE: Error during uploading item",
          "error"
        );
        return;
      }

      // refresh subtitles VTT list
      //delete element
      this.videoSubtitles.splice(data.index, 1);
      this.videoSubtitles.push({
        lang: subtitLang,
        uri: apiResponseObj.data.url,
      });
      this.videoSubtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1));

      //delete element from videoDeliverables_video array, filename != original
      // let splitArr = [];
      // let sas_url = "";
      // for (let i = 0; i < this.videoDeliverables_video.length; i++) {
      //   if (this.videoDeliverables_video[i].audioLanguage == subtitLang) {
      //     if (this.videoDeliverables_video[i].sas_url != undefined) {
      //       sas_url = this.videoDeliverables_video[i].sas_url;
      //       splitArr = sas_url.split("_");
      //       if (splitArr[splitArr.length - 1].split(".")[0] != "original") {
      //         this.videoDeliverables_video.splice(i, 1);
      //       }
      //     }
      //   }
      // }

      //update video obj
      await this.doUpdateVideo();

      //encoding video with VTT
      // START DISABLE
      // this.$root.addSnackBarMsg("Encoding VTT in progress", "info");

      // console.log("++ apiResponseObj:", apiResponseObj.data);

      // apiResponseObj = await EventService.encodingVTTJobID(
      //   apiResponseObj.data.jobId,
      //   this.modelSubtitleLanguage,
      //   this.$store.getters.getToken.token
      // );

      // this.resetUploadSubtitFile();

      // if (apiResponseObj.status != 200) {
      //   console.error("Encode >> error during encode", apiResponseObj.status);
      //   this.$root.addSnackBarMsg("ENCODE VTT: Error during encoding", "error");
      //   return;
      // }
      // END DISABLE
    },
    // async updateSubtitleFile(data) {
    //   const formData = new FormData();
    //   const blob = new Blob([data.vttText], { type: "text/vtt" });
    //   const fileName = data.subtitle.lang + "_" + this.video.videoId + ".vtt";
    //   blob.name = fileName;
    //   formData.append("file", blob, fileName);

    //   console.log("++ updateSubtitleFile blob, data",blob, data);
    //   //if (formData) return;

    //   this.isChangesSaved[4] = false;
    //   this.showLoader(true);
    //   const apiResponseObj = await EventService.uploadSubtitle(
    //     formData,
    //     this.video.videoId,
    //     data.subtitle.lang,
    //     this.token
    //   );
    //   this.showLoader(false);

    //   if (apiResponseObj.status != 200) {
    //     console.error(
    //       "Upload file vtt >> error during upload item",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "UPLOAD VTT FILE: Error during uploading item",
    //       "error"
    //     );
    //     this.resetUploadSubtitFile();
    //     return;
    //   }

    //   this.$root.addSnackBarMsg("Vtt file uploaded", "info");
    //   console.log("*** upload vtt file response:", apiResponseObj);
    //   this.videoSubtitles.splice(data.index, 1);
    //   // add code to refresh array
    //   this.videoSubtitles.push({
    //     lang: data.subtitle.lang,
    //     uri: apiResponseObj.data.url,
    //   });
    //   this.videoSubtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1));

    //   this.resetUploadSubtitFile();
    //   this.showLoader(false);

    //   //update video obj
    //   await this.doUpdateVideo();

    //   this.$refs.btn_add_subtit.click();
    // },
    // **********************************
    // END ** Manage VTT (Audio Track) **
    // **********************************

    // *******************************
    // START ** Manage Video Object **
    // *******************************
    initVideoObjElements() {
      this.awaitVideo = [];
      this.video = [];

      this.videoAttachments = [];

      this.videoDeliverables = [];
      this.videoDeliverables_audio = [];
      this.videoDeliverables_video = [];

      this.videoMarkers = [];
      this.videoMarkerMetadataDetails_it = [];
      this.videoMarkerMetadataDetails_en = [];
      this.videoMarkerMetadataDetails_ru = [];

      this.videoMarkers = [];
      this.videoMarkersMetadataDetails = [];

      this.videoMetadata = [];
      this.videoMetadataDetails_it = {
        lang: "it",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };
      this.videoMetadataDetails_en = {
        lang: "en",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };
      this.videoMetadataDetails_ru = {
        lang: "ru",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };

      this.videoSubtitles = [];

      this.videoThumbnails = [];
    },
    async getVideoDataFromAPI() {
      this.initVideoObjElements();
      //Get video from API
      this.videoId = this.$route.params.id;
      this.isLoading = true;
      this.awaitVideo = await EventService.getVideo(
        this.videoId,
        this.$store.getters.getToken.token
      );

      if (this.awaitVideo.status != 200) {
        console.error("Video >> id:" + this.videoId + " error during loading data");
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET VIDEO: An error has occurred, please try again",
          "error"
        );
        return;
      }

      console.log("+++ Get Video >> data >>", this.awaitVideo);

      this.isLoading = false;
      this.isVisible = true;

      //GET VIDEO DATA ARRAY
      this.video = this.awaitVideo.data;
      console.log("+++ this.video:", this.video);

      //get attachments
      this.videoAttachments = this.video.attachments;
      console.log("+++ this.videoAttachments:", this.videoAttachments);

      //get audioTracks (are inside the video obj)

      //get deliverables
      this.videoDeliverables = this.video.deliverables;
      //add streamLocatorName:""
      for (let i = 0; i < this.videoDeliverables.length; i++) {
        this.videoDeliverables[i].streamLocatorName = "";
      }
      //sort videoDeliverables
      this.videoDeliverables.sort(CommonMethods.compareValuesObjKey("width", "desc"));
      console.log("+++ this.videoDeliverables:", this.videoDeliverables);

      //get digitalEdition (just did, bind video.digitalEdition)

      //get markers
      this.videoMarkers = this.video.markers;
      console.log("+++ this.videoMarkers:", this.videoMarkers);
      this.getAllMarkersMetadataDetails();

      //get metadata
      this.videoMetadata = this.video.metadata;
      console.log("+++ this.videoMetadata:", this.videoMetadata);
      this.getAllMetadataDetails();

      //get subtitles
      this.videoSubtitles = this.video.subtitles;
      this.videoSubtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1));
      console.log("+++ this.videoSubtitles:", this.videoSubtitles);

      //get thumbnails
      this.videoThumbnails = this.video.thumbnails;
      console.log("+++ this.videoThumbnails:", this.videoThumbnails);

      //get attributes from data table attributes (all areas, all categories, all tags)
      // get areas, get categories, get tags
      this.getAttributes();

      this.mainVideoDataConverted = this.outputMainVideoData();

      //construct videojs model

      //separate this.videoDeliverables isAudio = true & isAudio = false
      for (let i = 0; i < this.videoDeliverables.length; i++) {
        if (this.videoDeliverables[i].isAudio) {
          this.videoDeliverables_audio.push(this.videoDeliverables[i]);
        } else {
          this.videoDeliverables_video.push(this.videoDeliverables[i]);
        }
      }
      console.log("+++ this.videoDeliverables_audio:", this.videoDeliverables_audio);
      console.log("+++ this.videoDeliverables_video:", this.videoDeliverables_video);

      //sort only video of deliverbles (but they are just sorted)
      const videoDeliverables_video_Sorted = this.videoDeliverables_video.sort(
        CommonMethods.compareValuesObjKey("width", "desc")
      );
      console.log("+++ videoDeliverables_video_Sorted:", videoDeliverables_video_Sorted);
      //videoProperties obj video src e poster
      //get first element, it will be the first
      if (videoDeliverables_video_Sorted.length > 0) {
        // this.videoProperties.videoSrc =
        //   videoDeliverables_video_Sorted[0].sas_url;
        this.videoProperties.videoSrc = this.getDeliverableVideoOriginal(
          this.videoDeliverables_video,
          "sas_url"
        ).sas_url;
        const elThumb = this.videoThumbnails.find((el) => el.isDefault === true);
        if (elThumb) {
          this.videoProperties.poster = elThumb.uri;
          console.warn("++ this.videoProperties.poster:", this.videoProperties.poster);
        }
        this.videoProperties.width = videoDeliverables_video_Sorted[0].width;
        this.videoProperties.height = videoDeliverables_video_Sorted[0].height;
      } else {
        this.videoProperties = {
          videoSrc: "",
          poster: "",
          width: 0,
          height: 0,
        };
      }

      this.checkIsNoAudioLanguage();
      this.checkNoMoreSubtitlesLanguage();

      console.log("*** videoProperties:", this.videoProperties);

      this.getSharableUrl();

      //this.setPreviewVideoOptions();
    },

    updateVideo() {
      if (!this.validationFields()) return false;
      this.$swal
        .fire({
          title: "Update",
          html:
            "Are you sure you want to update all data?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doUpdateVideo();
          }
        });
    },
    async doUpdateVideo() {
      //check required field
      if (!this.checkRequiredField()) return;

      let videoToUpload = { ...this.video };
      console.log("+++ this.video:", this.video);

      //important: remove videoId key before to upload API
      delete videoToUpload.videoId;

      //UPDATE VIDEO SEQUENCE

      //update areas
      videoToUpload.areas = this.getAttributesAdded(
        this.tagsAreasValue,
        this.tagsAreas,
        "areaId"
      );

      //update attachments
      videoToUpload.attachments = this.videoAttachments;

      //upload audioTracks (insert any changes on audioTracks key)
      console.log("++ this.video.audioTracks:", this.video.audioTracks);

      //upload categories
      videoToUpload.categories = this.getAttributesAdded(
        this.tagsCategoriesValue,
        this.tagsCategories,
        "catId"
      );

      //upload deliverables
      this.videoDeliverables = [];
      for (let i = 0; i < this.videoDeliverables_audio.length; i++) {
        this.videoDeliverables.push(this.videoDeliverables_audio[i]);
      }
      for (let i = 0; i < this.videoDeliverables_video.length; i++) {
        this.videoDeliverables.push(this.videoDeliverables_video[i]);
      }
      videoToUpload.deliverables = this.videoDeliverables;

      //upload digitalEdition (just do it, bind video.digitalEdition)

      //upload markers
      let id, idFounded;
      for (let i = 0; i < this.videoMarkers.length; i++) {
        id = this.videoMarkers[i].id;
        idFounded = this.videoMarkersMetadataDetails.find((el) => el.id === id);
        if (idFounded) {
          this.videoMarkers[i].metaDataDetails = idFounded.metaDataElements;
        }
      }
      videoToUpload.markers = this.videoMarkers;

      //upload metadata
      videoToUpload.metadata = [
        this.videoMetadataDetails_it,
        this.videoMetadataDetails_en,
        this.videoMetadataDetails_ru,
      ];

      //upload subtitles (just do it)

      //upload tags
      videoToUpload.tags = this.getAttributesAdded(
        this.tagsTagsValue,
        this.tagsTags,
        "tagId"
      );

      //update thumbnails
      videoToUpload.thumbnails = this.videoThumbnails;

      // Upload video
      console.log("+++ videoToUpload:", videoToUpload);

      //Start upload video obj by API
      this.showLoader(true);
      const resultAPI = await EventService.updateVideoObj(
        videoToUpload,
        this.video.videoId,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);

      if (resultAPI.status != 200) {
        console.error(
          "+++ uploadVideo >> id:" + this.video.videoId + " error during updating data"
        );
        this.$root.addSnackBarMsg(
          "SAVE VIDEO: An error has occurred, please try again",
          "error"
        );
        return;
      }

      this.$root.addSnackBarMsg("The video was updated successfully", "info");
      this.initChangesSaved();

      //refresh page
      //this.$router.go();
      await this.getVideoDataFromAPI();
    },
    checkRequiredField() {
      //metadata it title, cannot be empty
      if (this.videoMetadataDetails_en.title == "") {
        console.error(
          "** this.videoMetadataDetails_en.title: is required. This field cannot be empty"
        );
        this.$root.addSnackBarMsg(
          "METADATA EN TITLE: This field cannot be empty",
          "warning"
        );
        return false;
      }
      //metadata it author, cannot be empty
      if (this.videoMetadataDetails_en.author == "") {
        console.error(
          "** this.videoMetadataDetails_en.author: is required. This field cannot be empty"
        );
        this.$root.addSnackBarMsg(
          "METADATA EN AUTHOR: This field cannot be empty",
          "warning"
        );
        return false;
      }

      return true;
    },
    // *****************************
    // END ** Manage Video Object **
    // *****************************

    handlerContextmenu(e) {
      e.preventDefault();
    },
    pauseVideo() {
      if (this.$refs.videoPlayer_GeneralInfo) {
        this.$refs.videoPlayer_GeneralInfo.pause();
      }
      if (this.$refs.videoPlayer_Preview) {
        this.$refs.videoPlayer_Preview.pause();
      }
      if (this.$refs.videoPlayer_Marker) {
        this.$refs.videoPlayer_Marker.pause();
      }
      let audioPlayers = document.getElementsByClassName("audioPlayer");
      console.log(audioPlayers);
      for (let i = 0; i < audioPlayers.length; i++) {
        if (audioPlayers[i]) {
          audioPlayers[i].pause();
        }
      }
    },
    setPreviewVideoOptions() {
      const src = this.videoProperties.videoSrc;
      const videoOptions = {
        autoplay: false,
        controls: true,
        sources: {
          src: src,
          type: "video/mp4",
        },
      };
      return videoOptions;
    },

    outputMainVideoData() {
      let returnOutputObjVideo = {};

      // creationDateUnixTimeStamp
      let creationDateUnixTimeStamp = this.convertUnixDate(
        this.video.creationDateUnixTimeStamp
      );

      //modificationDateUnixTimeStamp
      let modificationDateUnixTimeStamp = this.convertUnixDate(
        this.video.modificationDateUnixTimeStamp
      );

      //returnOutputObjVideo
      returnOutputObjVideo = {
        //creationDateUnixTimeStamp & modificationDateUnixTimeStamp
        creationDateUnixTimeStamp: creationDateUnixTimeStamp,
        modificationDateUnixTimeStamp: modificationDateUnixTimeStamp,
        //views
        views: this.formatNumber(this.video.views),
      };
      return returnOutputObjVideo;
    },

    // ***************************
    // START ** Manage Metadata **
    // ***************************
    getAllMetadataDetails() {
      //metadetails it
      this.videoMetadataDetails_it = this.getMetadatadetailsByLang(
        this.video.metadata,
        "lang",
        "it",
        "metaDataDetails"
      );

      //metadetails en
      this.videoMetadataDetails_en = this.getMetadatadetailsByLang(
        this.video.metadata,
        "lang",
        "en",
        "metaDataDetails"
      );

      //metadetails ru
      this.videoMetadataDetails_ru = this.getMetadatadetailsByLang(
        this.video.metadata,
        "lang",
        "ru",
        "metaDataDetails"
      );
    },
    getMetadatadetailsByLang(videoObjKey, lang, value, metaDetails) {
      let metaDataDetails = {
        title: this.na,
        author: this.na,
        s_abstract: this.na,
        eyelet: this.na,
        description: this.na,
      };
      const searchMetaDataDetails = this.searchJsonArrayValueFromKey(
        videoObjKey,
        lang,
        value,
        metaDetails
      );

      if (searchMetaDataDetails.length == 0)
        return { lang: value, metaDataDetails: metaDataDetails };

      metaDataDetails = {
        title:
          searchMetaDataDetails[0].value.title != ""
            ? searchMetaDataDetails[0].value.title
            : this.na,
        author:
          searchMetaDataDetails[0].value.author != ""
            ? searchMetaDataDetails[0].value.author
            : this.na,
        s_abstract:
          searchMetaDataDetails[0].value.s_abstract != ""
            ? searchMetaDataDetails[0].value.s_abstract
            : this.na,
        eyelet:
          searchMetaDataDetails[0].value.eyelet != ""
            ? searchMetaDataDetails[0].value.eyelet
            : this.na,
        description:
          searchMetaDataDetails[0].value.description != ""
            ? searchMetaDataDetails[0].value.description
            : this.na,
      };

      return { lang: value, metaDataDetails: metaDataDetails };
    },
    onTitleVideoENChange(event) {
      if (event.target.value == "") {
        this.$root.addSnackBarMsg(
          "OVERVIEW METADATA EN TITLE: This field cannot be empty",
          "warning"
        );
      }
    },
    validationFields() {
      if (this.videoMetadataDetails_en.metaDataDetails.title == "") {
        this.$root.addSnackBarMsg(
          "OVERVIEW METADATA EN TITLE: This field cannot be empty",
          "warning"
        );
        return false;
      }
      return true;
    },
    // *************************
    // END ** Manage Metadata **
    // *************************

    getJsonArrayValueFromKey(jsonKey, key) {
      let obj = [];
      for (let i = 0; i < jsonKey.length; i++) {
        for (const [_key, _value] of Object.entries(jsonKey[i])) {
          if (_key == key) {
            obj.push({ key: _key, value: _value });
            break;
          }
        }
      }
      return obj;
    },
    searchJsonArrayValueFromKey(jsonKey, key, valueToSearch, keyToGet) {
      let obj = [];
      for (let i = 0; i < jsonKey.length; i++) {
        for (const [_key, _value] of Object.entries(jsonKey[i])) {
          if (_key == key && _value == valueToSearch) {
            for (const [_keyN, _valueN] of Object.entries(jsonKey[i])) {
              if (_keyN == keyToGet) {
                obj.push({ key: _keyN, value: _valueN });
                break;
              }
            }
            break;
          }
        }
      }
      return obj;
    },
    formatBytes(bytes) {
      return CommonMethods.formatBytes(bytes);
    },
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
    async downloadFileVideo(url) {
      console.log("++ file video uri:", url);

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/video" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    getResolution(width, height) {
      let obj = this.videoResolutionChart.find((o) => o.pixelSize.width == width);
      let str = "-";
      if (width != 0 && height != 0) {
        if (obj) {
          if (obj.pixelSize.height == height) {
            str = obj.description;
          } else {
            str = `${width} x ${height}`;
          }
        } else {
          str = `${width} x ${height}`;
        }
      }
      return str;
    },
    getMovieVersions(filename = false) {
      let width = 0;
      let height = 0;
      let returnDirectUrl = [];
      let splitArr = [];
      let sas_url = "";
      let index = 0;
      let description = "";
      let lang = "";
      let langCode = "";
      let originalStr = "";
      for (let i = 0; i < this.videoDeliverables.length; i++) {
        if (this.videoDeliverables[i].isAudio == false) {
          width = this.videoDeliverables[i].width;
          height = this.videoDeliverables[i].height;
          langCode = this.videoDeliverables[i].audioLanguage;
          lang = `[ ${this.getLanguageLabel(langCode)} ]`;

          //look in the default table (videoResolutionChart) for the width
          index = this.videoResolutionChart.findIndex((o) => o.pixelSize.width === width);

          if (index != -1) {
            //element found
            if (this.videoResolutionChart[index].pixelSize.height == height) {
              description = `${this.videoResolutionChart[index].description} ${lang}`;
            } else {
              //element not found
              description = `${width} x ${height} ${lang}`;
            }
          } else {
            //element not found
            description = `${width} x ${height} ${lang}`;
          }

          if (this.videoDeliverables[i].sas_url != undefined) {
            if (filename) {
              sas_url = this.videoDeliverables[i].fileName;
            } else {
              sas_url = this.videoDeliverables[i].sas_url;
            }
            splitArr = sas_url.split("_");
            if (splitArr[splitArr.length - 1].split(".")[0] == "original") {
              originalStr = " - Original";
            } else if (splitArr[splitArr.length - 1].split(".")[0] == "") {
              originalStr = " - Subtitles";
            } else {
              originalStr = "";
            }
            returnDirectUrl.push({
              description: description + originalStr,
              value: sas_url,
            });
          }
        }
      }
      return returnDirectUrl;
    },
    getDeliverableVideoOriginal(array, key) {
      let str = "";
      let index, existsInString;
      let element = array[0];
      for (let i = 0; i < array.length; i++) {
        str = array[i][key];
        index = str.indexOf("_original.");
        existsInString = !!~index;
        if (existsInString) {
          element = array[i];
          break;
        }
      }
      return element;
    },
    getLanguageFromSasUrl(str) {
      let index,
        existsInString,
        splitArr = ["", ""],
        lang = "";
      index = str.indexOf("_original_");
      existsInString = !!~index;
      if (existsInString) {
        console.warn("++ existsInString:", existsInString);
        splitArr = str.split("_original_");
        lang = splitArr[1].substring(0, 2);
        console.log("++ splitArr[0]", splitArr[0]);
        console.log("++ splitArr[1]", splitArr[1]);
        console.log("++ str", str);
      }
      return lang;
    },

    // *****************************
    // START ** Manage Attributes **
    // *****************************
    getAttributesAdded(attributesValue, arrayAttributes, key) {
      let elementReturn = [];
      let valueAtt, elementFound, attr;
      for (let i = 0; i < attributesValue.length; i++) {
        valueAtt = attributesValue[i];
        elementFound = arrayAttributes.find((el) => el.value == valueAtt);
        if (elementFound != undefined) {
          attr = {
            name: elementFound.label,
            [key]: valueAtt,
            lang: process.env.VUE_APP_DEFAULT_METADATA_LANG,
            namesearch: elementFound.label.toLowerCase(),
          };
          elementReturn.push(attr);
        }
      }
      return elementReturn;
    },
    // Manage Categories ===
    async getAttributesCategories() {
      await EventService.getCategories(this.$store.getters.getToken.token);
      this.attributes_categories = CommonMethods.getSemplifyAttributes(
        this.$store.getters.categories,
        "catId"
      );
    },
    createTagsCategories() {
      this.tagsCategories = [];
      for (let i = 0; i < this.attributes_categories.length; i++) {
        this.tagsCategories.push({
          value: this.attributes_categories[i].catId,
          label: this.attributes_categories[i].name,
        });
      }
    },
    getTagsCategories() {
      this.tagsCategoriesValue = [];
      for (let i = 0; i < this.video.categories.length; i++) {
        if (this.video.categories[i].lang == process.env.VUE_APP_DEFAULT_METADATA_LANG) {
          this.tagsCategoriesValue.push(this.video.categories[i].catId);
        }
      }
    },
    // Manage Tags ===
    async getAttributesTags() {
      await EventService.getTags(this.$store.getters.getToken.token);
      this.attributes_tags = CommonMethods.getSemplifyAttributes(
        this.$store.getters.tags,
        "tagId"
      );
    },
    createTagsTags() {
      this.tagsTags = [];
      for (let i = 0; i < this.attributes_tags.length; i++) {
        this.tagsTags.push({
          value: this.attributes_tags[i].tagId,
          label: this.attributes_tags[i].name,
        });
      }
    },
    getTagsTags() {
      this.tagsTagsValue = [];
      for (let i = 0; i < this.video.tags.length; i++) {
        if (this.video.tags[i].lang == process.env.VUE_APP_DEFAULT_METADATA_LANG) {
          this.tagsTagsValue.push(this.video.tags[i].tagId);
        }
      }
    },
    // Manage Areas ===
    async getAttributesAreas() {
      await EventService.getAreas(this.$store.getters.getToken.token);
      this.attributes_areas = CommonMethods.getSemplifyAttributes(
        this.$store.getters.areas,
        "areaId"
      );
    },
    createTagsAreas() {
      this.tagsAreas = [];
      for (let i = 0; i < this.attributes_areas.length; i++) {
        this.tagsAreas.push({
          value: this.attributes_areas[i].areaId,
          label: this.attributes_areas[i].name,
        });
      }
    },
    getTagsAreas() {
      this.tagsAreasValue = [];
      for (let i = 0; i < this.video.areas.length; i++) {
        if (this.video.areas[i].lang == process.env.VUE_APP_DEFAULT_METADATA_LANG) {
          this.tagsAreasValue.push(this.video.areas[i].areaId);
        }
      }
    },
    async getAttributes() {
      //Manage Categories
      await this.getAttributesCategories();
      this.createTagsCategories();
      this.getTagsCategories();
      //Manage Tags
      await this.getAttributesTags();
      this.createTagsTags();
      this.getTagsTags();
      //Manage Areas
      await this.getAttributesAreas();
      this.createTagsAreas();
      this.getTagsAreas();
    },
    // ***************************
    // END ** Manage Attributes **
    // ***************************

    // *****************************
    // START ** Manage Attachment **
    // *****************************
    async deleteAttachment(index) {
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this attachment?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            //call code to delete
            this.callDeleteAttachment(index);
          }
        });
    },
    async callDeleteAttachment(index) {
      //delete element from array
      this.videoAttachments.splice(index, 1);
      this.$root.addSnackBarMsg("Attachment deleted", "info");
      //update video obj
      await this.doUpdateVideo();
    },

    handleAttachmentUpload(event) {
      this.attachmentFileObj = {};
      this.isUploadAttachReady = false;
      let tmpFile = event.target.files[0];
      console.log(`** attachment tmpFile: ${tmpFile}`);
      if (!tmpFile) return;
      let tmpFileSize = tmpFile.size;
      let tmpFileType = tmpFile.type;
      console.log(`** attachment: ${tmpFile.name} - ${tmpFileSize} - ${tmpFileType}`);
      if (!this.validAttachmentFileType(tmpFile)) {
        this.$root.addSnackBarMsg(
          `File name ${tmpFile.name}: Not a valid file type. Update your selection.`,
          "warning"
        );
        return;
      }
      if (tmpFileSize === 0) {
        this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
        return;
      }

      this.attachmentModelFile = `File name: ${
        tmpFile.name
      } - File size: ${this.formatBytes(tmpFile.size)}`;
      this.attachmentFileObj = tmpFile;
      this.attachmentFileSrc = URL.createObjectURL(this.attachmentFileObj);

      this.isUploadAttachReady = true;

      // this.statusIndex = 0;
      console.log("** attachment to upload:", this.attachmentFileObj);
    },
    validAttachmentFileType(file) {
      console.log("** attachments file type:", file.type);
      return this.attachmentFileTypes.includes(file.type);
    },
    resetUploadAttachFile() {
      this.attachmentFileObj = {};
      this.attachmentFileSrc = "";
      this.isUploadAttachReady = false;
      this.attachmentModelFile = "";
      //reset input file value
      document.getElementById("attachmentFile").value = "";
    },
    removeUploadAttachFromList() {
      this.resetUploadAttachFile();
      this.$root.addSnackBarMsg("File removed", "info");
      // this.$refs.btn_add_attach.click();
    },
    async uploadAttachmentFile() {
      const formData = new FormData();
      formData.append("file", this.attachmentFileObj, this.attachmentFileObj.name);

      this.isChangesSaved[5] = false;
      this.token = this.$store.getters.getToken.token;
      this.showLoader(true);
      const apiResponseObj = await EventService.uploadAttachment(
        formData,
        this.video.videoId,
        this.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error(
          "Upload file attachment >> error during upload item",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg("UPLOAD FILE: Error during uploading item", "error");
        return;
      }

      this.$root.addSnackBarMsg("File uploaded", "info");
      console.log("*** upload file response:", apiResponseObj);
      // add code to refresh array
      this.videoAttachments.push(apiResponseObj.data);

      this.resetUploadAttachFile();
      this.showLoader(false);

      //update video obj
      await this.doUpdateVideo();

      this.$refs.btn_add_attach.click();
    },
    // ***************************
    // END ** Manage Attachment **
    // ***************************

    // ******************************************************
    // START ** Manage Thumbnail & Manage Upload Thumbnail **
    // ******************************************************
    setThumbnail(item, index) {
      if (item.isDefault) return;
      for (let i = 0; i < this.videoThumbnails.length; i++) {
        this.videoThumbnails[i].isDefault = false;
      }

      this.videoThumbnails[index].isDefault = true;
      this.videoProperties.poster = item.uri;

      console.log("++ video properties change thumbnail:", this.videoProperties);
      this.$root.addSnackBarMsg("Thumbnail selected", "info");
      this.isChangesSaved[2] = false;
      console.log("*** thumbnail isDefault:", item, "index:", index);
    },
    deleteThumbnail(item, index) {
      console.log("*** delete thumbnail:", item, index);
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this thumbnail?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            //call code to delete by api
            this.callDeleteThumbnail(index);

            //delete item from array
            // this.videoThumbnails = this.videoThumbnails.filter((video) => {
            //   return video.uri !== item.uri;
            // });
          }
        });
    },
    async callDeleteThumbnail(index) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE THUMBNAIL: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }

      //delete element from array
      this.videoThumbnails.splice(index, 1);
      this.$root.addSnackBarMsg("Thumbnail deleted", "info");
      //update video obj
      await this.doUpdateVideo();
    },
    // async callDeleteThumbnail(index) {
    //   if (this.isLogged) {
    //     this.token = this.$store.getters.getToken.token;
    //   } else {
    //     this.$root.addSnackBarMsg(
    //       "DELETE THUMBNAIL: An error has occurred, please try again",
    //       "error"
    //     );
    //     this.$store.dispatch("clearToken").then(() => {});
    //     this.$router.push({ name: "view-login" });
    //     return false;
    //   }
    //   this.showLoader(true);
    //   const options = {
    //     videoId: this.video.videoId,
    //     thumbName: this.videoThumbnails[index].uri,
    //   };
    //   const apiResponseObj = await EventService.deleteThumbnail(
    //     options,
    //     this.token
    //   );
    //   this.showLoader(false);
    //   if (apiResponseObj.status === 200) {
    //     //delete element from array
    //     this.videoThumbnails.splice(index, 1);
    //     this.$root.addSnackBarMsg("Thumbnail deleted", "info");
    //     //update video obj
    //     await this.doUpdateVideo();
    //   } else {
    //     console.error(
    //       "Delete thumbnail >> error during delete item",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "DELETE THUMBNAIL: Error during deleting item",
    //       "error"
    //     );
    //   }
    // },
    handleThumbnailUpload(event) {
      this.thumbnailFileObj = {};
      this.isUploadThumbReady = false;
      let tmpFile = event.target.files[0];
      console.log(`** thumbnail tmpFile: ${tmpFile}`);
      if (!tmpFile) return;
      let tmpFileSize = tmpFile.size;
      let tmpFileType = tmpFile.type;
      console.log(`** thumbnail: ${tmpFile.name} - ${tmpFileSize} - ${tmpFileType}`);
      if (!this.validImageFileType(tmpFile)) {
        this.$root.addSnackBarMsg(
          `File name ${tmpFile.name}: Not a valid file type. Update your selection.`,
          "warning"
        );
        return;
      }
      if (tmpFileSize === 0) {
        this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
        return;
      }

      this.thumbnailModelFile = `File name: ${
        tmpFile.name
      } - File size: ${this.formatBytes(tmpFile.size)}`;
      this.thumbnailFileObj = tmpFile;
      this.thumbnailImageSrc = URL.createObjectURL(this.thumbnailFileObj);

      this.isUploadThumbReady = true;

      // this.statusIndex = 0;
      console.log("** thumbnail to upload:", this.thumbnailFileObj);
    },
    validImageFileType(file) {
      return this.imageFileTypes.includes(file.type);
    },
    resetUploadThumbFile() {
      this.thumbnailFileObj = {};
      this.thumbnailImageSrc = "";
      this.isUploadThumbReady = false;
      this.thumbnailModelFile = "";
      //reset input file value
      document.getElementById("thumbnailFile").value = "";
    },
    removeUploadThumbFromList() {
      this.resetUploadThumbFile();
      this.$root.addSnackBarMsg("Thumbnail removed", "info");
    },
    resetUploadTakeThumbFile() {
      this.thumbnailTakeFileObj = {};
      this.thumbnailTakeImageSrc = "";
      this.isUploadTakeThumbReady = false;
    },
    removeUploadTakeThumbFromList() {
      this.resetUploadTakeThumbFile();
      this.$root.addSnackBarMsg("Thumbnail removed", "info");
    },
    async uploadThumbnailFile() {
      console.log("+++ uploadThumbnailFile > thumbnailFileObj", this.thumbnailFileObj);
      const formData = new FormData();
      formData.append("file", this.thumbnailFileObj, this.thumbnailFileObj.name);
      console.log("++ uploadThumbnailFile > formData:", formData);
      this.isChangesSaved[2] = false;
      this.token = this.$store.getters.getToken.token;
      this.showLoader(true);
      const apiResponseObj = await EventService.uploadThumbnail(
        formData,
        this.video.videoId,
        this.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error(
          "Upload thumbnail >> error during upload item",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "UPLOAD THUMBNAIL: Error during uploading item",
          "error"
        );
        return;
      }

      this.$root.addSnackBarMsg("Thumbnail uploaded", "info");
      console.log("*** upload thumbnail response:", apiResponseObj);
      // add code to refresh array
      this.videoThumbnails.push({
        uri: apiResponseObj.data.url,
        isDefault: false,
      });

      this.resetUploadThumbFile();
      this.showLoader(false);

      //update video obj
      await this.doUpdateVideo();
    },
    async uploadTakeThumbnailFile() {
      let imageToSave = document.getElementById("imgTakeThumb");
      if (imageToSave) {
        let imageObj = {
          data: imageToSave.src,
          videoId: this.video.videoId,
        };

        this.showLoader(true);
        let saveImage = await EventService.uploadThumbnailBase64(
          imageObj,
          this.$store.getters.getToken.token
        );
        this.showLoader(false);

        if (saveImage.status != 200) {
          console.error("Upload thumbnail >> error during saving data");
          this.$root.addSnackBarMsg(
            "UPLOAD THUMBNAIL: Error during uploading item",
            "error"
          );
          return;
        }

        this.$root.addSnackBarMsg("Thumbnail uploaded", "info");

        console.log("*** upload thumbnail response:", saveImage);
        // add code to refresh array
        this.videoThumbnails.push({
          uri: saveImage.data.url,
          isDefault: false,
        });

        //update video obj
        await this.doUpdateVideo();
      } else {
        this.$root.addSnackBarMsg("There is no image to save", "warning");
      }
    },
    // async uploadTakeThumbnailFile() {
    //   console.log(
    //     "+++ uploadTakeThumbnailFile > thumbnailTakeFileObj:",
    //     this.thumbnailTakeFileObj
    //   );
    //   this.thumbnailTakeFileObj.name = this.thumbnailTakeImageFilename;
    //   const formData = new FormData();
    //   formData.append(
    //     "file",
    //     this.thumbnailTakeFileObj,
    //     this.thumbnailTakeFileObj.name
    //   );

    //   console.log("++ uploadTakeThumbnailFile > formData:", formData);

    //   this.isChangesSaved[2] = false;
    //   this.showLoader(true);
    //   const apiResponseObj = await EventService.uploadThumbnail(
    //     formData,
    //     this.video.videoId,
    //     this.token
    //   );
    //   this.showLoader(false);

    //   if (apiResponseObj.status != 200) {
    //     console.error(
    //       "Upload thumbnail >> error during upload item",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "UPLOAD THUMBNAIL: Error during uploading item",
    //       "error"
    //     );
    //     return;
    //   }

    //   this.$root.addSnackBarMsg("Thumbnail uploaded", "info");
    //   console.log("*** upload thumbnail response:", apiResponseObj);
    //   // add code to refresh array
    //   this.videoThumbnails.push({
    //     uri: apiResponseObj.data.url,
    //     isDefault: false,
    //   });

    //   this.resetUploadTakeThumbFile();
    //   this.showLoader(false);

    //   //update video obj
    //   await this.doUpdateVideo();
    // },
    createSnapshotFromVideo() {
      this.isUploadTakeThumbReady = false;
      let canvas = document.createElement("canvas");

      console.log(
        "++ video preview size:",
        this.$refs.videoPreviewContainer.clientWidth,
        this.$refs.videoPreviewContainer.clientHeight
      );
      canvas.id = "canvas";
      canvas.width = this.$refs.videoPreviewContainer.clientWidth; //this.videoProperties.width;
      canvas.height = this.$refs.videoPreviewContainer.clientHeight; //this.videoProperties.height;
      canvas.style.position = "absolute";
      canvas.style.zIndex = 100;
      canvas.hidden = true;

      let body = document.getElementsByTagName("body")[0];
      body.appendChild(canvas);

      const elementVideo = this.$refs.videoPlayer_Preview.$el;
      canvas.getContext("2d").drawImage(elementVideo, 0, 0, canvas.width, canvas.height);

      this.thumbnailTakeImageSrc = canvas.toDataURL();
      this.thumbnailTakeImageFilename =
        "snapshot_" + Math.floor(Date.now() / 1000) + ".jpg";

      this.thumbnailTakeFileObj = new Blob([this.thumbnailTakeImageSrc], {
        type: "image/jpeg",
      });

      this.isUploadTakeThumbReady = true;
      canvas.remove();
    },
    // ****************************************************
    // END ** Manage Thumbnail & Manage Upload Thumbnail **
    // ****************************************************

    // *********************************
    // START ** Manage File Subtitles **
    // *********************************
    async deleteSubtitle(index) {
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this subtitle?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            //call code to delete
            this.callDeleteSubtitle(index);
          }
        });
    },
    async callDeleteSubtitle(index) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE SUBTITLE: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }

      // let langToDelete = this.videoSubtitles[index].lang;

      //delete element from videoSubtitles array
      this.videoSubtitles.splice(index, 1);
      this.$root.addSnackBarMsg("Subtitle deleted", "info");

      //delete element from videoDeliverables_video array, filename != original
      // let splitArr = [];
      // let sas_url = "";
      // for (let i = 0; i < this.videoDeliverables_video.length; i++) {
      //   if (this.videoDeliverables_video[i].audioLanguage == langToDelete) {
      //     if (this.videoDeliverables_video[i].sas_url != undefined) {
      //       sas_url = this.videoDeliverables_video[i].sas_url;
      //       splitArr = sas_url.split("_");
      //       if (splitArr[splitArr.length - 1].split(".")[0] != "original") {
      //         this.videoDeliverables_video.splice(i, 1);
      //       }
      //     }
      //   }
      // }

      //update videoDeliverables array
      // this.videoDeliverables = [];
      // for (let i = 0; i < this.videoDeliverables_audio.length; i++) {
      //   this.videoDeliverables.push(this.videoDeliverables_audio[i]);
      // }
      // for (let i = 0; i < this.videoDeliverables_video.length; i++) {
      //   this.videoDeliverables.push(this.videoDeliverables_video[i]);
      // }

      this.checkIsNoAudioLanguage();
      this.checkNoMoreSubtitlesLanguage();

      //update video obj
      await this.doUpdateVideo();
    },
    // async callDeleteSubtitle(index) {
    //   if (this.isLogged) {
    //     this.token = this.$store.getters.getToken.token;
    //   } else {
    //     this.$root.addSnackBarMsg(
    //       "DELETE SUBTITLE: An error has occurred, please try again",
    //       "error"
    //     );
    //     this.$store.dispatch("clearToken").then(() => {});
    //     this.$router.push({ name: "view-login" });
    //     return false;
    //   }
    //   this.showLoader(true);
    //   const options = {
    //     videoId: this.video.videoId,
    //     subtitlename: this.videoSubtitles[index].uri,
    //   };
    //   const apiResponseObj = await EventService.deleteSubtitle(
    //     options,
    //     this.token
    //   );
    //   if (apiResponseObj.status === 200) {
    //     this.showLoader(false);
    //     //delete element from array
    //     this.videoSubtitles.splice(index, 1);
    //     this.$root.addSnackBarMsg("Subtitle deleted", "info");
    //     //update video obj
    //     await this.doUpdateVideo();
    //   } else {
    //     this.showLoader(false);
    //     console.error(
    //       "Delete subtitle >> error during delete item",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "DELETE SUBTITLE: Error during deleting item",
    //       "error"
    //     );
    //   }
    // },
    handleSubtitleUpload(event) {
      this.subtitleFileObj = {};
      this.isUploadSubtitReady = false;
      this.isUploadTranslateSubtitReady = false;
      let tmpFile = event.target.files[0];
      console.log(`** subtitle tmpFile: ${tmpFile}`);
      if (!tmpFile) return;
      let tmpFileSize = tmpFile.size;
      let tmpFileType = tmpFile.type;
      console.log(`** subtitle: ${tmpFile.name} - ${tmpFileSize} - ${tmpFileType}`);
      if (!this.validSubtitleFileExtension(tmpFile)) {
        this.$root.addSnackBarMsg(
          `File name ${tmpFile.name}: Not a valid file type. Update your selection.`,
          "warning"
        );
        this.resetUploadSubtitFile();
        return;
      }
      if (tmpFileSize === 0) {
        this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
        return;
      }

      this.subtitleModelFile = `File name: ${
        tmpFile.name
      } - File size: ${this.formatBytes(tmpFile.size)}`;
      this.subtitleFileObj = tmpFile;
      this.subtitleFileSrc = URL.createObjectURL(this.subtitleFileObj);

      this.isUploadSubtitReady = true;
      this.isUploadTranslateSubtitReady = false;

      console.log("** subtitle to upload:", this.subtitleFileObj);
    },
    handleTranslateSubtitleUpload(event) {
      this.subtitleFileObj = {};
      this.isUploadSubtitReady = false;
      this.isUploadTranslateSubtitReady = false;
      let tmpFile = event.target.files[0];
      console.log(`** subtitle tmpFile: ${tmpFile}`);
      if (!tmpFile) return;
      let tmpFileSize = tmpFile.size;
      let tmpFileType = tmpFile.type;
      console.log(`** subtitle: ${tmpFile.name} - ${tmpFileSize} - ${tmpFileType}`);
      if (!this.validSubtitleFileExtension(tmpFile)) {
        this.$root.addSnackBarMsg(
          `File name ${tmpFile.name}: Not a valid file type. Update your selection.`,
          "warning"
        );
        this.resetUploadSubtitFile();
        return;
      }
      if (tmpFileSize === 0) {
        this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
        return;
      }

      this.subtitleModelFile = `File name: ${
        tmpFile.name
      } - File size: ${this.formatBytes(tmpFile.size)}`;
      this.subtitleFileObj = tmpFile;
      this.subtitleFileSrc = URL.createObjectURL(this.subtitleFileObj);

      this.isUploadTranslateSubtitReady = true;
      this.isUploadSubtitReady = false;

      console.log("** subtitle to upload:", this.subtitleFileObj);
    },
    validSubtitleFileType(file) {
      console.log("** subtitles file type:", file.type);
      return this.subtitleFileTypes.includes(file.type);
    },
    validSubtitleFileExtension(file) {
      console.log("** subtitles file", file);
      let filename = file.name;
      filename =
        filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
      filename = filename.toLowerCase();
      return this.subtitleFileTypes.includes(filename);
    },
    resetUploadSubtitFile() {
      this.subtitleFileObj = {};
      this.subtitleFileSrc = "";
      this.isUploadSubtitReady = false;
      this.isUploadTranslateSubtitReady = false;
      this.isSelectLanguageTranslateReady = false;
      this.subtitleModelFile = "";
      this.modelSubtitleLanguage = "";
      this.modelInputTranslateSubtitleLanguage = "";
      this.modelOutpuptTranslateSubtitleLanguage = "";
      //reset input file value
      if (document.getElementById("subtitleFile")) {
        document.getElementById("subtitleFile").value = "";
      }
      if (document.getElementById("subtitleTranslateFile")) {
        document.getElementById("subtitleTranslateFile").value = "";
      }
    },
    removeUploadSubtitFromList() {
      this.resetUploadSubtitFile();
      this.$root.addSnackBarMsg("Subtitle removed", "info");
    },
    async uploadNewSubtitleFile() {
      const formData = new FormData();
      formData.append("file", this.subtitleFileObj, this.subtitleFileObj.name);

      console.log(this.subtitleFileObj);
      // if (formData) return;

      // start upload file vtt
      this.isChangesSaved[4] = false;
      this.token = this.$store.getters.getToken.token;
      this.showLoader(true);
      let apiResponseObj = await EventService.uploadSubtitle(
        formData,
        this.video.videoId,
        this.modelSubtitleLanguage,
        this.token
      );

      if (apiResponseObj.status != 200) {
        this.showLoader(false);
        console.error(
          "Upload file subtitle >> error during upload item",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "UPLOAD SUBTITLE: Error during uploading item",
          "error"
        );
        return;
      }

      // refresh subtitles VTT list
      this.videoSubtitles.push({
        lang: this.modelSubtitleLanguage,
        uri: apiResponseObj.data.url,
      });
      this.videoSubtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1));

      this.$refs.btn_add_subtit.click();

      this.showLoader(false);

      this.resetUploadSubtitFile();

      //update video obj
      await this.doUpdateVideo();

      //encoding video with VTT
      // START DISABLE
      // this.$root.addSnackBarMsg("Encoding VTT in progress", "info");

      // console.log("++ apiResponseObj:", apiResponseObj.data);

      // apiResponseObj = await EventService.encodingVTTJobID(
      //   apiResponseObj.data.jobId,
      //   this.modelSubtitleLanguage,
      //   this.$store.getters.getToken.token
      // );

      // this.resetUploadSubtitFile();

      // if (apiResponseObj.status != 200) {
      //   console.error("Encode >> error during encode", apiResponseObj.status);
      //   this.$root.addSnackBarMsg("ENCODE VTT: Error during encoding", "error");
      //   return;
      // }
      // END DISABLE
    },
    async uploadNewSubtitleFileToTranslate() {
      // this.modelInputTranslateSubtitleLanguage
      // this.modelOutpuptTranslateSubtitleLanguage

      // start translate, invoke api
      this.isChangesSaved[4] = false;
      this.token = this.$store.getters.getToken.token;
      this.showLoader(true);
      let apiResponseObj = await EventService.sendVTTToTranslate(
        this.video.videoId,
        this.modelInputTranslateSubtitleLanguage,
        this.modelOutpuptTranslateSubtitleLanguage,
        this.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error(
          "Subtitle translate >> error during translate",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "TRANSLATE SUBTITLE: Error during translation",
          "error"
        );
        return;
      }

      console.log("++ return translate:", apiResponseObj.data);

      //**************************** */

      // refresh subtitles VTT list
      this.videoSubtitles.push({
        lang: this.modelOutpuptTranslateSubtitleLanguage,
        uri: apiResponseObj.data.url,
      });
      this.videoSubtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1));

      this.$refs.btn_add_subtit.click();

      this.resetUploadSubtitFile();

      //update video obj
      await this.doUpdateVideo();

      //**************************** */

      //encoding video with VTT
      // START DISABLE
      // this.$root.addSnackBarMsg("Encoding VTT in progress", "info");

      // console.log("++ apiResponseObj:", apiResponseObj.data);

      // apiResponseObj = await EventService.encodingVTTJobID(
      //   apiResponseObj.data.jobId,
      //   this.modelSubtitleLanguage,
      //   this.$store.getters.getToken.token
      // );

      // this.resetUploadSubtitFile();

      // if (apiResponseObj.status != 200) {
      //   console.error("Encode >> error during encode", apiResponseObj.status);
      //   this.$root.addSnackBarMsg("ENCODE VTT: Error during encoding", "error");
      //   return;
      // }
      // END DISABLE
    },
    async encodeVideoVTT(jobId, language) {
      this.showLoader(true);
      this.$root.addSnackBarMsg("Encoding VTT in progress", "info");

      const apiResponseObj = await EventService.encodingVTTJobID(
        jobId,
        language,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        console.error("Encode >> error during encode", apiResponseObj.status);
        this.$root.addSnackBarMsg("ENCODE VTT: Error during encoding", "error");
        return;
      } else {
        this.$root.addSnackBarMsg("Encoder job done", "error");
      }
    },
    // *******************************
    // END ** Manage File Subtitles **
    // *******************************

    // ******************************
    // START ** Manage File Audios **
    // ******************************
    async deleteAudio(index) {
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this audio?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            //call code to delete
            this.callDeleteAudio(index);
          }
        });
    },
    async callDeleteAudio(index) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE AUDIO: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }

      //delete element from array
      console.log(
        "++ this.videoDeliverables_audio before delete:",
        this.videoDeliverables_audio
      );
      this.videoDeliverables_audio.splice(index, 1);
      console.log(
        "++ this.videoDeliverables_audio after delete:",
        this.videoDeliverables_audio
      );

      //update audioTracks array
      this.video.audioTracks = [];
      for (let i = 0; i < this.videoDeliverables_audio.length; i++) {
        this.video.audioTracks.push(this.videoDeliverables_audio[i].audioLanguage);
      }

      console.log("++ this.video.audioTracks:", this.video.audioTracks);
      this.$root.addSnackBarMsg("Audio deleted", "info");
      //update video obj
      await this.doUpdateVideo();
    },
    // async callDeleteAudio(index) {
    //   if (this.isLogged) {
    //     this.token = this.$store.getters.getToken.token;
    //   } else {
    //     this.$root.addSnackBarMsg(
    //       "DELETE AUDIO: An error has occurred, please try again",
    //       "error"
    //     );
    //     this.$store.dispatch("clearToken").then(() => {});
    //     this.$router.push({ name: "view-login" });
    //     return false;
    //   }
    //   this.showLoader(true);
    //   const options = {
    //     videoId: this.video.videoId,
    //     subtitlename: this.videoDeliverables_audio[index].uri,
    //   };
    //   const apiResponseObj = await EventService.deleteAudio(
    //     options,
    //     this.token
    //   );
    //   if (apiResponseObj.status === 200) {
    //     this.showLoader(false);
    //     //delete element from array
    //     this.videoDeliverables_audio.splice(index, 1);
    //     this.$root.addSnackBarMsg("Audio deleted", "info");
    //     //update video obj
    //     await this.doUpdateVideo();
    //   } else {
    //     this.showLoader(false);
    //     console.error(
    //       "Delete audio >> error during delete item",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "DELETE AUDIO: Error during deleting item",
    //       "error"
    //     );
    //   }
    // },
    handleAudioUpload(event) {
      this.audioFileObj = {};
      this.isUploadAudioReady = false;
      let tmpFile = event.target.files[0];
      console.log(`** audio tmpFile: ${tmpFile}`);
      if (!tmpFile) return;
      let tmpFileSize = tmpFile.size;
      let tmpFileType = tmpFile.type;
      console.log(`** audio: ${tmpFile.name} - ${tmpFileSize} - ${tmpFileType}`);
      if (!this.validAudioFileType(tmpFile)) {
        this.$root.addSnackBarMsg(
          `File name ${tmpFile.name}: Not a valid file type. Update your selection.`,
          "warning"
        );
        this.resetUploadAudioFile();
        return;
      }
      if (tmpFileSize === 0) {
        this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
        this.resetUploadAudioFile();
        return;
      }

      this.audioModelFile = `File name: ${tmpFile.name} - File size: ${this.formatBytes(
        tmpFile.size
      )}`;
      this.audioFileObj = tmpFile;
      this.file = tmpFile;
      this.audioFileSrc = URL.createObjectURL(this.audioFileObj);

      this.isUploadAudioReady = true;

      console.log("** audio to upload:", this.audioFileObj);
    },
    validAudioFileType(file) {
      console.log("** audio file type:", file.type);
      return this.audioFileTypes.includes(file.type);
    },
    resetUploadAudioFile() {
      this.audioFileObj = {};
      this.audioFileSrc = "";
      this.audioModelFile = "";
      this.modelAudioLanguage = "";
      //reset input file value
      this.requestUploadAudioFile = null;
      document.getElementById("audioFile").value = "";
      this.isUploadAudioReady = false;
      this.isProcessRunning = false;
      this.isUploadCompleted = false;
      this.uploadPercentage = 0;
      this.statusIndex = 0;
    },
    removeUploadAudioFromList() {
      this.resetUploadAudioFile();
      this.$root.addSnackBarMsg("Audio removed", "info");
    },
    createOctectHeader(token) {
      let apiAxios = null;
      apiAxios = axios.create({
        baseURL: this.BASEURL,
        headers: {
          "x-file-name": this.file.name,
          "x-file-lang": this.modelAudioLanguage,
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + token,
        },
      });
      return apiAxios;
    },
    async submitAudioFile() {
      this.isProcessRunning = true;
      this.statusIndex = 1;
      this.$root.addSnackBarMsg(this.upload_status[this.statusIndex].label, "info");

      this.requestUploadAudioFile = await this.uploadAudioFile(this.file);
      let output = { status: "", data: {} };
      this.showLoader(false);
      if (this.requestUploadAudioFile) {
        switch (this.requestUploadAudioFile.status) {
          case 200:
            output = { status: 200, data: this.requestUploadAudioFile };
            console.log("Upload file status >> 200 OK", output);
            this.sendToEncoding(output);
            break;
          case 403:
            output = { status: 403, data: this.requestUploadAudioFile };
            console.log("Upload file status >> 403 Forbidden", output);
            this.$root.addSnackBarMsg("UPLOAD AUDIO TRACK: Forbidden", "error");
            break;
          case 413:
            output = { status: 413, data: this.requestUploadAudioFile };
            console.log("Upload file status >> 413 Payload too large", output);
            this.$root.addSnackBarMsg("UPLOAD AUDIO TRACK: Payload too large", "error");
            break;
          default:
            output = { status: "error", data: this.requestUploadAudioFile };
            console.error("Upload file status >> Server error >>", output);
            this.$root.addSnackBarMsg("UPLOAD AUDIO TRACK: Server error", "error");
            break;
        }
      } else {
        console.log("Upload file status is " + this.requestUploadAudioFile);
      }
    },
    async uploadAudioFile(fileName) {
      this.showLoader(true);
      const isTokenValid = await EventService.checkToken();
      if (!isTokenValid) {
        return { status: "Invalid token" };
      }
      this.apiClientAxios = this.createOctectHeader(this.$store.getters.getToken.token);
      console.log(
        "++ uploadAudioFile, videoID, file to upload:",
        this.video.videoId,
        fileName
      );
      return await this.apiClientAxios
        .post("/Files/UploadAudio/" + this.video.videoId, fileName, {
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            console.log("Upload Progress: " + this.uploadPercentage + "%");
          }.bind(this),
        })
        .then(
          (response) => {
            console.log("API uploadFile >>", response);
            this.showLoader(false);
            return response;
          },
          (error) => {
            console.error("API uploadFile >> error >>", error.response);
            this.showLoader(false);
            return error.response;
          }
        );
    },
    async sendToEncoding(output) {
      console.log("++ process id to encode:", output.data.data.id);
      // Start encoding
      this.showLoader(true);
      this.statusIndex = 2;
      this.$root.addSnackBarMsg(this.upload_status[this.statusIndex].label, "info");
      document
        .getElementById("progress_upload")
        .classList.remove("progress-bar-striped", "progress-bar-animated");
      this.isUploadCompleted = true;
      const apiResponseObj = await EventService.encodingAudioJobID(
        output.data.data.id,
        this.modelAudioLanguage,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);

      this.resetUploadAudioFile();
      this.$refs.btn_add_audio.click();

      if (apiResponseObj.status != 200) {
        this.statusIndex = 4;
        console.error("Encode >> error during encode", apiResponseObj.status);
        this.$root.addSnackBarMsg("ENCODE: Error during encoding", "error");
        return;
      } else {
        this.$root.addSnackBarMsg("Audio is encoded", "info");

        this.checkIsNoAudioLanguage();
        this.checkNoMoreSubtitlesLanguage();

        //refresh page
        //this.$router.go();
        await this.getVideoDataFromAPI();
        // await this.doUpdateVideo();
      }
    },
    // async uploadAudioFile() {
    //   const formData = new FormData();
    //   formData.append("file", this.audioFileObj, this.audioFileObj.name);

    //   this.isChangesSaved[4] = false;
    //   this.showLoader(true);
    //   const apiResponseObj = await EventService.uploadAudio(
    //     formData,
    //     this.video.videoId,
    //     this.modelAudioLanguage,
    //     this.token
    //   );
    //   this.showLoader(false);

    //   if (apiResponseObj.status != 200) {
    //     console.error(
    //       "Upload file audio >> error during upload item",
    //       apiResponseObj.status
    //     );
    //     this.$root.addSnackBarMsg(
    //       "UPLOAD AUDIO: Error during uploading item",
    //       "error"
    //     );
    //     return;
    //   }

    //   this.$root.addSnackBarMsg("Audio uploaded", "info");
    //   console.log("*** upload audio response:", apiResponseObj);
    //   // add code to refresh array
    //   this.videoDeliverables_audio.push({
    //     lang: this.modelAudioLanguage,
    //     uri: apiResponseObj.data.url,
    //   });
    //   this.videoDeliverables_audio.sort((a, b) => (a.lang > b.lang ? 1 : -1));

    //   this.resetUploadAudioFile();
    //   this.showLoader(false);

    //   //update video obj
    //   await this.doUpdateVideo();

    //   this.$refs.btn_add_audio.click();
    // },
    // ****************************
    // END ** Manage File Audios **
    // ****************************

    // ***************************
    // START ** Tools & Utility **
    // ***************************
    copyTextToClipboard(textToCopy, what) {
      if (navigator.clipboard) {
        if (textToCopy == "" || textToCopy == null) {
          this.$root.addSnackBarMsg("There is nothing to copy", "warning");
          return;
        }
        navigator.clipboard.writeText(textToCopy).then(() => {
          this.$root.addSnackBarMsg(what + " copied to the clipboard", "info");
        });
      } else {
        this.$root.addSnackBarMsg(
          "This feature is available only in secure contexts (HTTPS)",
          "warning"
        );
        console.warn("This feature is available only in secure contexts (HTTPS)");
      }
    },
    formatDuration(duration) {
      let milliseconds = duration * 1000;
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      seconds = seconds % 60;
      minutes = minutes % 60;
      hours = hours % 24;
      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(
        minutes
      )}:${this.padTo2Digits(seconds)}`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },

    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
    convertUnixDate(date) {
      return CommonMethods.convertUnixTimeStampMoment(date);
    },

    getLanguageLabel(isoCode) {
      let langLabel = "Unknow Language";
      if (isoCode == "" || isoCode == undefined || isoCode == null) return langLabel;
      let element = this.languageISOCode.find((el) => el.isoCode === isoCode);
      if (element) {
        langLabel = element.language;
      }
      return langLabel;
    },
    returnFileSize(number) {
      if (number < 1024) {
        return `${number} bytes`;
      } else if (number >= 1024 && number < 1048576) {
        return `${(number / 1024).toFixed(1)} KB`;
      } else if (number >= 1048576) {
        return `${(number / 1048576).toFixed(1)} MB`;
      }
    },
    humanFileSize(size) {
      var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    getSubtitleLanguagesNotUsed(lang = "") {
      //clone object array
      let subtitleLanguagesNotUsed = JSON.parse(JSON.stringify(this.languageISOCode));
      let subtitleLanguages = JSON.parse(JSON.stringify(this.videoSubtitles));
      let filteredLang = subtitleLanguages;
      if (lang) {
        filteredLang = subtitleLanguages.filter((value) => {
          return value.lang != lang;
        });
      }
      for (let k = 0; k < filteredLang.length; k++) {
        for (let i = 0; i < subtitleLanguagesNotUsed.length; i++) {
          if (subtitleLanguagesNotUsed[i].isoCode == filteredLang[k].lang) {
            //delete element from array
            subtitleLanguagesNotUsed.splice(i, 1);
          }
        }
      }
      return subtitleLanguagesNotUsed;
    },
    getAudioLanguagesNotUsed(lang = "") {
      //clone object array
      let audioLanguagesNotUsed = JSON.parse(JSON.stringify(this.languageISOCode));
      let audioLanguages = JSON.parse(JSON.stringify(this.video.audioTracks));
      console.log("++ audioLanguages:", audioLanguages);
      let filteredLang = audioLanguages;
      if (lang) {
        filteredLang = audioLanguages.filter((value) => {
          return value != lang;
        });
      }
      for (let k = 0; k < filteredLang.length; k++) {
        for (let i = 0; i < audioLanguagesNotUsed.length; i++) {
          if (audioLanguagesNotUsed[i].isoCode == filteredLang[k]) {
            //delete element from array
            audioLanguagesNotUsed.splice(i, 1);
          }
        }
      }
      return audioLanguagesNotUsed;
    },
    getSubtitleLanguagesFromSubtitlesLanguage() {
      let returnSubtitlesLangISO = [];
      if (this.videoSubtitles) {
        returnSubtitlesLangISO = JSON.parse(JSON.stringify(this.languageISOCode));
        let subtitleLanguages = JSON.parse(JSON.stringify(this.videoSubtitles));
        for (let k = 0; k < subtitleLanguages.length; k++) {
          for (let i = 0; i < returnSubtitlesLangISO.length; i++) {
            if (returnSubtitlesLangISO[i].isoCode == subtitleLanguages[k].lang) {
              //delete element from array
              returnSubtitlesLangISO.splice(i, 1);
              break;
            }
          }
        }
      }
      return returnSubtitlesLangISO;
    },
    getActiveLanguagesFromSubtitlesLanguage() {
      let returnSubtitlesLangISO = [];
      if (this.videoSubtitles) {
        let subtitleLanguages = JSON.parse(JSON.stringify(this.videoSubtitles));
        for (let k = 0; k < subtitleLanguages.length; k++) {
          for (let i = 0; i < this.languageISOCode.length; i++) {
            if (this.languageISOCode[i].isoCode == subtitleLanguages[k].lang) {
              //add element
              returnSubtitlesLangISO.push(this.languageISOCode[i]);
              break;
            }
          }
        }
      }
      return returnSubtitlesLangISO;
    },

    getSubtitleLanguagesFromAudioLanguage() {
      let returnAudioLangISO = [];
      if (this.video.audioTracks) {
        let index = 0;
        let audioLanguagesISO = JSON.parse(JSON.stringify(this.languageISOCode));
        let audioLanguages = JSON.parse(JSON.stringify(this.video.audioTracks));
        let subtitleLanguages = JSON.parse(JSON.stringify(this.videoSubtitles));
        for (let k = 0; k < audioLanguages.length; k++) {
          index = audioLanguagesISO.findIndex(
            (element) => element.isoCode === audioLanguages[k]
          );
          if (index != -1) {
            //element found
            returnAudioLangISO.push(audioLanguagesISO[index]);
          } else {
            //element not found
            console.log("audioLanguages[k]", audioLanguages[k]);
          }
        }
        for (let k = 0; k < subtitleLanguages.length; k++) {
          for (let i = 0; i < returnAudioLangISO.length; i++) {
            if (returnAudioLangISO[i].isoCode == subtitleLanguages[k].lang) {
              //delete element from array
              returnAudioLangISO.splice(i, 1);
            }
          }
        }
      }
      return returnAudioLangISO;
    },
    checkNoMoreSubtitlesLanguage() {
      let lang = this.getSubtitleLanguagesFromSubtitlesLanguage();
      if (lang.length > 0) {
        this.isNoSubtitLanguage = false;
      } else {
        this.isNoSubtitLanguage = true;
      }
    },
    checkIsNoAudioLanguage() {
      this.isNoAudioLanguage = false;
      //togliere il controllo
      //
      // let lang = this.getSubtitleLanguagesFromAudioLanguage();
      // if (lang.length > 0) {
      //   this.isNoAudioLanguage = false;
      // } else {
      //   this.isNoAudioLanguage = true;
      // }
    },
    // **************************
    // END ** Tools & Utility **
    // **************************
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.isLoading = false;
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    //init changes saved
    this.initChangesSaved();
    //get video data
    this.getVideoDataFromAPI();
    //check if speech to text in progress
    this.checkEncodingSTTInProgress(true);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangesSaved.indexOf(false) > 0) {
      this.$swal
        .fire({
          title: "Data unsaved",
          html: "Do you really want to leave? You have unsaved changes!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      clearInterval(this.intervalEncodingSTTInProgress);
      next();
    }
  },
};
</script>

<style src="@/assets/css/multiselect.css"></style>
