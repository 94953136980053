<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-11 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              IMAGES
              <span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="zmdi zmdi-collection-image"></i>
                </span>
                {{ titleObj.name }}&nbsp;&nbsp;&nbsp;
                <span style="font-size: smaller"
                  >({{ formatNumber(totImages) }}
                  {{ getElementsLabel }})&nbsp;&nbsp;&nbsp;{{ pagesTitle() }}</span
                >
              </span>
            </h1>
          </div>
        </div>
        <div class="col-1 p-0">
          <!-- Search  -->
          <search-button
            @txtToSearch="searchText"
            @clearTxtToSearch="clearSearch"
            :isSearching="searching"
          />
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Linear Loader -->
        <div v-if="isLoading" class="linear-loader">
          <span></span>
        </div>
        <!-- Table List -->
        <h6 class="mt-1" v-if="images && images.length == 0 && !isLoading">
          No data to display
        </h6>
        <div class="row m-0 padding-x-4px">
          <table
            v-if="images && images.length > 0 && !isLoading"
            class="table-bordered table-responsive-md table-setup"
          >
            <thead class="table-header-font">
              <tr class="td-center">
                <th width="115">THUMBNAIL</th>
                <th>TITLE</th>
                <th>AREAS</th>
                <th>CATEGORIES</th>
                <th>TAGS</th>
                <th width="170">
                  CREATED
                  <br />MODIFIED
                </th>
                <th width="130">ACTIONS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <!-- Records content -->
              <record-pictures-list
                v-for="image in images"
                :key="image.id"
                :image="image"
                @editImage="editImage(image)"
                @deleteImage="deleteImage(image)"
                @openPopupImage="openPopupImage(image)"
              ></record-pictures-list>
            </tbody>
          </table>
          <!-- Pagination -->
          <div
            class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
          >
            <div class="row" v-if="images && images.length > 0 && getTotPages > 1">
              <vue-pagination
                :total-pages="getTotPages"
                :total="totImages"
                :per-page="maxPerPage"
                :current-page="currentPage"
                :maxVisibleButtons="6"
                @pagechanged="onPageChange"
              ></vue-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Image -->
    <div id="modalImg" class="modal-image-container">
      <span id="modalImgClose" class="modal-image-close">&times;</span>
      <img class="modal-image-content border-mainblue pt-checker" id="imageModal" />
      <div id="modalImgCaption" class="modal-image-caption"></div>
    </div>
  </div>
</template>

<script>
import SearchButton from "@/components/core/SearchButton.vue";
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";
import RecordPicturesList from "@/components/core/RecordPicturesList.vue";
import VuePagination from "@/components/ui/VuePagination.vue";

export default {
  components: { SearchButton, RecordPicturesList, VuePagination },
  data() {
    return {
      titleObj: {},
      images: [],
      image: {},
      paginatedImages: [],
      isLoading: true,
      currentPage: 1,
      apiPage: 0,
      maxPerPage: 20,
      totImages: 0,
      showDialog: false,
      searching: false,
      textToSearch: "",
      spinnerLoader: null,
      isCreated: false,
      localStorageName: process.env.VUE_APP_LOCALSTORAGE_PICTURE,
    };
  },
  computed: {
    getTotPages() {
      let totPages = 0;
      const a = this.totImages / this.maxPerPage;
      const b = a - Math.floor(a);
      if (b == 0) {
        totPages = a;
      } else {
        totPages = Math.floor(a) + 1;
      }
      console.log("** pages:", totPages);
      return totPages;
    },
    showReadMore() {
      console.log(
        "this.image.length < this.totImages",
        this.images.length < this.totImages
      );
      console.log("this.image.length,this.totImages", this.images.length, this.totImages);
      return this.images.length < this.totImages;
    },
    sort() {
      return this.$store.getters.getPicturesOrder;
    },
    filterCategories() {
      return this.$store.getters.getFilterCategories;
    },
    filterTags() {
      return this.$store.getters.getFilterTags;
    },
    filterAreas() {
      return this.$store.getters.getFilterAreas;
    },
    getElementsLabel() {
      return this.totImages == 1 ? "element" : "elements";
    },
  },
  watch: {
    sort() {
      this.setSortOrder();
    },
    filterCategories() {
      if (this.isCreated && !this.isLoading) this.setFilters();
    },
    filterTags() {
      if (this.isCreated && !this.isLoading) this.setFilters();
    },
    filterAreas() {
      if (this.isCreated && !this.isLoading) this.setFilters();
    },
  },
  methods: {
    pagesTitle() {
      let pageLabel = "";
      if (this.images && this.images.length > 0 && this.getTotPages > 1) {
        pageLabel = `(Page ${this.currentPage}/${this.getTotPages})`;
      }
      return pageLabel;
    },
    searchText(textToSearch) {
      if (textToSearch == "") return false;
      this.searching = true;
      this.updateLSObjectParams("searching", this.searching);
      this.textToSearch = textToSearch;
      this.updateLSObjectParams("textToSearch", this.textToSearch);

      this.updateLSObjectParams("currentPage", 1);
      this.updateLSObjectParams("apiPage", 0);

      this.resetVariables();
      this.titleObj = this.getTitle();
      this.getPaginatedImages();
    },
    clearSearch() {
      this.searching = false;
      this.updateLSObjectParams("searching", this.searching);
      this.textToSearch = "";
      this.updateLSObjectParams("textToSearch", this.textToSearch);

      this.updateLSObjectParams("currentPage", 1);
      this.updateLSObjectParams("apiPage", 0);

      this.titleObj = this.getTitle();
      this.resetVariables();
      this.getPaginatedImages();
    },
    onPageChange(page) {
      this.currentPage = page;
      this.updateLSObjectParams("currentPage", this.currentPage);
      this.apiPage = this.currentPage - 1;
      this.updateLSObjectParams("apiPage", this.apiPage);
      this.getPaginatedImages();
    },
    editImage(image) {
      this.$router.push({
        name: "view-edit-picture",
        params: {
          id: image.imageId,
        },
      });
    },
    openPopupImage(image) {
      let modal = document.getElementById("modalImg");
      let img = image;
      let modalImg = document.getElementById("imageModal");
      let captionText = document.getElementById("modalImgCaption");

      modal.style.display = "block";
      modalImg.src = img.originalImageUri;
      captionText.innerHTML = img.originalImageUri;

      let span = document.getElementById("modalImgClose");
      span.onclick = () => {
        modal.style.display = "none";
      };
      modalImg.onclick = () => {
        modal.style.display = "none";
      };
      modal.onclick = () => {
        modal.style.display = "none";
      };
      window.onkeydown = (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
          modal.style.display = "none";
        }
      };
    },
    deleteImage(image) {
      console.log("*** delete image:", image.imageId);
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this image?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.callDeleteImage(image.imageId);
          }
        });
    },
    async callDeleteImage(imageId) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE IMAGE: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.showLoader(true);

      const apiResponseObj = await EventService.deleteImage(imageId, this.token);
      if (
        apiResponseObj.status === 200 &&
        !apiResponseObj.data.toLowerCase().includes("error")
      ) {
        this.showLoader(false);
        this.$root.addSnackBarMsg("Image deleted", "info");
        //update list images
        this.images = [];
        this.paginatedImages = [];
        this.isLoading = true;
        this.currentPage = 1;
        this.updateLSObjectParams("currentPage", this.currentPage);
        this.apiPage = 0;
        this.updateLSObjectParams("apiPage", this.apiPage);
        this.totImages = 0;
        this.getPaginatedImages();
      } else {
        this.showLoader(false);
        console.error("Delete image >> error during delete item", apiResponseObj.status);
        this.$root.addSnackBarMsg("DELETE IMAGE: Error during delete", "error");
      }
    },
    setSortOrder() {
      this.resetVariables();
      this.titleObj = this.getTitle();
      this.getPaginatedImages();
    },
    setFilters() {
      this.resetVariables();
      this.titleObj = this.getTitle();
      this.getPaginatedImages();
    },
    resetVariables() {
      this.images = [];
      this.image = {};
      this.paginatedImages = [];
      this.isLoading = true;
      this.currentPage = 1;
      this.apiPage = 0;
      this.totImages = 0;
    },
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
    getTitle() {
      const all = "All";
      const aCategories = this.$store.getters.getFilterCategories;
      const aTags = this.$store.getters.getFilterTags;
      const aAreas = this.$store.getters.getFilterAreas;

      this.initSearchElement();

      if (
        this.textToSearch == "" &&
        aCategories.length == 0 &&
        aTags.length == 0 &&
        aAreas.length == 0
      ) {
        return { name: all };
      }
      // concatenate attribute
      // let attributesConcat = aAreas.concat(aCategories).concat(aTags);
      let attributesConcat = [...aAreas, ...aCategories, ...aTags];
      let strAttributesName = Array.prototype.map
        .call(attributesConcat, (item) => {
          return item.name;
        })
        .join(", ");
      // if search
      if (this.searching) {
        if (strAttributesName == "") {
          strAttributesName = "'" + this.textToSearch + "'";
        } else {
          strAttributesName = "'" + this.textToSearch + "'" + ", " + strAttributesName;
        }
      }
      return { name: strAttributesName };
    },
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    viewOptions() {
      this.initNavigationElement();
      const page = this.apiPage;
      const size = this.maxPerPage;
      const sort = this.sort;
      // concatenate attributes
      const attributes = this.concatenateAttributes();
      const options = {
        page: page,
        size: size,
        sort: sort,
        attributes: attributes,
      };
      return options;
    },
    viewOptionsSearch() {
      this.initNavigationElement();
      const page = this.apiPage;
      const size = this.maxPerPage;
      const sort = this.sort.toString();
      // get all array attributes
      const attributes = this.arrayAllAttributes();
      const options = {
        page: page,
        size: size,
        sort: sort,
        attributes: attributes,
        textToSearch: this.textToSearch,
      };
      return options;
    },
    concatenateAttributes() {
      const aCategories = this.$store.getters.getFilterCategories;
      const aTags = this.$store.getters.getFilterTags;
      const aAreas = this.$store.getters.getFilterAreas;
      let attributes = "";
      if (aCategories.length == 0 && aTags.length == 0 && aAreas.length == 0) {
        return attributes;
      }
      if (aAreas.length > 0) {
        for (let i = 0; i < aAreas.length; i++) {
          attributes = attributes + "&attributes=" + aAreas[i].id;
        }
        console.log("** aAreas attributes:", attributes);
      }
      if (aCategories.length > 0) {
        for (let i = 0; i < aCategories.length; i++) {
          attributes = attributes + "&attributes=" + aCategories[i].id;
        }
        console.log("** aCategories attributes:", attributes);
      }
      if (aTags.length > 0) {
        for (let i = 0; i < aTags.length; i++) {
          attributes = attributes + "&attributes=" + aTags[i].id;
        }
        console.log("** aTags attributes:", attributes);
      }
      console.log("** all attributes:", attributes);
      return attributes;
    },
    arrayAllAttributes() {
      const aCategories = this.$store.getters.getFilterCategories;
      const aTags = this.$store.getters.getFilterTags;
      const aAreas = this.$store.getters.getFilterAreas;
      let attributes = [];
      if (aCategories.length == 0 && aTags.length == 0 && aAreas.length == 0) {
        return attributes;
      }
      // let attributesConcat = aAreas.concat(aCategories).concat(aTags);
      let attributesConcat = [...aAreas, ...aCategories, ...aTags];
      for (let i = 0; i < attributesConcat.length; i++) {
        attributes.push(attributesConcat[i].id);
      }
      console.log("** all attributes in search:", attributes);
      return attributes;
    },
    async getImages(options) {
      let images = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET IMAGES: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;
      const apiResponseObj = await EventService.getAllImagesConsoleByAttributes(
        options,
        this.token
      );

      console.log("List all images >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        if (apiResponseObj.data.totImages > 0) {
          this.totImages = apiResponseObj.data.totImages;
          if (this.totImages == 0) {
            images = [];
          } else {
            images = apiResponseObj.data.images;
          }
        }
      } else {
        this.totImages = 0;
        this.isLoading = false;
        console.error("List images >> error during loading data");
        this.$root.addSnackBarMsg("GET IMAGES: Error during loading data", "error");
      }
      return images;
    },
    async getImageSearch(options) {
      let images = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET IMAGES: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;

      const apiResponseObj = await EventService.getSearchedConsoleImagesByAttributes(
        options,
        this.token
      );
      console.log("List all images >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        if (apiResponseObj.data.totImages > 0) {
          this.totImages = apiResponseObj.data.totImages;
          if (this.totImages == 0) {
            images = [];
          } else {
            images = apiResponseObj.data.images;
          }
        }
      } else {
        this.totImages = 0;
        this.isLoading = false;
        console.error("List images >> error during loading data");
        this.$root.addSnackBarMsg("GET IMAGES: Error during loading data", "error");
      }
      return images;
    },
    async getPaginatedImages() {
      this.isLoading = true;
      let sendOptions = null;

      this.initSearchElement();

      if (this.searching) {
        sendOptions = this.viewOptionsSearch();
        this.paginatedImages = await this.getImageSearch(sendOptions);
      } else {
        sendOptions = this.viewOptions();
        this.paginatedImages = await this.getImages(sendOptions);
      }

      if (this.paginatedImages.length != undefined) {
        //empty images array
        this.images = [];
        //push images
        this.images.push(...this.paginatedImages);
        return this.images;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
    loadLSObject() {
      return CommonMethods.loadLSObjectParam(this.localStorageName);
    },
    initFilters() {
      const objLS = this.loadLSObject();
      //get filtered areas
      if (objLS.filterAreas) {
        this.$store.dispatch("setFilterAreas", objLS.filterAreas);
      }
      //get filtered categories
      if (objLS.filterCategories) {
        this.$store.dispatch("setFilterCategories", objLS.filterCategories);
      }
      //get filtered tags
      if (objLS.filterTags) {
        this.$store.dispatch("setFilterTags", objLS.filterTags);
      }
    },
    initNavigationElement() {
      const objLS = this.loadLSObject();
      //get apiPage
      if (objLS.apiPage) {
        this.apiPage = objLS.apiPage;
      } else {
        this.apiPage = 0;
      }
      //get currentPage
      if (objLS.currentPage) {
        this.currentPage = objLS.currentPage;
      } else {
        this.currentPage = 1;
      }
      //get sort
      if (objLS.sort) {
        this.$store.dispatch("setPicturesOrder", objLS.sort);
      }
    },
    initSearchElement() {
      this.initSearchingElement();
      this.initTextToSearchElement();
    },
    initSearchingElement() {
      const objLS = this.loadLSObject();
      //get searching
      if (objLS.searching) {
        this.searching = objLS.searching;
      } else {
        this.searching = false;
      }
    },
    initTextToSearchElement() {
      const objLS = this.loadLSObject();
      //get textToSearch
      if (objLS.textToSearch) {
        this.textToSearch = objLS.textToSearch;
      } else {
        this.textToSearch = "";
      }
    },
    updateLSObjectParams(key, value) {
      CommonMethods.updateLSKeyObjectParam(this.localStorageName, key, value);
    },
  },
  async created() {
    this.isCreated = false;
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }

    this.titleObj = this.getTitle();
    console.log("** titleObj", this.titleObj);

    //get images
    await this.getPaginatedImages();

    // load localStorage Picture filters
    this.initFilters();

    this.isCreated = true;
  },
};
</script>
