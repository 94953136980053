<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              MONITORING
              <span class="text-white small">
                <span class="mx-3">
                  <i class="zmdi zmdi-flash"></i>
                </span>Encoding Archive -&nbsp;
                <span
                  style="font-size: smaller"
                >({{ formatNumber(totJobs) }} {{ getElementsLabel }})</span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Linear Loader -->
        <div v-if="isLoading" class="linear-loader">
          <span></span>
        </div>
        <h6
          class="mt-1"
          v-if="archiveList && archiveList.length == 0 && !isLoading"
        >No data to display</h6>
        <!-- Table List -->
        <div class="row m-0 padding-x-4px">
          <table
            v-if="archiveList && archiveList.length > 0"
            class="table-bordered table-responsive-md table-setup"
          >
            <thead class="table-header-font">
              <tr class="td-center">
                <th>FILE NAME</th>
                <th width="15%">CREATED</th>
                <th width="20%">TYPE</th>
                <th width="15%">STATUS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <tr v-for="(archiveItem, index) in archiveList" :key="index">
                <!-- FileName -->
                <td>{{ archiveItem.fileName }}</td>
                <!-- Created & Ended -->
                <td class="td-center">
                  <ul class="unstyled">
                    <li>{{ convertUnixDate(archiveItem.creationDateUnixTimeStamp) }}</li>
                  </ul>
                </td>
                <!-- Type -->
                <td class="td-center">
                  <ul class="list-tags">
                    <li>
                      <span
                        v-if="archiveItem.isOnlyAudio"
                        class="badge-colored green"
                        title="AUDIO"
                        alt="AUDIO"
                      >AUDIO</span>
                      <span
                        v-if="!archiveItem.isOnlyAudio && !archiveItem.isImage"
                        class="badge-colored fucsia"
                        title="VIDEO"
                        alt="VIDEO"
                      >VIDEO</span>
                      <span
                        v-if="archiveItem.isImage"
                        class="badge-colored blue"
                        title="IMAGE"
                        alt="IMAGE"
                      >IMAGE</span>
                    </li>
                  </ul>
                </td>
                <!-- Status -->
                <td class="td-center" v-html="getStatus(archiveItem.status)"></td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination -->
          <div class="align-items-center container-fluid d-flex justify-content-center my-4 p-0">
            <div class="row" v-if="archiveList && archiveList.length > 0 && getTotPages>1">
              <vue-pagination
                :total-pages="getTotPages"
                :total="totJobs"
                :per-page="maxPerPage"
                :current-page="currentPage"
                :maxVisibleButtons="6"
                @pagechanged="onPageChange"
              ></vue-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";
import VuePagination from "@/components/ui/VuePagination.vue";

export default {
  components: { VuePagination },
  data() {
    return {
      isLoading: false,
      archiveList: [],
      paginatedMonitorArchive: [],
      currentPage: 1,
      apiPage: 0,
      maxPerPage: 20,
      totJobs: 0,
      statusValues: [
        { label: "encoding started", class: "color-info" },
        { label: "encoder job done", class: "color-success" },
        { label: "encoding ended with error", class: "color-error" },
      ],
    };
  },
  computed: {
    getTotPages() {
      let totPages = 0;
      const a = this.totJobs / this.maxPerPage;
      const b = a - Math.floor(a);
      if (b == 0) {
        totPages = a;
      } else {
        totPages = Math.floor(a) + 1;
      }
      console.log("** pages:", totPages);
      return totPages;
    },
    getElementsLabel() {
      return this.totJobs == 1 ? "element" : "elements";
    },
  },
  methods: {
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
    onPageChange(page) {
      this.currentPage = page;
      this.apiPage = this.currentPage - 1;
      this.getPaginatedMonitorArchive();
    },
    convertUnixDate(date) {
      return CommonMethods.convertUnixTimeStampMoment(date);
    },
    getStatus(status) {
      let statusStr = "-";
      let findStatus = this.statusValues.find((el) => el.label == status);
      if (findStatus != undefined) {
        statusStr = `<span class='bold ${findStatus.class}'>${
          status.charAt(0).toUpperCase() + status.substring(1)
        }</span>`;
        return statusStr;
      } else {
        return statusStr;
      }
    },
    async getPaginatedMonitorArchive() {
      this.isLoading = true;

      let sendOptions = null;
      sendOptions = this.viewOptions(this.apiPage);
      this.paginatedMonitorArchive = await this.getMonitorArchive(sendOptions);
      if (this.paginatedMonitorArchive.length != undefined) {
        //empty archiveList array
        this.archiveList = [];
        //push jobs
        this.archiveList.push(...this.paginatedMonitorArchive);
        return this.archiveList;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    async getMonitorArchive(options) {
      let jobs = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET ARCHIVE: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;
      const apiResponseObj = await EventService.getAllJobsArchive(
        options,
        this.token
      );
      console.log("List all jobs >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        if (apiResponseObj.data.totJobs > 0) {
          this.totJobs = apiResponseObj.data.totJobs;
          if (this.totJobs == 0) {
            jobs = [];
          } else {
            jobs = apiResponseObj.data.jobs;
          }
        }
      } else {
        this.totJobs = 0;
        this.isLoading = false;
        console.error("List jobs >> error during loading data");
        this.$root.addSnackBarMsg(
          "GET VIDEOS: Error during loading data",
          "error"
        );
      }
      return jobs;
    },
    viewOptions(page) {
      const size = this.maxPerPage;
      const options = {
        page: page,
        size: size,
      };
      return options;
    },
    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }

    this.getPaginatedMonitorArchive();
  },
};
</script>
