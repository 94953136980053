<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-11 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              USERS
              <span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="zmdi zmdi-accounts"></i>
                </span>
                {{ titleObj.name }}&nbsp;&nbsp;&nbsp;
                <span style="font-size: smaller"
                  >({{ formatNumber(totUsers) }} {{ getElementsLabel }})</span
                >
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Linear Loader -->
        <div v-if="isLoading" class="linear-loader">
          <span></span>
        </div>
        <!-- Table List -->
        <h6 class="mt-1" v-if="users && users.length == 0 && !isLoading">
          No data to display
        </h6>
        <div class="row m-0 padding-x-4px">
          <table
            v-if="users && users.length > 0"
            class="table-bordered table-responsive-md table-setup"
          >
            <thead class="table-header-font">
              <tr class="td-center">
                <th>FIRST NAME</th>
                <th>LAST NAME</th>
                <th>USER NAME</th>
                <th>EMAIL</th>
                <th v-if="isEmailVerified" class="td-center">EMAIL VERIFIED</th>
                <th>PRIVILEGES</th>
                <th width="80">ACTIONS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <!-- Records content -->
              <record-users-list
                v-for="user in users"
                :key="user.id"
                :user="user"
                :myUserId="myUserId"
                @editUser="editUser(user)"
                @deleteUser="deleteUser(user)"
              ></record-users-list>
            </tbody>
          </table>
          <!-- Pagination -->
          <div
            class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
          >
            <div class="row" v-if="users && users.length > 0 && getTotPages > 1">
              <vue-pagination
                :total-pages="getTotPages"
                :total="totUsers"
                :per-page="maxPerPage"
                :current-page="currentPage"
                :maxVisibleButtons="6"
                @pagechanged="onPageChange"
              ></vue-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";
import RecordUsersList from "@/components/core/RecordUsersList.vue";
import VuePagination from "@/components/ui/VuePagination.vue";

export default {
  components: { RecordUsersList, VuePagination },
  data() {
    return {
      titleObj: {},
      users: [],
      user: {},
      paginatedUsers: [],
      isLoading: true,
      currentPage: 1,
      apiPage: 0,
      maxPerPage: 20,
      totUsers: 0,
      showDialog: false,
      userType: null,
      userTypes: [
        { type: "users", label: "Users", tag: "USER", isAdmin: false, isAdminStr: "N" },
        { type: "admins", label: "Admins", tag: "ADMIN", isAdmin: true, isAdminStr: "Y" },
      ],
      spinnerLoader: null,
      isEmailVerified: false,
      myUserId:null
    };
  },
  computed: {
    getTotPages() {
      let totPages = 0;
      const a = this.totUsers / this.maxPerPage;
      const b = a - Math.floor(a);
      if (b == 0) {
        totPages = a;
      } else {
        totPages = Math.floor(a) + 1;
      }
      console.log("** pages:", totPages);
      return totPages;
    },
    getElementsLabel() {
      return this.totUsers == 1 ? "element" : "elements";
    },
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.apiPage = this.currentPage - 1;
      this.getPaginatedUsers();
    },
    editUser(user) {
      this.$router.push({
        name: "view-edit-user",
        params: {
          id: user.providerUserId,
        },
      });
    },
    deleteUser(user) {
      console.log("*** delete user:", user.providerUserId);
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this user?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.callDeleteUser(user.providerUserId);
          }
        });
    },
    async callDeleteUser(userId) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE USER: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }

      this.showLoader(true);
      const apiResponseObj = await EventService.deleteUser(userId, this.token);
      this.showLoader(false);

      if (
        apiResponseObj.status === 200 &&
        !apiResponseObj.data.toLowerCase().includes("error")
      ) {
        this.$root.addSnackBarMsg("User deleted", "info");
        //update list users
        this.users = [];
        this.paginatedUsers = [];
        this.isLoading = true;
        this.currentPage = 1;
        this.apiPage = 0;
        this.totUsers = 0;
        this.getPaginatedUsers();
      } else {
        console.error("Delete user >> error during delete item", apiResponseObj.status);
        this.$root.addSnackBarMsg(
          "DELETE USER: An error has occurred, please try again",
          "error"
        );
      }
    },
    resetVariables() {
      this.users = [];
      this.user = {};
      this.paginatedUsers = [];
      this.isLoading = true;
      this.currentPage = 1;
      this.apiPage = 0;
      this.totUsers = 0;
    },
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
    getTitle() {
      const userTypeId = this.$route.params.id;
      const tempElement = this.foundElementInArrayById(
        "type",
        userTypeId,
        this.userTypes
      );
      if (tempElement != undefined) {
        return { name: tempElement.label };
      } else {
        console.log("** error, no user type found");
        this.$root.addSnackBarMsg("GET USERS: No users type found", "error");
        return { name: "* User type not found *" };
      }
    },
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    viewOptions(page) {
      const userTypeId = this.$route.params.id;
      this.userType = userTypeId;
      let isAdmin = "N";
      const tempElement = this.foundElementInArrayById(
        "type",
        userTypeId,
        this.userTypes
      );
      if (tempElement != undefined) {
        isAdmin = tempElement.isAdminStr;
      } else {
        return false;
      }
      const size = this.maxPerPage;
      const options = {
        page: page,
        size: size,
        text: "",
        isAdmin: isAdmin,
      };
      return options;
    },
    async getUsers(options) {
      let users = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET USERS: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;
      const apiResponseObj = await EventService.getAllUsers(options, this.token);
      console.log("List all users >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        if (apiResponseObj.data.totalUsers > 0) {
          this.totUsers = apiResponseObj.data.totalUsers;
          if (this.totUsers == 0) {
            users = [];
          } else {
            users = apiResponseObj.data.users;
          }
        }
      } else {
        this.totUsers = 0;
        this.isLoading = false;
        console.error("List users >> error during loading data");
        this.$root.addSnackBarMsg("GET USERS: Error during loading data", "error");
      }
      return users;
    },
    async getPaginatedUsers() {
      //Get users from API
      this.isLoading = true;
      //await CommonMethods.sleep(3000);
      let sendOptions = null;
      sendOptions = this.viewOptions(this.apiPage);
      if (!sendOptions) {
        this.isLoading = false;
        return false;
      }
      this.paginatedUsers = await this.getUsers(sendOptions);
      if (this.paginatedUsers.length != undefined) {
        //empty users array
        this.users = [];
        //push users
        this.users.push(...this.paginatedUsers);
        return this.users;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    this.titleObj = this.getTitle();
    console.log("** titleObj", this.titleObj);
    if (this.titleObj != undefined) {
      if (
        this.$store.getters.getToken.providerUserId == null ||
        this.$store.getters.getToken.providerUserId == undefined
      ) {
        this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
        return false;
      }
      this.myUserId = this.$store.getters.getToken.providerUserId;  
      this.getPaginatedUsers();
    } else {
      this.$router.push({
        name: "page-not-found",
        params: { notFound: "404" },
      });
    }
  },
};
</script>
