<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              SETTINGS
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-settings"></i> </span>Protection
                Rules
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray">
        <!-- Domain 1 -->
        <div class="row p-0 m-0 my-3">
          <div class="align-items-center d-flex flex-row col-lg-3">
            <div class="form-label">DOMAIN</div>
          </div>
          <div class="align-items-center col-lg-9 d-flex flex-row">
            <input
              type="text"
              class="form-control"
              placeholder="Domain"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              v-model="model_domain_1"
            />
          </div>
        </div>
        <!-- Referrer 1 -->
        <div class="row p-0 m-0 my-3">
          <div class="align-items-top d-flex flex-row col-lg-3">
            <div class="form-label">REFERRER</div>
          </div>
          <div class="align-items-center col-lg-9 d-flex flex-row">
            <textarea
              wrap="soft"
              class="form-control"
              placeholder="Referrer"
              rows="12"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              v-model="model_referrer_1"
            ></textarea>
          </div>
        </div>
        <hr class="sepGray mt-4" />
        <!-- Save Settings -->
        <div class="container-fluid p-0 pt-3">
          <button
            @click="saveSettings()"
            type="button"
            class="btn button-outline-1 mb-3 float-end"
          >
            <span> <i class="zmdi zmdi-floppy"></i>Save Settings </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";

export default {
  components: {},
  data() {
    return {
      model_domain_1: "",
      model_referrer_1: "",
      model_domain_2: "",
      model_referrer_2: "",
      spinnerLoader: null,
    };
  },
  methods: {
    // isValidHttpUrl(string) {
    //   try {
    //     const url = new URL(string);
    //     return url.protocol === "http:" || url.protocol === "https:";
    //   } catch (err) {
    //     return false;
    //   }
    // },
    isValidHttpUrl(str) {
      let givenURL;
      try {
        givenURL = new URL(str);
        console.log("++ givenURL", givenURL);
      } catch (error) {
        console.log("error is", error);
        return false;
      }
    },

    // console.log(isValidHttpUrl('https://codingbeautydev.com')); // true
    // console.log(isValidHttpUrl('app://codingbeautydev.com')); // false
    // console.log(isValidHttpUrl('Coding Beauty')); // false

    validationFields() {
      console.log(this.isValidHttpUrl(this.model_domain_1));
      if (this.isValidHttpUrl(this.model_domain_1) == false) {
        this.$root.addSnackBarMsg("DOMAIN: It's not a valid HTTP url", "warning");
        return false;
      }
      return true;
    },
    saveSettings() {
      if (!this.validationFields()) return false;
      console.log("++ this.model_domain_1:", this.model_domain_1);
      const bodyObj = {
        name: this.model_domain_1,
        referrer: this.model_referrer_1,
      };
      this.putSettingsProtectionRule(bodyObj);
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
    async getSettingsProtectionRule() {
      this.showLoader(true);
      const awaitSettings = await EventService.getSettingsProtectionRule(
        this.$store.getters.getToken.token
      );

      if (awaitSettings.status != 200) {
        console.error("++ Settings, protection rule: error during loading data");
        this.$root.addSnackBarMsg(
          "GET PROTECTION RULES SETTING: An error has occurred, please try again",
          "error"
        );
        this.showLoader(false);
        return;
      }
      this.showLoader(false);
      console.log("++ protection rules settings:", awaitSettings.data);

      this.model_domain_1 = awaitSettings.data.name;
      this.model_referrer_1 = awaitSettings.data.referrer;
    },
    async putSettingsProtectionRule(bodyObj) {
      this.showLoader(true);
      const awaitSettings = await EventService.putSettingsProtectionRule(
        bodyObj,
        this.$store.getters.getToken.token
      );

      if (awaitSettings.status != 200) {
        console.error("++ Settings, protection rule: error during saving data");
        this.$root.addSnackBarMsg(
          "SAVE PROTECTION RULES SETTING: An error has occurred, please try again",
          "error"
        );
        this.showLoader(false);
        return;
      }
      this.showLoader(false);
      this.$root.addSnackBarMsg("The data was updated successfully", "info");
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    this.getSettingsProtectionRule();
  },
};
</script>
