<template>
  <tr>
    <!-- Thumbnail -->
    <td class="td-center w100-px">
      <img
        @click.prevent="$emit('openPopupImage')"
        :src="getThumbnail()"
        class="img-fluid thumb pt-checker"
        style="cursor: pointer"
        title="OPEN"
      />
    </td>
    <!-- Title -->
    <td>{{ getTitleAuthor().title }}</td>
    <!-- Areas -->
    <td class="td-center">
      <ul class="list-tags">
        <li v-for="(dataElement, index) in getAreas()" :key="index">
          <span
            v-if="dataElement != null"
            class="badge-colored gray1 truncate-15-ch"
            :title="dataElement"
            :alt="dataElement"
            >{{ dataElement }}</span
          >
        </li>
      </ul>
    </td>
    <!-- Categories -->
    <td class="td-center">
      <ul class="list-tags">
        <li v-for="(dataElement, index) in getCategories()" :key="index">
          <span
            v-if="dataElement != null"
            class="badge-colored gray1 truncate-15-ch"
            :title="dataElement"
            :alt="dataElement"
            >{{ dataElement }}</span
          >
        </li>
      </ul>
    </td>
    <!-- Tags -->
    <td class="td-center">
      <ul class="list-tags">
        <li v-for="(dataElement, index) in getTags()" :key="index">
          <span
            v-if="dataElement != null"
            class="badge-colored gray1 truncate-15-ch"
            :title="dataElement"
            :alt="dataElement"
            >{{ dataElement }}</span
          >
        </li>
      </ul>
    </td>
    <!-- Created on & Published On -->
    <td class="td-center">
      <ul class="unstyled">
        <li>{{ convertUnixDate(image.creationDateUnixTimeStamp) }}</li>
        <li>{{ convertUnixDate(image.modificationDateUnixTimeStamp) }}</li>
      </ul>
    </td>
    <!-- Actions -->
    <td class="td-actions td-center">
      <button alt="EDIT" title="EDIT" @click="$emit('editImage')">
        <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
      </button>
      <button alt="DELETE" title="DELETE" @click="$emit('deleteImage')">
        <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
      </button>
      <button
        @click="copyTextToClipboard(image.guid, 'GUID')"
        alt="COPY GUID"
        title="COPY GUID"
      >
        <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import CommonMethods from "@/services/CommonMethods";
// import CommonMethods from "@/services/CommonMethods.js";

const METADATA_DEFAULT_LANG = process.env.VUE_APP_DEFAULT_METADATA_LANG;

export default {
  emits: ["editImage", "deleteImage", "openPopupImage"],
  props: {
    image: { type: Object, required: true },
  },
  components: {},
  data() {
    return {
      placeholder: require("@/assets/images/image-placeholder.png"),
    };
  },
  computed: {},
  methods: {
    getThumbnail() {
      let thumbnail = this.placeholder;
      if (this.image.thumbImageUri) {
        thumbnail = this.image.thumbImageUri;
      }
      return thumbnail;
    },
    getTitleAuthor() {
      const node_metadata = this.image.metadata;
      let nodeLangArray = [];
      let metadata = { title: "", author: "" };
      if (Object.keys(node_metadata).length > 0) {
        node_metadata.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            if (element.metaDataDetails.title != "") {
              nodeLangArray.push({
                title: element.metaDataDetails.title,
                author: element.metaDataDetails.author,
              });
            }
          }
        });
        if (nodeLangArray.length > 0) {
          metadata = {
            title: nodeLangArray[0].title,
            author: nodeLangArray[0].author,
          };
        }
      }
      return metadata;
    },
    getTitle() {
      const node_metadata = this.image.metadata;
      let nodeLangArray = [];
      let title = "";
      if (Object.keys(node_metadata).length > 0) {
        node_metadata.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            if (element.metaDataDetails.title != "") {
              nodeLangArray.push(element.metaDataDetails.title);
            }
          }
        });
        for (let i = 0; i < nodeLangArray.length; i++) {
          if (nodeLangArray[i] != "") {
            title = nodeLangArray[i];
            break;
          }
        }
      }
      return title;
    },
    getAuthor() {
      const node_metadata = this.image.metadata;
      let author = "";
      if (Object.keys(node_metadata).length > 0) {
        node_metadata.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            author = element.metaDataDetails.author;
          }
        });
      }
      return author;
    },
    getCategories() {
      const node_categories = this.image.categories;
      let categories = [];
      if (Object.keys(node_categories).length > 0) {
        node_categories.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            categories.push(element.name);
          }
        });
      }
      return categories;
    },
    getTags() {
      const node_tags = this.image.tags;
      let tags = [];
      if (Object.keys(node_tags).length > 0) {
        node_tags.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            tags.push(element.name);
          }
        });
      }
      return tags;
    },
    getAreas() {
      const node_areas = this.image.areas;
      let areas = [];
      if (Object.keys(node_areas).length > 0) {
        node_areas.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            areas.push(element.name);
          }
        });
      }
      return areas;
    },
    convertUnixDate(date) {
      return CommonMethods.convertUnixTimeStampMoment(date);
    },
    copyTextToClipboard(textToCopy, what) {
      if (navigator.clipboard) {
        if (textToCopy == "" || textToCopy == null) {
          this.$root.addSnackBarMsg("There is nothing to copy", "warning");
          return;
        }
        navigator.clipboard.writeText(textToCopy).then(() => {
          this.$root.addSnackBarMsg(what + " copied to the clipboard", "info");
        });
      } else {
        this.$root.addSnackBarMsg(
          "This feature is available only in secure contexts (HTTPS)",
          "warning"
        );
        console.warn("This feature is available only in secure contexts (HTTPS)");
      }
    },
  },
};
</script>
