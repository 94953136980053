<template>
  <header class="sticky-top">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <img :src="require('@/assets/images/logo-medialive-web.png')" width="170" />
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
