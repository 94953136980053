<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <!-- Title section -->
            <h1>
              MY ACCOUNT
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-edit"></i> </span>Edit
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Linear Loader -->
      <div v-if="isLoading" class="linear-loader">
        <span></span>
      </div>
      <!-- Content -->
      <div v-if="isVisible" class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Title -->
        <div class="row m-0 padding-x-4px">
          <div
            v-if="formUserInfo.name != '' || formUserInfo.surname != ''"
            class="col-md-12 p-0 mt-4"
          >
            <div class="align-items-center d-flex flex-row">
              <h4 class="color-mainblue m-0 p-0">
                {{ formUserInfo.name }} {{ formUserInfo.surname }}
              </h4>
              <ul class="list-tags ms-2">
                <li>
                  <span
                    v-if="model_checkbox_isadmin"
                    class="badge-colored blue"
                    :title="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                    :alt="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                    >{{ getUserTypeLabel(model_checkbox_isadmin) }}</span
                  >
                  <span
                    v-if="!model_checkbox_isadmin"
                    class="badge-colored fucsia"
                    :title="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                    :alt="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                    >{{ getUserTypeLabel(model_checkbox_isadmin) }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="sepGray" />

        <!-- Form 1 User Info -->
        <div class="container-fluid">
          <form novalidate autocomplete="off">
            <!-- Name & Surname -->
            <div class="row">
              <div class="col-lg-6">
                <!-- Name -->
                <div class="container p-1 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">NAME</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{ error: vUser$.formUserInfo.name.$errors.length }"
                    >
                      <input
                        tabindex="1"
                        id="name"
                        ref="name"
                        v-model.trim="vUser$.formUserInfo.name.$model"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        required
                        spellcheck="false"
                        @click.right.prevent
                        autocomplete="off"
                        autocapitalize="on"
                        @input="isChangesSaved[1] = false"
                        maxlength="30"
                      />
                      <!-- Error Name -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vUser$.formUserInfo.name.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- Surname -->
                <div class="container p-1 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">SURNAME</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{ error: vUser$.formUserInfo.surname.$errors.length }"
                    >
                      <input
                        tabindex="2"
                        id="surname"
                        ref="surname"
                        v-model.trim="vUser$.formUserInfo.surname.$model"
                        type="text"
                        class="form-control"
                        placeholder="Surname"
                        required
                        spellcheck="false"
                        @click.right.prevent
                        autocomplete="off"
                        autocapitalize="on"
                        @input="isChangesSaved[1] = false"
                        maxlength="30"
                      />
                      <!-- Error Surname -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vUser$.formUserInfo.surname.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Email & Username -->
            <div class="row">
              <div class="col-lg-6">
                <!-- UserName -->
                <div class="container p-1 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">USERNAME</div>
                    </div>
                    <!-- Field -->
                    <div class="align-items-center col-lg-9 p-0">
                      <div class="form-control disabled">
                        {{ model_username }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- E-mail -->
                <div v-show="false" class="container p-1 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">EMAIL</div>
                    </div>
                    <!-- Field -->
                    <div class="align-items-center col-lg-9 p-0">
                      <div class="form-control disabled">
                        {{ model_email }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <hr class="sepGray" />

        <!-- Update User -->
        <div class="container-fluid d-flex justify-content-end my-3">
          <button
            @click.prevent="updateAccount()"
            type="button"
            class="btn button-outline-1"
            :class="!isChangesSaved[1] ? 'notSaved' : ''"
          >
            <span> <i class="zmdi zmdi-floppy"></i>Update My Account</span>
          </button>
        </div>

        <div class="container mt-5">&nbsp;</div>

        <!-- Form 2 Change Password -->
        <div class="container-fluid">
          <form novalidate autocomplete="off" class="container-fluid p-0">
            <!-- New Password & Confirm Password -->
            <div class="row p-0 p-0">
              <div class="col-lg-4 p-0">
                <!-- Actual Password -->
                <div class="container p-3 my-2">
                  <div class="container p-0">
                    <div class="form-label my-2">CURRENT PASSWORD</div>
                    <!-- Field -->
                    <div
                      class="align-items-center"
                      :class="{
                        error:
                          vPassword$.formChangePassword.actualpassword.$errors.length,
                      }"
                    >
                      <div class="input-group">
                        <input
                          id="actualpassword"
                          ref="actualpassword"
                          tabindex="4"
                          v-model.trim="
                            vPassword$.formChangePassword.actualpassword.$model
                          "
                          type="password"
                          class="form-control"
                          placeholder="Current password"
                          required
                          spellcheck="false"
                          @click.right.prevent
                          autocomplete="new-password"
                          autocapitalize="off"
                          @keydown.space.prevent
                          @change="
                            vPassword$.formChangePassword.actualpassword.$model = vPassword$.formChangePassword.actualpassword.$model.replace(
                              /\s/g,
                              ''
                            )
                          "
                          @input="isChangesSaved[2] = false"
                        />
                        <button
                          class="input-group-button"
                          :alt="eye_act_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          :title="eye_act_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          @click.prevent="
                            eye_act_psw = switchVisibility('actualpassword')
                          "
                        >
                          <i
                            :class="
                              eye_act_psw
                                ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                                : 'zmdi zmdi-eye zmdi-hc-lg'
                            "
                          ></i>
                        </button>
                      </div>
                      <!-- Error Password -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vPassword$.formChangePassword
                          .actualpassword.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 p-0">
                <!-- New Password -->
                <div class="container p-3 my-2">
                  <div class="container p-0">
                    <div class="form-label my-2">NEW PASSWORD</div>
                    <!-- Field -->
                    <div
                      class="align-items-center"
                      :class="{
                        error: vPassword$.formChangePassword.newpassword.$errors.length,
                      }"
                    >
                      <div class="input-group">
                        <input
                          id="newpassword"
                          ref="newpassword"
                          tabindex="5"
                          v-model.trim="vPassword$.formChangePassword.newpassword.$model"
                          type="password"
                          class="form-control"
                          placeholder="New password"
                          required
                          spellcheck="false"
                          @click.right.prevent
                          autocomplete="new-password"
                          autocapitalize="off"
                          @keydown.space.prevent
                          @change="
                            vPassword$.formChangePassword.newpassword.$model = vPassword$.formChangePassword.newpassword.$model.replace(
                              /\s/g,
                              ''
                            )
                          "
                          @input="isChangesSaved[2] = false"
                        />
                        <button
                          class="input-group-button"
                          alt="GENERATE PASSWORD"
                          title="GENERATE PASSWORD"
                          @click.prevent="
                            vPassword$.formChangePassword.newpassword.$model = generatePassword()
                          "
                        >
                          <i class="zmdi zmdi-lock zmdi-hc-lg"></i>
                        </button>
                        <button
                          class="input-group-button"
                          :alt="eye_new_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          :title="eye_new_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          @click.prevent="eye_new_psw = switchVisibility('newpassword')"
                        >
                          <i
                            :class="
                              eye_new_psw
                                ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                                : 'zmdi zmdi-eye zmdi-hc-lg'
                            "
                          ></i>
                        </button>
                      </div>
                      <!-- Error Password -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vPassword$.formChangePassword.newpassword
                          .$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 p-0">
                <!-- Confirm Password -->
                <div class="container p-3 my-2">
                  <div class="container p-0">
                    <div class="form-label my-2">CONFIRM PASSWORD</div>
                    <!-- Field -->
                    <div
                      class="align-items-center"
                      :class="{
                        error:
                          vPassword$.formChangePassword.confirm_newpassword.$errors
                            .length,
                      }"
                    >
                      <div class="input-group">
                        <input
                          id="confirm_newpassword"
                          ref="confirm_newpassword"
                          tabindex="6"
                          v-model.trim="
                            vPassword$.formChangePassword.confirm_newpassword.$model
                          "
                          type="password"
                          class="form-control"
                          placeholder="Confirm password"
                          required
                          spellcheck="false"
                          @click.right.prevent
                          autocomplete="new-password"
                          autocapitalize="off"
                          @keydown.space.prevent
                          @change="
                            vPassword$.formChangePassword.confirm_newpassword.$model = vPassword$.formChangePassword.confirm_newpassword.$model.replace(
                              /\s/g,
                              ''
                            )
                          "
                          @input="isChangesSaved[2] = false"
                        />
                        <button
                          class="input-group-button"
                          :alt="eye_cnf_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          :title="eye_cnf_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          @click.prevent="
                            eye_cnf_psw = switchVisibility('confirm_newpassword')
                          "
                        >
                          <i
                            :class="
                              eye_cnf_psw
                                ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                                : 'zmdi zmdi-eye zmdi-hc-lg'
                            "
                          ></i>
                        </button>
                      </div>
                      <!-- Error Password -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vPassword$.formChangePassword
                          .confirm_newpassword.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <hr class="sepGray" />

        <div class="container-fluid d-flex justify-content-end my-3">
          <!-- Reset Fields -->
          <button
            @click="resetPasswordFields()"
            type="button"
            class="btn button-outline-1"
          >
            <span> <i class="zmdi zmdi-rotate-left"></i>Reset Password Fields</span>
          </button>
          <!-- Change Password -->
          <button
            @click="changePassword()"
            type="button"
            class="btn button-outline-1 ms-4"
            :class="!isChangesSaved[2] ? 'notSaved' : ''"
          >
            <span> <i class="zmdi zmdi-lock"></i>Change Password</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventService from "@/services/EventService.js";
// import CommonMethods from "@/services/CommonMethods";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, sameAs } from "@vuelidate/validators";

export default {
  setup() {
    return { vUser$: useVuelidate(), vPassword$: useVuelidate() };
  },
  data() {
    return {
      eye_act_psw: false,
      eye_new_psw: false,
      eye_cnf_psw: false,
      isUserLogged: false,
      isLoading: false,
      isVisible: false,
      isChangesSaved: [],
      formUserInfo: {
        name: "",
        surname: "",
      },
      formChangePassword: {
        actualpassword: "",
        newpassword: "",
        confirm_newpassword: "",
      },
      model_checkbox_isadmin: false,
      model_username: "",
      model_email: "",
      userTypes: [
        { type: "users", label: "Users", tag: "USER", isAdmin: false, isAdminStr: "N" },
        { type: "admins", label: "Admins", tag: "ADMIN", isAdmin: true, isAdminStr: "Y" },
      ],
      userId: null,
      user: [],
      spinnerLoader: null,
    };
  },
  validations() {
    return {
      formUserInfo: {
        name: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(2)
          ),
        },
        surname: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(2)
          ),
        },
      },
      formChangePassword: {
        actualpassword: {
          required: helpers.withMessage("This field cannot be empty", required),
        },
        newpassword: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(8)
          ),
          passwordRequirements: helpers.withMessage(
            () =>
              "The password requires at least one uppercase character, one lowercase character and a number",
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/.test(value)
          ),
        },
        confirm_newpassword: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(8)
          ),
          passwordRequirements: helpers.withMessage(
            () =>
              "The password requires at least one uppercase character, one lowercase character and a number",
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/.test(value)
          ),
          sameAsPassword: helpers.withMessage(
            () => "Passwords don't match",
            sameAs(this.formChangePassword.newpassword)
          ),
        },
      },
    };
  },
  computed: {
    notChangesSaved() {
      if (!this.isChangesSaved[1]) {
        return true;
      }
      return false;
    },
  },
  methods: {
    initChangesSaved() {
      this.isChangesSaved[1] = true;
      this.isChangesSaved[2] = true;
    },
    focusInput(field) {
      this.$refs[field].focus();
    },
    switchVisibility(field) {
      let inputField = document.getElementById(field);
      if (inputField.getAttribute("type") === "password") {
        inputField.setAttribute("type", "text");
        return true;
      } else {
        inputField.setAttribute("type", "password");
        return false;
      }
    },
    getUserTypeLabel(isAdmin) {
      const tempElement = this.foundElementInArrayById(
        "isAdmin",
        isAdmin,
        this.userTypes
      );
      if (tempElement != undefined) {
        return tempElement.tag;
      }
      return "-";
    },
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    showPasswordField(field) {
      let inputField = document.getElementById(field);
      if (inputField.getAttribute("type") === "password") {
        inputField.setAttribute("type", "text");
      }
    },
    generatePassword() {
      let generatedPassword = "";
      let passwordLength = 12;
      const symbols = "!@#$%^&*(){}[]=<>/,.";
      for (let i = 0; i < passwordLength; i++) {
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 10) + 48); //number
        generatedPassword += symbols[Math.floor(Math.random() * symbols.length)]; //special characters
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 97); //alfabethic lower
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 65); //alfabethic upper
      }
      const finalPassword = generatedPassword.slice(0, passwordLength);
      this.vPassword$.formChangePassword.confirm_newpassword.$model = finalPassword;

      //enable visibility password fields
      this.eye_new_psw = true;
      this.showPasswordField("newpassword");
      this.eye_cnf_psw = true;
      this.showPasswordField("confirm_newpassword");

      this.isChangesSaved[2] = false;

      return finalPassword;
    },
    initFields() {
      //name
      if (this.user.metadata.name) {
        this.formUserInfo.name = this.user.metadata.name;
      } else {
        this.formUserInfo.name = "";
      }
      //surname
      if (this.user.metadata.surname) {
        this.formUserInfo.surname = this.user.metadata.surname;
      } else {
        this.formUserInfo.surname = "";
      }
      //e-mail
      if (this.user.email) {
        this.model_email = this.user.email;
      } else {
        this.model_email = "";
      }
      //username
      if (this.user.username) {
        this.model_username = this.user.username;
      } else {
        this.model_username = "";
      }
      this.model_checkbox_isadmin = this.user.isAdmin;
      this.initChangesSaved();
    },
    resetPasswordFields() {
      this.formChangePassword.actualpassword = "";
      this.formChangePassword.newpassword = "";
      this.formChangePassword.confirm_newpassword = "";
      this.model_checkbox_isadmin = false;
      this.$nextTick(() => {
        this.vPassword$.$reset();
      });
      this.isChangesSaved[2] = true;
      this.focusInput("actualpassword");
    },
    async getUserDataFromAPI() {
      //Get user from API
      if (
        this.$store.getters.getToken.providerUserId == null ||
        this.$store.getters.getToken.providerUserId == undefined
      ) {
        this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.userId = this.$store.getters.getToken.providerUserId;
      this.isLoading = true;
      const awaitUser = await EventService.getUser(
        this.userId,
        this.$store.getters.getToken.token
      );

      if (awaitUser.status != 200) {
        console.error("User >> id:" + this.userId + " error during loading data");
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET USER: An error has occurred, please try again",
          "error"
        );
        return;
      }

      console.log("+++ Get User >> data >>", awaitUser);

      this.user = awaitUser.data;

      this.initFields();

      this.isLoading = false;
      this.isVisible = true;
      setTimeout(() => {
        this.focusInput("name");
      }, 300);
    },
    async updateAccount() {
      // return if no changes have been made
      if (this.isChangesSaved[1]) {
        this.focusInput("name");
        return;
      }

      //validate user form
      this.vUser$.$touch();
      if (this.vUser$.formUserInfo.$invalid) {
        console.warn("++ validations edit my account: fill in all fields");
        this.$root.addSnackBarMsg(
          "EDIT MY ACCOUNT: Please fill in all fields",
          "warning"
        );
        this.focusInput("name");
        return false;
      }

      console.log("++ validations edit my account ok");

      //update user info
      this.showLoader(true);
      let bodyObj = {
        name: this.formUserInfo.name,
        surname: this.formUserInfo.surname,
      };
      let apiResponseObj = await EventService.updateUserMetadata(
        this.userId,
        bodyObj,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);
      if (apiResponseObj.status != 200) {
        console.error(
          "Update my account >> error during updating my accountr",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "UPDATE MY ACCOUNT: An error has occurred, please try again",
          "error"
        );
        this.isChangesSaved[1] = true;
        this.focusInput("name");
        return false;
      }

      //update token name
      this.$store.dispatch("updateTokenName", this.formUserInfo.name);

      //show message ok
      console.log("++ My account updated");
      this.$root.addSnackBarMsg(
        `${this.formUserInfo.name} ${this.formUserInfo.surname} updated`,
        "info"
      );
      this.isChangesSaved[1] = true;
      this.focusInput("name");
    },
    async changePassword() {
      if (this.isChangesSaved[2]) {
        this.focusInput("actualpassword");
        return;
      }

      //validate pasword form
      this.vPassword$.formChangePassword.$touch();
      if (this.vPassword$.formChangePassword.$invalid) {
        console.warn("++ validations edit update password: fill in all fields");
        this.$root.addSnackBarMsg(
          "EDIT UPDATE PASSWORD: Please fill in all fields",
          "warning"
        );
        this.focusInput("actualpassword");
        return false;
      }

      //update password
      this.showLoader(true);
      let objPasswords = {
        oldPassword: this.vPassword$.formChangePassword.actualpassword.$model,
        newPassword: this.vPassword$.formChangePassword.newpassword.$model,
        confirmPassword: this.vPassword$.formChangePassword.confirm_newpassword.$model,
      };

      console.log("++ objPasswords", objPasswords);
      let apiResponseObj = await EventService.updatePassword(
        objPasswords,
        this.userId,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);

      //api error
      if (apiResponseObj.status != 200) {
        console.error(
          "Update password >> error during updating password",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "UPDATE PASSWORD: An error has occurred, please try again",
          "error"
        );
        this.isChangesSaved[2] = true;
        this.resetPasswordFields();
        this.focusInput("actualpassword");
        return false;
      }
      //error password code
      let pswError = false;
      switch (apiResponseObj.data.code) {
        case 0:
          console.log("Update password >> 0 >> Password updated successfully");
          this.$root.addSnackBarMsg("Password updated successfully", "info");
          pswError = false;
          break;
        case 112:
          console.log("Update password >> 112 >> Reset password link expired");
          this.$root.addSnackBarMsg("Reset password link expired", "error");
          pswError = true;
          break;
        case 113:
          console.log("Update password >> 113 >> Passwords don't match");
          this.$root.addSnackBarMsg("Passwords don't match", "error");
          pswError = true;
          break;
        case 114:
          console.log("Update password >> 114 >> Incorrect current password");
          this.$root.addSnackBarMsg("Incorrect current password", "error");
          pswError = true;
          break;
        default:
          console.log("Update password >> default >> error data:", apiResponseObj.data);
          this.$root.addSnackBarMsg(
            "UPDATE PASSWORD: An error has occurred, please try again",
            "error"
          );
          pswError = true;
      }

      console.log("++ is error password:", pswError);

      this.isChangesSaved[2] = true;
      this.resetPasswordFields();
      this.focusInput("actualpassword");
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async beforeMount() {
    //if user is not logged
    this.isUserLogged = await EventService.checkToken();
    if (!this.isUserLogged) {
      this.isLoading = false;
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    //get user data
    this.getUserDataFromAPI();
  },
  mounted() {
    // setTimeout(() => {
    //   if (this.isUserLogged) {
    //     this.focusInput("name");
    //   }
    // }, 300);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangesSaved.indexOf(false) > 0) {
      this.$swal
        .fire({
          title: "Data unsaved",
          html: "Do you really want to leave? You have unsaved changes!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      clearInterval(this.intervalEncodingSTTInProgress);
      next();
    }
  },
};
</script>
