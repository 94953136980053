<template>
  <div class="d-flex justify-content-end">
    <div class="align-items-center d-flex justify-content-end">
      <div class="header-search-2">
        <a
          v-if="!setSearching"
          href="#"
          @click.prevent="searchToggleBtn"
          class="search-toggle"
          id="searchToggle"
          title="SEARCH FILTER"
          name="SEARCH FILTER"
        >
          <i class="zmdi zmdi-search s-open"></i>
          <i class="zmdi zmdi-close s-close"></i>
        </a>
        <a
          v-if="setSearching"
          href="#"
          @click.prevent="clearSearchResult"
          class="clear-search"
          id="clearSearch"
          title="REMOVE SEARCH FILTER"
          name="REMOVE SEARCH FILTER"
        >
          <i class="zmdi zmdi-close"></i>
        </a>
        <div class="search-wrap-2" id="searchWrap">
          <form class="search-bar" novalidate @submit.prevent="submitSearch()">
            <input
              placeholder="Search..."
              type="text"
              id="inputSearch"
              name="inputSearch"
              ref="inputSearch"
              v-model.trim="searchText"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              @keydown="checkKeyDownAlphaNumSpecial($event)"
            />
            <button type="submit" class="button-search">
              <i class="zmdi zmdi-search"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["txtToSearch", "clearTxtToSearch"],
  props: { isSearching: Boolean },
  data() {
    return {
      searchText: "",
      minCharSearch: 3,
      searching: false,
    };
  },
  computed: {
    setSearching() {
      return this.searching;
    },
  },
  methods: {
    checkKeyDownAlphaNumSpecial(event) {
      console.log("++ checkKeyDownAlphaNumSpecial, no filter", event);
      // if (!/[ A-Za-z0-9_@./#&+-,;:{}?!="'%$òàùèìé]/.test(event.key)) {
      //   this.ignoredValue = event.key ? event.key : "";
      //   event.preventDefault();
      // }
    },
    searchToggleBtn() {
      const searchToggle = document.getElementById("searchToggle");
      const searchWrap = document.getElementById("searchWrap");
      if (searchToggle.classList.contains("search-open")) {
        searchToggle.classList.remove("search-open");
        searchWrap.classList.remove("search-open");
      } else {
        document.getElementById("inputSearch").value = "";
        this.searchText = "";
        searchToggle.classList.add("search-open");
        searchWrap.classList.add("search-open");
        setTimeout(() => {
          this.$refs.inputSearch.focus();
        }, 100);
      }
    },
    clearSearchResult() {
      this.searching = false;
      this.$emit("clearTxtToSearch");
    },
    submitSearch() {
      if (this.searchText.length < this.minCharSearch) {
        this.$root.addSnackBarMsg("Enter at least 3 characters", "info");
        return;
      }
      this.searching = true;
      this.searchToggleBtn();
      this.$emit("txtToSearch", this.searchText);
    },
  },
  updated() {
    this.searching = this.isSearching;
  },
};
</script>
