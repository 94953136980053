<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              TAGS
              <span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="zmdi zmdi-tag"></i>
                </span>
                All&nbsp;&nbsp;&nbsp;
                <span v-if="showTotals" style="font-size: smaller"
                  >({{ totElements }}
                  {{ totElements == 1 ? "element" : "elements" }})</span
                >
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <div class="row m-0 padding-x-4px">
          <!-- Add tag -->
          <div
            class="align-items-center justify-content-end container-fluid d-flex my-3 p-0"
          >
            <button
              type="button"
              class="btn button-outline-1 small"
              style="white-space: nowrap"
              @click="addNewElement()"
            >
              <span> <i class="zmdi zmdi-plus"></i>Add Tag </span>
            </button>
          </div>
          <hr class="sepGray" />
          <!-- Linear Loader -->
          <div v-if="isLoading" class="linear-loader">
            <span></span>
          </div>
          <h6 class="mt-1" v-if="elements && elements.length == 0 && !isLoading">
            No data to display
          </h6>
          <!-- Table List -->
          <table
            v-if="elements && elements.length > 0"
            class="table-bordered table-responsive-md table-setup my-3"
          >
            <thead class="table-header-font">
              <tr class="td-center">
                <th>NAME</th>
                <th width="80">ACTIONS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <tr v-for="(element, index) in elements" :key="index">
                <!-- Name -->
                <td>{{ element.$sort }}</td>
                <!-- Actions -->
                <td class="td-actions td-center">
                  <button @click="editElement(element, index)" alt="EDIT" title="EDIT">
                    <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                  </button>
                  <button
                    @click="deleteElement(element, index)"
                    alt="DELETE"
                    title="DELETE"
                  >
                    <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <edit-attributes
    :dialogTitle="dialogTitle"
    :dialogWidth="'1000px'"
    :attribute="attribute"
    :isEdit="isEdit"
    :dataElement="dataElement"
    @closeDialog="closeDialogEditAttribute"
    :openDialog="isOpenEditAttribute"
    @confirmData="updateElements"
  ></edit-attributes>
</template>

<script>
import EditAttributes from "@/components/dialogs/EditAttributes.vue";
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";

// cambiare this.attribute = "category" per i tag e le area
// Object.values(this.$store.getters.categories)
// await EventService.getCategories(

export default {
  components: { EditAttributes },
  data() {
    return {
      isLoading: false,
      isOpenEditAttribute: false,
      elements: [],
      element: {},
      dataElement: {},
      attribute: "",
      isEdit: false,
      dialogTitle: "",
      totElements: 0,
      spinnerLoader: null,
      showTotals: true,
    };
  },
  computed: {
    sort() {
      return this.$store.getters.getAttributesOrder;
    },
  },
  watch: {
    sort() {
      this.setSortOrder();
    },
  },
  methods: {
    closeDialogEditAttribute() {
      this.isOpenEditAttribute = false;
    },
    editElement(element, index) {
      console.log("++ edit attribute element:", element, index);
      this.dialogTitle = "Edit element";
      this.isEdit = true;
      this.dataElement = { dataElement: element, index: index };
      this.isOpenEditAttribute = true;
    },
    addNewElement() {
      console.log("++ add attribute element");
      this.dialogTitle = "Add new element";
      let attId = this.getKeyId();
      this.isEdit = false;
      let elementArr = {
        [attId]: "",
        namesearch: "",
        language: [
          {
            lang: "it",
            name: "",
          },
          {
            lang: "en",
            name: "",
          },
          {
            lang: "ru",
            name: "",
          },
        ],
        $sort: "",
      };
      this.dataElement = { dataElement: elementArr, index: 0 };
      this.isOpenEditAttribute = true;
    },
    deleteElement(element, index) {
      console.log("++ delete attribute element:", element, index);
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this element?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.callDeleteElement(element);
          }
        });
    },
    async callDeleteElement(element) {
      let attId = this.getKeyId();
      this.showLoader(true);

      console.warn("++ attribute to delete:", element);

      let error;
      let awaitElements = await EventService.deleteTag(
        this.$store.getters.getToken.token,
        element[attId]
      );
      if (awaitElements.status != 200) {
        error = true;
      }

      if (error) {
        console.error("++ error during add/edit element");
        this.$root.addSnackBarMsg(
          "ATTRIBUTES: An error has occurred, please try again",
          "error"
        );
      } else {
        this.$root.addSnackBarMsg(
          this.attribute.charAt(0).toUpperCase() +
            this.attribute.substring(1) +
            " deleted",
          "info"
        );
      }
      this.showLoader(false);

      this.loadElements();
    },
    async updateElements(returnedData) {
      console.log("++ attributes data modified:", returnedData);
      let attId = this.getKeyId();
      let modelAttribute = {
        name: "",
        [attId]: "",
        lang: "",
        namesearch: "",
      };
      let arrToUpdate = [];
      for (let i = 0; i < returnedData.dataElement.language.length; i++) {
        modelAttribute = {
          name: returnedData.dataElement.language[i].name,
          [attId]: returnedData.dataElement[attId],
          lang: returnedData.dataElement.language[i].lang,
          namesearch: returnedData.dataElement.language[i].name.toLowerCase(),
        };
        arrToUpdate.push(modelAttribute);
      }
      console.log(arrToUpdate);

      let awaitElements, error;
      this.showLoader(true);
      for (let i = 0; i < arrToUpdate.length; i++) {
        awaitElements = await EventService.editAddTag(
          this.$store.getters.getToken.token,
          arrToUpdate[i]
        );
        if (awaitElements.status != 200) {
          error = true;
        }
        await CommonMethods.sleep(200);
      }
      if (error) {
        console.error("++ error during add/edit element");
        this.$root.addSnackBarMsg(
          "ATTRIBUTES: An error has occurred, please try again",
          "error"
        );
      } else {
        if (this.isEdit) {
          this.$root.addSnackBarMsg(
            this.attribute.charAt(0).toUpperCase() +
              this.attribute.substring(1) +
              " updated",
            "info"
          );
        } else {
          this.$root.addSnackBarMsg(
            this.attribute.charAt(0).toUpperCase() +
              this.attribute.substring(1) +
              " added",
            "info"
          );
        }
      }
      this.showLoader(false);

      this.loadElements();

      this.isOpenEditAttribute = false;
    },
    setSortOrder() {
      if (this.sort == 0) {
        //sort A-Z
        // this.elements.sort((a, b) => (a.$sort > b.$sort ? 1 : -1));
        this.elements.sort(
          (a, b) => a.$sort.localeCompare(b.$sort, undefined, { sensitivity: "base" }) //case insensitive
        );
      } else if (this.sort == 1) {
        //sort Z-A
        // this.elements.sort((a, b) => (a.$sort > b.$sort ? -1 : 1));
        this.elements.sort(
          (a, b) => b.$sort.localeCompare(a.$sort, undefined, { sensitivity: "base" }) //case insensitive
        );
      }
    },
    getKeyId() {
      let attId = "catId";
      if (this.attribute == "category") {
        attId = "catId";
      } else if (this.attribute == "tag") {
        attId = "tagId";
      } else if (this.attribute == "area") {
        attId = "areaId";
      }
      return attId;
    },
    addLangElements(arrayElements) {
      let langTmp, langFind;
      let objLang_it = { lang: "it", name: "" };
      let objLang_en = { lang: "en", name: "" };
      let objLang_ru = { lang: "ru", name: "" };
      for (let i = 0; i < arrayElements.length; i++) {
        langTmp = arrayElements[i].language;
        langFind = langTmp.find((el) => el.lang === "it");
        if (langFind == undefined) {
          langTmp.push(objLang_it);
        }
        langFind = langTmp.find((el) => el.lang === "en");
        if (langFind == undefined) {
          langTmp.push(objLang_en);
        }
        langFind = langTmp.find((el) => el.lang === "ru");
        if (langFind == undefined) {
          langTmp.push(objLang_ru);
        }
      }
      return arrayElements;
    },
    async loadElements() {
      this.isLoading = true;
      let awaitElements = await EventService.getTags(this.$store.getters.getToken.token);
      if (awaitElements.status == 200) {
        // convert object to array and add languages
        this.elements = this.addLangElements(Object.values(this.$store.getters.tags));
        console.log("++ attributes elements (tags):", this.elements);
      } else {
        this.$root.addSnackBarMsg(
          "GET ATTRIBUTES: An error has occurred, please try again",
          "error"
        );
      }
      if (awaitElements.data.totTags != undefined) {
        this.totElements = this.formatNumber(awaitElements.data.totTags);
      }

      console.log("this.totElements", this.totElements);
      this.isLoading = false;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    this.attribute = "tag";
    this.loadElements();
  },
};
</script>
