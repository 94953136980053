import { createStore } from "vuex";
import Token from "./token";
import Data from "./data";

export default createStore({
  state: {
    videosOrder: 3,
    picturesOrder: 3,
    attributesOrder: 0,
  },
  getters: {
    getVideosOrder: (state) => state.videosOrder,
    getPicturesOrder: (state) => state.picturesOrder,
    getAttributesOrder: (state) => state.attributesOrder,
  },
  mutations: {
    setVideosOrder(state, payload) {
      state.videosOrder = payload;
    },
    setPicturesOrder(state, payload) {
      state.picturesOrder = payload;
    },
    setAttributesOrder(state, payload) {
      state.attributesOrder = payload;
    },
  },
  actions: {
    setVideosOrder({ commit }, payload) {
      commit("setVideosOrder", payload);
    },
    setPicturesOrder({ commit }, payload) {
      commit("setPicturesOrder", payload);
    },
    setAttributesOrder({ commit }, payload) {
      commit("setAttributesOrder", payload);
    },
  },
  modules: {
    Token,
    Data
  }
});
