import { createRouter, createWebHistory } from "vue-router";

import PageNotFound from "@/views/PageNotFound";

import LoginView from "@/views/login/LoginView";

import SettingsView from "@/views/settings/SettingsView";
import SettingsThumbnail from "@/views/settings/SettingsThumbnail";
import SettingsProtection from "@/views/settings/SettingsProtection";

import VideosView from "@/views/videos/VideosView";
import VideosAddNew from "@/views/videos/VideosAddNew";
import VideosList from "@/views/videos/VideosList";
import VideosEdit from "@/views/videos/VideosEdit";

import PicturesView from "@/views/pictures/PicturesView";
import PicturesAddNew from "@/views/pictures/PicturesAddNew";
import PicturesList from "@/views/pictures/PicturesList";
import PicturesEdit from "@/views/pictures/PicturesEdit";

import MonitoringView from "@/views/monitoring/MonitoringView";
import MonitoringList from "@/views/monitoring/MonitoringList";
import MonitoringListArchive from "@/views/monitoring/MonitoringListArchive";

import AttributesView from "@/views/attributes/AttributesView";
import CategoriesList from "@/views/attributes/categories/CategoriesList";
import TagsList from "@/views/attributes/tags/TagsList";
import AreasList from "@/views/attributes/areas/AreasList";

import UsersView from "@/views/users/UsersView";
import UsersAddNew from "@/views/users/UsersAddNew";
import UsersList from "@/views/users/UsersList";
import UsersEdit from "@/views/users/UsersEdit";

import AccountView from "@/views/account/AccountView";
import AccountEdit from "@/views/account/AccountEdit";

import ForgotPasswordView from "@/views/account/ForgotPasswordView"
import ResetPasswordView from "@/views/account/ResetPasswordView"

// import store from '@/store'

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "view-login",
    component: LoginView
  },
  {
    path: "/videos",
    component: VideosView,
    children: [
      {
        path: "",
        redirect: "/videos/all",
        name: "view-videos-all",
        component: VideosList
      },
      {
        path: ":id",
        name: "view-videos",
        component: VideosList
      },
      {
        path: "new",
        name: "view-videos-new",
        component: VideosAddNew
      },
      {
        path: "edit/:id",
        name: "view-edit-video",
        component: VideosEdit
      }
    ]
  },
  {
    path: "/pictures",
    component: PicturesView,
    children: [
      {
        path: "",
        redirect: "/pictures/all",
        name: "view-pictures-all",
        component: PicturesList
      },
      {
        path: ":id",
        name: "view-pictures",
        component: PicturesList
      },
      {
        path: "new",
        name: "view-pictures-new",
        component: PicturesAddNew
      },
      {
        path: "edit/:id",
        name: "view-edit-picture",
        component: PicturesEdit
      }
    ]
  },
  {
    path: "/monitoring",
    name: "view-monitoring",
    redirect: "/monitoring/encoding-jobs",
    component: MonitoringView,
    children: [
      {
        path: "encoding-jobs",
        name: "view-monitoring-encoding-jobs",
        component: MonitoringList
      },
      {
        path: "encoding-archive",
        name: "view-monitoring-encoding-archive",
        component: MonitoringListArchive
      }
    ]
  },
  {
    path: "/attributes",
    name: "view-attributes",
    redirect: "/attributes/areas",
    component: AttributesView,
    children: [
      {
        path: "categories",
        name: "view-categories",
        component: CategoriesList
      },
      {
        path: "tags",
        name: "view-tags",
        component: TagsList
      },
      {
        path: "areas",
        name: "view-areas",
        component: AreasList
      }
    ]
  },
  {
    path: "/settings",
    name: "view-settings",
    redirect: "/settings/thumbnail",
    component: SettingsView,
    children: [
      {
        path: "thumbnail",
        name: "view-settings-thumbnail",
        component: SettingsThumbnail
      },
      {
        path: "protection",
        name: "view-settings-protection",
        component: SettingsProtection
      }
    ]
  },
  {
    path: "/users",
    component: UsersView,
    children: [
      {
        path: "",
        redirect: "/users/users",
        name: "view-users-all",
        component: UsersList
      },
      {
        path: ":id",
        name: "view-users",
        component: UsersList
      },
      {
        path: "new",
        name: "view-users-new",
        component: UsersAddNew
      },
      {
        path: "edit/:id",
        name: "view-edit-user",
        component: UsersEdit
      }
    ]
  },
  {
    path: "/account",
    component: AccountView,
    children: [
      {
        path: "",
        redirect: "/account/edit",
        component: AccountEdit
      },
      {
        path: "edit",
        name: "view-edit-account",
        component: AccountEdit
      }
    ]
  },
  {
    path: "/forgotpassword",
    name: "forgot-password",
    component: ForgotPasswordView,
  },
  {
    path: "/resetpassword",
    name: "reset-password",
    component: ResetPasswordView,
  },

  // { path: "/:pathMatch(.*)*", name: "page-not-found", component: PageNotFound }
  { path: "/:notFound(.*)", name: "page-not-found", component: PageNotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise(resolve => {
        setTimeout(() => resolve({ top: 0 }), 300);
      })
    );
  }
});


// router.beforeEach(async (to, from) => {
//   const cat = store.getters.getFilterCategories
//   console.warn("** to", to)
//   console.warn("** from", from)
//   console.warn("** cat", cat)
// })

export default router;

