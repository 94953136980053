import CommonMethods from "@/services/CommonMethods";

export default {
  state: {
    categories: [],
    tags: [],
    areas: [],
    filterCategories: [],
    filterTags: [],
    filterAreas: []
  },
  mutations: {
    setCategories(state, payload) {
      //   in questo modo avrò solo un oggetto e non un array di oggetti per ogni elemento
      //   payload.categories.forEach(element => {
      //     state.categories[element.catId] = element;
      //   });
      //   state.categories = { ...state.categories };
      //   console.log("*** state.categories", state.categories);

      //la soluzione adatta è questa CommonMethods.groupArrayByKeySorted
      const categoriesGroupedByKey = CommonMethods.groupArrayByKeySorted(
        payload.categories,
        "catId"
      );
      state.categories = { ...categoriesGroupedByKey };
    },

    setTags(state, payload) {
      const tagsGroupedByKey = CommonMethods.groupArrayByKeySorted(
        payload.tags,
        "tagId"
      );
      console.log("++ tagsGroupedByKey", tagsGroupedByKey);
      state.tags = { ...tagsGroupedByKey };
    },
    setAreas(state, payload) {
      const areasGroupedByKey = CommonMethods.groupArrayByKeySorted(
        payload.areas,
        "areaId"
      );
      console.log("++ areasGroupedByKey", areasGroupedByKey);
      state.areas = { ...areasGroupedByKey };
    },
    setFilterCategories(state, payload) {
      state.filterCategories = payload;
    },
    setFilterTags(state, payload) {
      state.filterTags = payload;
    },
    setFilterAreas(state, payload) {
      state.filterAreas = payload;
    }
  },
  actions: {
    setCategories({ commit }, payload) {
      commit("setCategories", payload);
    },
    setTags({ commit }, payload) {
      commit("setTags", payload);
    },
    setAreas({ commit }, payload) {
      commit("setAreas", payload);
    },
    setFilterCategories({ commit }, payload) {
      commit("setFilterCategories", payload);
    },
    setFilterTags({ commit }, payload) {
      commit("setFilterTags", payload);
    },
    setFilterAreas({ commit }, payload) {
      commit("setFilterAreas", payload);
    },
  },
  getters: {
    categories: (state) => state.categories,
    tags: (state) => state.tags,
    areas: (state) => state.areas,
    getFilterCategories: (state) => state.filterCategories,
    getFilterTags: (state) => state.filterTags,
    getFilterAreas: (state) => state.filterAreas,
  }
};
