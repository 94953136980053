<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <ul class="unstyled nav-parent-item">
        <!-- Add new video -->
        <li>
          <router-link :to="{ name: 'view-videos-new' }" exact>Add new video</router-link>
        </li>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <!-- All -->
        <li>
          <router-link
            :to="{ name: 'view-videos', params: { id: 'all' } }"
            @click.prevent="clearAllAttributesFilters()"
            exact
            >All</router-link
          >
        </li>
      </ul>
      <hr
        v-if="categories[0] != undefined || tags[0] != undefined || areas[0] != undefined"
        aria-orientation="horizontal"
        role="separator"
        class="divider"
      />
      <!-- Areas -->
      <ul v-if="areas[0] != undefined" class="unstyled nav-parent-item">
        <li>
          <a
            href="#"
            class="collapsible"
            data-bs-toggle="collapse"
            data-bs-target="#areas-menu"
            style="color: #fff !important"
            >{{ `Areas (${checkedAreas.length > 0 ? checkedAreas.length : "all"})` }}</a
          >
          <div id="areas-menu" class="collapse show">
            <div class="scroll-container">
              <div
                class="btn-group-vertical d-block"
                v-for="(dataElement, index) in areas"
                :key="index"
              >
                <input
                  :id="'filter_' + dataElement.areaId"
                  :value="{ id: dataElement.areaId, name: dataElement.name }"
                  v-model="checkedAreas"
                  @change="onChangeFilterAttributes($event, 'areas')"
                  type="checkbox"
                  class="btn-check-filter"
                />
                <label class="btn btn-filter" :for="'filter_' + dataElement.areaId">{{
                  dataElement.name
                }}</label>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- Categories -->
      <ul v-if="categories[0] != undefined" class="unstyled nav-parent-item">
        <li>
          <a
            href="#"
            class="collapsible"
            data-bs-toggle="collapse"
            data-bs-target="#categories-menu"
            style="color: #fff !important"
            >{{
              `Categories (${
                checkedCategories.length > 0 ? checkedCategories.length : "all"
              })`
            }}</a
          >
          <div id="categories-menu" class="collapse show">
            <div class="scroll-container">
              <div
                class="btn-group-vertical d-block"
                v-for="(dataElement, index) in categories"
                :key="index"
              >
                <input
                  :id="'filter_' + dataElement.catId"
                  :value="{ id: dataElement.catId, name: dataElement.name }"
                  v-model="checkedCategories"
                  @change="onChangeFilterAttributes($event, 'categories')"
                  type="checkbox"
                  class="btn-check-filter"
                />
                <label class="btn btn-filter" :for="'filter_' + dataElement.catId">{{
                  dataElement.name
                }}</label>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- Tags -->
      <ul v-if="tags[0] != undefined" class="unstyled nav-parent-item">
        <li>
          <a
            href="#"
            class="collapsible"
            data-bs-toggle="collapse"
            data-bs-target="#tags-menu"
            style="color: #fff !important"
            >{{ `Tags (${checkedTags.length > 0 ? checkedTags.length : "all"})` }}</a
          >
          <div id="tags-menu" class="collapse show">
            <div class="scroll-container">
              <div
                class="btn-group-vertical d-block"
                v-for="(dataElement, index) in tags"
                :key="index"
              >
                <input
                  :id="'filter_' + dataElement.tagId"
                  :value="{ id: dataElement.tagId, name: dataElement.name }"
                  v-model="checkedTags"
                  @change="onChangeFilterAttributes($event, 'tags')"
                  type="checkbox"
                  class="btn-check-filter"
                />
                <label class="btn btn-filter" :for="'filter_' + dataElement.tagId">{{
                  dataElement.name
                }}</label>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <hr role="separator" aria-orientation="horizontal" class="divider" />
      <!-- Sort -->
      <ul class="unstyled nav-parent-item">
        <li
          v-for="item in sortOrder"
          :key="item.text"
          @click.prevent="setOrder(item.value)"
        >
          <a
            href
            :class="{
              active: order == item.value,
            }"
            >{{ item.text }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";

export default {
  components: {},
  data() {
    return {
      categories: [],
      tags: [],
      areas: [],
      order: this.$store.getters.getVideosOrder,
      checkedCategories: [],
      checkedTags: [],
      checkedAreas: [],
      localStorageName: process.env.VUE_APP_LOCALSTORAGE_VIDEO,
    };
  },
  computed: {
    sortOrder() {
      return [
        {
          text: "Latest",
          value: "3",
        },
        {
          text: "Older",
          value: "2",
        },
        {
          text: "Alphabetically (A-Z)",
          value: "0",
        },
        {
          text: "Alphabetically (Z-A)",
          value: "1",
        },
      ];
    },
    isActive() {
      return this.$route.name == "view-videos-all";
    },
  },
  methods: {
    clearAllAttributesFilters() {
      this.checkedCategories = [];
      this.checkedTags = [];
      this.checkedAreas = [];
      this.order = this.$store.getters.getVideosOrder;

      this.$store.dispatch("setFilterCategories", this.checkedCategories);
      this.updateLSObjectParams("filterCategories", this.checkedCategories);

      this.$store.dispatch("setFilterTags", this.checkedTags);
      this.updateLSObjectParams("filterTags", this.checkedTags);

      this.$store.dispatch("setFilterAreas", this.checkedAreas);
      this.updateLSObjectParams("filterAreas", this.checkedAreas);

      this.updateLSObjectParams("searching", false);
      this.updateLSObjectParams("textToSearch", "");

      this.$store.dispatch("setVideosOrder", 3);
      this.updateLSObjectParams("sort", 3);

      this.initObjectNavigation();
    },
    onChangeFilterAttributes(event, attribute) {
      if (attribute == "areas") {
        this.$store.dispatch("setFilterAreas", this.checkedAreas);
        console.log("** areas attribute:", attribute, this.checkedAreas);
        // update filter areas values to localStorage
        this.updateLSObjectParams("filterAreas", this.checkedAreas);
        this.initObjectNavigation();
        return;
      }
      if (attribute == "categories") {
        this.$store.dispatch("setFilterCategories", this.checkedCategories);
        console.log("** categories attribute:", attribute, this.checkedCategories);
        // update filter categories values to localStorage
        this.updateLSObjectParams("filterCategories", this.checkedCategories);
        this.initObjectNavigation();
        return;
      }
      if (attribute == "tags") {
        this.$store.dispatch("setFilterTags", this.checkedTags);
        console.log("** tags attribute:", attribute, this.checkedTags);
        // update filter tags values to localStorage
        this.updateLSObjectParams("filterTags", this.checkedTags);
        this.initObjectNavigation();
        return;
      }
    },
    async getCategories() {
      await EventService.getCategories(this.$store.getters.getToken.token);
      const categories = this.$store.getters.categories;
      this.categories = CommonMethods.getSemplifyAttributes(categories, "catId");
      return this.categories;
    },
    async getTags() {
      await EventService.getTags(this.$store.getters.getToken.token);
      const tags = this.$store.getters.tags;
      this.tags = CommonMethods.getSemplifyAttributes(tags, "tagId");
      return this.tags;
    },
    async getAreas() {
      await EventService.getAreas(this.$store.getters.getToken.token);
      const areas = this.$store.getters.areas;
      this.areas = CommonMethods.getSemplifyAttributes(areas, "areaId");
      return this.areas;
    },
    setOrder(order = 3) {
      this.order = order;
      this.$store.dispatch("setVideosOrder", order);
      this.updateLSObjectParams("sort", this.order);
    },
    updateLSObjectParams(key, value) {
      CommonMethods.updateLSKeyObjectParam(this.localStorageName, key, value);
    },
    loadLSObject() {
      return CommonMethods.loadLSObjectParam(this.localStorageName);
    },
    initFilters() {
      const objLS = this.loadLSObject();
      //get filtered areas
      if (objLS.filterAreas) {
        this.checkedAreas = objLS.filterAreas;
      } else {
        this.checkedAreas = [];
      }
      //get filtered categories
      if (objLS.filterCategories) {
        this.checkedCategories = objLS.filterCategories;
      } else {
        this.checkedCategories = [];
      }
      //get filtered tags
      if (objLS.filterTags) {
        this.checkedTags = objLS.filterTags;
      } else {
        this.checkedTags = [];
      }
      //get sort
      if (objLS.sort) {
        this.order = objLS.sort;
      } else {
        this.order = this.$store.getters.getVideosOrder;
      }
    },
    initObjectNavigation() {
      this.updateLSObjectParams("currentPage", 1);
      this.updateLSObjectParams("apiPage", 0);
    },
  },
  async created() {
    console.log("** VideosSidebar >> created >> load areas, categories, tags");
    // load areas, categories, tags
    await this.getAreas();
    await this.getCategories();
    await this.getTags();

    this.initFilters();
  },
};
</script>
