<template>
  <div class="col main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <!-- Title section -->
            <h1>
              USER
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-edit"></i> </span>Edit
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Linear Loader -->
      <div v-if="isLoading" class="linear-loader">
        <span></span>
      </div>
      <!-- Content -->
      <div v-if="isVisible" class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Title -->
        <div class="row m-0 padding-x-4px">
          <div class="col-md-12 p-0 mt-4">
            <h4 class="color-mainblue m-0 p-0">
              {{ formUserInfo.name }} {{ formUserInfo.surname }}
            </h4>
          </div>
          <hr class="sepGray" />
        </div>
        <!-- Form 1 -->
        <div class="row m-0">
          <form novalidate autocomplete="off">
            <!-- Name & Surname -->
            <div class="row">
              <div class="col-lg-6">
                <!-- Name -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">NAME</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{ error: vUser$.formUserInfo.name.$errors.length }"
                    >
                      <input
                        tabindex="1"
                        id="name"
                        ref="name"
                        v-model.trim="vUser$.formUserInfo.name.$model"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        required
                        spellcheck="false"
                        @click.right.prevent
                        autocomplete="off"
                        autocapitalize="on"
                        @input="isChangesSaved[1] = false"
                        maxlength="30"
                      />
                      <!-- Error Name -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vUser$.formUserInfo.name.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- Surname -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">SURNAME</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{ error: vUser$.formUserInfo.surname.$errors.length }"
                    >
                      <input
                        tabindex="2"
                        id="surname"
                        ref="surname"
                        v-model.trim="vUser$.formUserInfo.surname.$model"
                        type="text"
                        class="form-control"
                        placeholder="Surname"
                        required
                        spellcheck="false"
                        @click.right.prevent
                        autocomplete="off"
                        autocapitalize="on"
                        @input="isChangesSaved[1] = false"
                        maxlength="30"
                      />
                      <!-- Error Surname -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vUser$.formUserInfo.surname.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Email & Username -->
            <div class="row">
              <div class="col-lg-6">
                <!-- UserName -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">USERNAME</div>
                    </div>
                    <!-- Field -->
                    <div class="align-items-center col-lg-9 p-0">
                      <div class="form-control disabled">
                        {{ vUser$.formUserInfo.username.$model }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- E-mail -->
                <div v-show="false" class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">EMAIL</div>
                    </div>
                    <!-- Field -->
                    <div class="align-items-center col-lg-9 p-0">
                      <div class="form-control disabled">
                        {{ vUser$.formUserInfo.email.$model }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- Admin -->
        <div class="row m-0">
          <div class="col-lg-6">
            <!-- Is Admin -->
            <div class="container m-0 my-2">
              <div class="align-items-center d-flex row">
                <div class="col-lg-3 pe-0">
                  <div class="form-label my-2">IS ADMIN</div>
                </div>
                <div
                  class="form-check form-switch align-items-center col-lg-9 d-flex flex-row"
                >
                  <input
                    class="form-check-input"
                    style="margin-top: 0"
                    type="checkbox"
                    v-model="model_checkbox_isadmin"
                    @input="handleCheckboxIsAdmin($event)"
                  />
                  <ul class="list-tags ms-2">
                    <li>
                      <span
                        v-if="model_checkbox_isadmin"
                        class="badge-colored blue"
                        :title="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        :alt="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        >{{ getUserTypeLabel(model_checkbox_isadmin) }}</span
                      >
                      <span
                        v-if="!model_checkbox_isadmin"
                        class="badge-colored fucsia"
                        :title="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        :alt="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        >{{ getUserTypeLabel(model_checkbox_isadmin) }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="sepGray" />
        <!-- Update User -->
        <div class="container-fluid my-3">
          <button
            @click="updateUser()"
            type="button"
            class="btn button-outline-1 float-end"
            :class="notChangesSaved ? 'notSaved' : ''"
          >
            <span> <i class="zmdi zmdi-floppy"></i>Update User</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventService from "@/services/EventService.js";
// import CommonMethods from "@/services/CommonMethods";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";

export default {
  setup() {
    return { vUser$: useVuelidate() };
  },
  data() {
    return {
      isUserLogged: false,
      isLoading: false,
      isVisible: false,
      isChangesSaved: [],
      formUserInfo: {
        name: "",
        surname: "",
        username: "",
        email: "",
      },
      model_checkbox_isadmin: false,
      userTypes: [
        { type: "users", label: "Users", tag: "USER", isAdmin: false, isAdminStr: "N" },
        { type: "admins", label: "Admins", tag: "ADMIN", isAdmin: true, isAdminStr: "Y" },
      ],
      userId: null,
      user: [],
      originalUserData: {},
      spinnerLoader: null,
    };
  },
  validations() {
    return {
      formUserInfo: {
        name: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(2)
          ),
        },
        surname: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(2)
          ),
        },
        username: {},
        email: {},
      },
    };
  },
  computed: {
    notChangesSaved() {
      if (!this.isChangesSaved[1]) {
        return true;
      }
      return false;
    },
  },
  methods: {
    initChangesSaved() {
      this.isChangesSaved[1] = true;
    },
    focusInput() {
      this.$refs.name.focus();
    },
    switchVisibility(field) {
      let inputField = document.getElementById(field);
      if (inputField.getAttribute("type") === "password") {
        inputField.setAttribute("type", "text");
        return true;
      } else {
        inputField.setAttribute("type", "password");
        return false;
      }
    },
    handleCheckboxIsAdmin(event) {
      if (event.target.checked) {
        this.model_checkbox_isadmin = true;
      } else {
        this.model_checkbox_isadmin = false;
      }
      this.isChangesSaved[1] = false;
    },
    getUserTypeLabel(isAdmin) {
      const tempElement = this.foundElementInArrayById(
        "isAdmin",
        isAdmin,
        this.userTypes
      );
      if (tempElement != undefined) {
        return tempElement.tag;
      }
      return "-";
    },
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    generatePassword() {
      let generatedPassword = "";
      let passwordLength = 12;
      const symbols = "!@#$%^&*(){}[]=<>/,.";
      for (let i = 0; i < passwordLength; i++) {
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 10) + 48); //number
        generatedPassword += symbols[Math.floor(Math.random() * symbols.length)]; //special characters
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 97); //alfabethic lower
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 65); //alfabethic upper
      }
      const finalPassword = generatedPassword.slice(0, passwordLength);
      return finalPassword;
    },
    initFields() {
      //name
      if (this.user.metadata.name) {
        this.formUserInfo.name = this.user.metadata.name;
      } else {
        this.formUserInfo.name = "";
      }
      //surname
      if (this.user.metadata.surname) {
        this.formUserInfo.surname = this.user.metadata.surname;
      } else {
        this.formUserInfo.surname = "";
      }
      //e-mail
      if (this.user.email) {
        this.formUserInfo.email = this.user.email;
      } else {
        this.formUserInfo.email = "";
      }
      //username
      if (this.user.username) {
        this.formUserInfo.username = this.user.username;
      } else {
        this.formUserInfo.username = "";
      }
      this.model_checkbox_isadmin = this.user.isAdmin;
      this.initChangesSaved();

      this.originalUserData = {
        name: this.formUserInfo.name,
        surname: this.formUserInfo.surname,
        isAdmin: this.user.isAdmin,
      };
    },
    restoreUserData() {
      this.formUserInfo.name = this.originalUserData.name;
      this.formUserInfo.surname = this.originalUserData.surname;
      this.model_checkbox_isadmin = this.originalUserData.isAdmin;
    },
    async getUserDataFromAPI() {
      //Get user from API
      this.userId = this.$route.params.id;
      this.isLoading = true;
      const awaitUser = await EventService.getUser(
        this.userId,
        this.$store.getters.getToken.token
      );

      if (awaitUser.status != 200) {
        console.error("User >> id:" + this.userId + " error during loading data");
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET USER: An error has occurred, please try again",
          "error"
        );
        return;
      }

      console.log("+++ Get User >> data >>", awaitUser);

      this.user = awaitUser.data;

      this.initFields();

      this.isLoading = false;
      this.isVisible = true;
    },
    async updateUser() {
      if (this.isChangesSaved[1]) {
        this.focusInput();
        return;
      }
      this.vUser$.$touch();
      if (this.vUser$.$invalid) {
        console.warn("++ validations edit user: fill in all fields");
        this.$root.addSnackBarMsg("EDIT USER: Please fill in all fields", "warning");
        this.focusInput();
        return false;
      }

      console.log("++ validations edit user ok");

      //update user info
      this.showLoader(true);
      let bodyObj = {
        name: this.formUserInfo.name,
        surname: this.formUserInfo.surname,
      };
      let apiResponseObj = await EventService.updateUserMetadata(
        this.userId,
        bodyObj,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);
      if (apiResponseObj.status != 200) {
        console.error("Update user >> error during updating user", apiResponseObj.status);
        this.$root.addSnackBarMsg(
          "UPDATE USER: An error has occurred, please try again",
          "error"
        );
        this.initChangesSaved();
        this.restoreUserData();
        this.focusInput();
        return false;
      }
      //update isAdmin
      this.showLoader(true);
      bodyObj = {
        providerUserId: this.userId,
        state: "active",
        isAdmin: this.model_checkbox_isadmin,
      };
      apiResponseObj = await EventService.updateUserIsAdmin(
        bodyObj,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);
      if (apiResponseObj.status != 200) {
        console.error("Update user >> error during add user", apiResponseObj.status);
        this.$root.addSnackBarMsg(
          "UPDATE USER: An error has occurred, please try again",
          "error"
        );
        this.initChangesSaved();
        this.restoreUserData();
        this.focusInput();
        return false;
      }

      console.log("++ User updated");
      this.$root.addSnackBarMsg(
        `${this.formUserInfo.name} ${this.formUserInfo.surname} updated`,
        "info"
      );
      this.initChangesSaved();
      this.focusInput();
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async beforeMount() {
    //if user is not logged
    this.isUserLogged = await EventService.checkToken();
    if (!this.isUserLogged) {
      this.isLoading = false;
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    //get user data
    this.getUserDataFromAPI();
  },
  mounted() {
    setTimeout(() => {
      if (this.isUserLogged) {
        this.focusInput();
      }
    }, 200);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangesSaved.indexOf(false) > 0) {
      this.$swal
        .fire({
          title: "Data unsaved",
          html: "Do you really want to leave? You have unsaved changes!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      clearInterval(this.intervalEncodingSTTInProgress);
      next();
    }
  },
};
</script>
